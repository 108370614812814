export const environment = {
    production: true,
    baseTitle: 'Aviator Online',
    environmentMessage: '',
    apiRoot: "https://aviator-online-prod-api.azurewebsites.net/api/v1",
    cdnRoot: "https://aviator-online-prod.azureedge.net/api/v1",
    useCDN: true,
    tenantKey: '16970b66-9788-4e12-aeb3-36bda56bd32f',
  
    allowConfiguration: true,
    enableConsoleLogging: false,
    
    googleAnalyticsTrackingToken: null,
    showContactUs: true,
    contactUsEmail: 'support@pilexos.com',
    disabledUserInactvityTimeout: false
  };
  