// tslint:disable
import { Injectable } from '@angular/core';

import { DwQuery, DwQueryBuilderGen, DwItem2, DwItemAttributeCollection, DwItemAttribute, DwItemRelationshipCollection, DwItemRelationship, DwItemDetail2, DwOrmData } from '@devwareapps/devware-cap';
import * as EnterpriseMetaData from '@devwareapps/devware-cap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/************************************************
   Generated with the Devware CAP CLI 4.4.47
   Code Gen Name   App
   API Url         https://aviator-online-test-api4.azurewebsites.net/

   Generated By    DEVWARE/anthony.monsees
   Generate On     11/18/2024 5:38:05 PM
************************************************/

export enum AppMetaDataItemNames {
    AircraftType = 'AircraftType',
    Assignment = 'Assignment',
    AssignmentCourseLesson = 'AssignmentCourseLesson',
    AssignmentStatus = 'AssignmentStatus',
    AssignmentStudent = 'AssignmentStudent',
    AssignmentType = 'AssignmentType',
    Course = 'Course',
    CourseEndorsement = 'CourseEndorsement',
    CourseEndorsementLesson = 'CourseEndorsementLesson',
    CourseEndorsementStudent = 'CourseEndorsementStudent',
    CourseEndorsementTemplate = 'CourseEndorsementTemplate',
    CourseEndorsementTemplateField = 'CourseEndorsementTemplateField',
    CourseLesson = 'CourseLesson',
    CourseLessonFlag = 'CourseLessonFlag',
    CourseLessonFlagStatus = 'CourseLessonFlagStatus',
    CourseLessonFlagType = 'CourseLessonFlagType',
    CourseLessonProgress = 'CourseLessonProgress',
    CourseLessonSession = 'CourseLessonSession',
    CourseModificationHistory = 'CourseModificationHistory',
    CourseModule = 'CourseModule',
    CourseModuleProgress = 'CourseModuleProgress',
    CourseType = 'CourseType',
    DwMedia = 'dw_Media',
    DwMediaGroup = 'dw_MediaGroup',
    DwRoMedia = 'dw_roMedia',
    DwTheme = 'dw_Theme',
    DwThemeProperty = 'dw_ThemeProperty',
    DwThemePropertyDefinition = 'dw_ThemePropertyDefinition',
    DwThemePropertyType = 'dw_ThemePropertyType',
    DwUser = 'dw_User',
    DwUserRole = 'dw_UserRole',
    DwUserType = 'dw_UserType',
    FlightSchool = 'FlightSchool',
    FlightSchoolBranding = 'FlightSchoolBranding',
    FlightSchoolCourse = 'FlightSchoolCourse',
    FlightSchoolGroup = 'FlightSchoolGroup',
    FlightSchoolGroupStudent = 'FlightSchoolGroupStudent',
    FlightSchoolInstructor = 'FlightSchoolInstructor',
    FlightSchoolPackage = 'FlightSchoolPackage',
    FlightSchoolStatus = 'FlightSchoolStatus',
    FlightSchoolStudentOld = 'FlightSchoolStudent_Old',
    Instructor = 'Instructor',
    InstructorStatus = 'InstructorStatus',
    InstructorStudent = 'InstructorStudent',
    JoinRequest = 'JoinRequest',
    JoinRequestCourse = 'JoinRequestCourse',
    JoinRequestEmail = 'JoinRequestEmail',
    JoinRequestStatus = 'JoinRequestStatus',
    JoinRequestType = 'JoinRequestType',
    JoinRequestTypeRole = 'JoinRequestTypeRole',
    LessonType = 'LessonType',
    Package = 'Package',
    PackageCourse = 'PackageCourse',
    PaymentMethod = 'PaymentMethod',
    ProcessorConfig = 'ProcessorConfig',
    ProgramType = 'ProgramType',
    Question = 'Question',
    QuestionNotUsed = 'Question_NotUsed',
    QuestionAnswer = 'QuestionAnswer',
    QuestionImportCharacterConversion = 'QuestionImportCharacterConversion',
    QuestionStatus = 'QuestionStatus',
    QuestionTag = 'QuestionTag',
    QuestionTagLink = 'QuestionTagLink',
    QuestionType = 'QuestionType',
    Quiz = 'Quiz',
    QuizOutcome = 'QuizOutcome',
    QuizQuestionAnswerNotUsed = 'QuizQuestionAnswer_NotUsed',
    QuizQuestionLink = 'QuizQuestionLink',
    QuizReferenceQuizLink = 'QuizReferenceQuizLink',
    QuizResult = 'QuizResult',
    QuizResultQuestion = 'QuizResultQuestion',
    QuizResultStatus = 'QuizResultStatus',
    QuizType = 'QuizType',
    ReferenceImage = 'ReferenceImage',
    ReferenceStatus = 'ReferenceStatus',
    RoAssignmentStudentCompletedDetails = 'roAssignmentStudentCompletedDetails',
    RoAssignmentStudentCourseLessonProgress = 'roAssignmentStudentCourseLessonProgress',
    RoFlightSchoolCourseUnique = 'roFlightSchoolCourseUnique',
    RoModuleLessonMaxOrder = 'roModuleLessonMaxOrder',
    RoUserRoleDetail = 'roUserRoleDetail',
    StandardColorSet = 'StandardColorSet',
    Student = 'Student',
    StudentCourse = 'StudentCourse',
    SubdomainSetupStatus = 'SubdomainSetupStatus',
    SubdomainType = 'SubdomainType',
    Subscription = 'Subscription',
    SubscriptionRole = 'SubscriptionRole',
    SubscriptionRoleTracking = 'SubscriptionRoleTracking',
    SubscriptionStatus = 'SubscriptionStatus',
    SubscriptionTier = 'SubscriptionTier',
    SubscriptionTierRole = 'SubscriptionTierRole',
    SubscriptionTierStatus = 'SubscriptionTierStatus',
    TemplateFieldAlignment = 'TemplateFieldAlignment',
    TemplateFieldType = 'TemplateFieldType',
    TemplateFontStyle = 'TemplateFontStyle',
    UserDetail = 'UserDetail',
    UserSubscriptionRole = 'UserSubscriptionRole',
    Video = 'Video',
    VideoHistory = 'VideoHistory',
    VideoLibrary = 'VideoLibrary',
    VideoStatus = 'VideoStatus',
    VideoStreamingProvider = 'VideoStreamingProvider',
    VideoTagLink = 'VideoTagLink',
}

export enum AppMetaDataLookups {
    AircraftTypeAllItems = 158,
    AssignmentAllItems = 200,
    AssignmentCourseLessonAllItems = 210,
    AssignmentStatusAllItems = 202,
    AssignmentStudentAllItems = 203,
    AssignmentTypeAllItems = 201,
    CourseBySchool = 207,
    CourseAllItems = 154,
    CourseEndorsementAllItems = 240,
    CourseEndorsementLessonAllItems = 241,
    CourseEndorsementStudentAllItems = 242,
    CourseEndorsementTemplateAllItems = 243,
    CourseEndorsementTemplateFieldAllItems = 244,
    CourseLessonByCourse = 206,
    CourseLessonWithDetail = 253,
    CourseLessonAllItems = 162,
    CourseLessonFlagAllItems = 250,
    CourseLessonFlagStatusAllItems = 252,
    CourseLessonFlagTypeAllItems = 251,
    CourseLessonProgressAllItems = 144,
    CourseLessonSessionAllItems = 175,
    CourseModificationHistoryAllItems = 255,
    CourseModuleAllItems = 161,
    CourseModuleProgressAllItems = 143,
    CourseTypeAllItems = 155,
    FlightSchoolAllItems = 187,
    FlightSchoolBrandingAllItems = 262,
    FlightSchoolCourseAllItems = 188,
    FlightSchoolGroupBySchool = 239,
    FlightSchoolGroupAllItems = 204,
    FlightSchoolGroupStudentBySchool = 238,
    FlightSchoolGroupStudentAllItems = 205,
    FlightSchoolInstructorAllItems = 228,
    FlightSchoolPackageAllItems = 191,
    FlightSchoolStatusAllItems = 186,
    FlightSchoolStudentOldAllItems = 275,
    InstructorAllItems = 227,
    InstructorsBySchool = 237,
    InstructorStatusAllItems = 235,
    InstructorStudentAllItems = 229,
    JoinRequestAllItems = 213,
    JoinRequestCourseAllItems = 218,
    JoinRequestEmailAllItems = 212,
    JoinRequestStatusAllItems = 219,
    JoinRequestTypeAllItems = 217,
    JoinRequestTypeRoleAllItems = 215,
    LessonTypeAllItems = 163,
    MediaAllItems = 82,
    MediaGroupAllItems = 66,
    ModuleByCourse = 195,
    PackageAllItems = 194,
    PackageCourseAllItems = 193,
    PaymentMethodAllItems = 273,
    ProcessorConfigAllItems = 271,
    ProgramTypeAllItems = 156,
    QuestionNotUsedAllItems = 177,
    QuestionAnswerAllItems = 181,
    QuestionImportCharacterConversionAllItems = 226,
    QuestionStatusAllItems = 196,
    QuestionTagAllItems = 178,
    QuestionTagLinkAllItems = 179,
    QuestionTypeAllItems = 172,
    QuizAllItems = 146,
    QuizOutcomeAllItems = 171,
    QuizQuestionAllItems = 147,
    QuizQuestionAnswerNotUsedAllItems = 180,
    QuizQuestionLinkAllItems = 176,
    QuizReferenceQuizLinkAllItems = 230,
    QuizResultAllItems = 149,
    QuizResultQuestionAllItems = 150,
    QuizResultStatusAllItems = 182,
    QuizTypeAllItems = 170,
    ReferenceImageAllItems = 248,
    ReferenceStatusAllItems = 254,
    RoAssignmentStudentCompletedDetailsAllItems = 249,
    RoAssignmentStudentCourseLessonProgressAllItems = 211,
    RoFlightSchoolCourseUniqueAllItems = 208,
    RoMediaAllItems = 79,
    RoModuleLessonMaxOrderAllItems = 173,
    RoUserRoleDetailAllItems = 236,
    StandardColorSetAllItems = 272,
    StudentBySchool = 209,
    StudentBySchoolCourse = 232,
    StudentAllItems = 153,
    StudentCourseAllItems = 174,
    SubdomainSetupStatusAllItems = 261,
    SubdomainTypeAllItems = 260,
    SubscriptionAllItems = 265,
    SubscriptionRoleAllItems = 267,
    SubscriptionRoleTrackingAllItems = 270,
    SubscriptionStatusAllItems = 266,
    SubscriptionTierAllItems = 263,
    SubscriptionTierRoleAllItems = 268,
    SubscriptionTierStatusAllItems = 264,
    TemplateFieldAlignmentAllItems = 247,
    TemplateFieldTypeAllItems = 245,
    TemplateFontStyleAllItems = 246,
    ThemeAllItems = 256,
    ThemePropertyAllItems = 257,
    ThemePropertyDefinitionAllItems = 258,
    ThemePropertyTypeAllItems = 259,
    UserAllItems = 54,
    UserDetailAllItems = 234,
    UserRoleAllItems = 51,
    UserSubscriptionRoleAllItems = 269,
    UserTypeAllItems = 137,
    VideoAllItems = 165,
    VideoHistoryAllItems = 233,
    VideoLibraryByStreamingProvider = 184,
    VideoLibraryAllItems = 167,
    VideosNotDeleted = 185,
    VideoStatusAllItems = 183,
    VideoStreamingProviderAllItems = 168,
    VideoTagLinkAllItems = 231,
}


// Generated Classes for MetaData service
export class AircraftTypeMetaData implements DwItem2<AircraftTypeEntity, AircraftTypeMetaDataAttributes,AircraftTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=149;
        this.ItemDetail.itemName='AircraftType';
        this.ItemDetail.keyName='AircraftTypeId';
    }

    CreateEntity(): AircraftTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AircraftTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AircraftTypeEntity, AircraftTypeMetaDataAttributes,AircraftTypeMetaDataRelations>(query, this);
    }
     Attributes = new AircraftTypeMetaDataAttributes();
     Relations = new AircraftTypeMetaDataRelations();

}


export type AircraftTypeQueryBuilder = DwQueryBuilderGen<AircraftTypeEntity, AircraftTypeMetaDataAttributes,AircraftTypeMetaDataRelations>


export class AircraftTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AircraftType';
    // Attributes
    AircraftTypeId = new DwItemAttribute(this.i, 'AircraftTypeId');
    AircraftType = new DwItemAttribute(this.i, 'AircraftType');
    AircraftTypeDescription = new DwItemAttribute(this.i, 'AircraftTypeDescription');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class AircraftTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('AircraftType|Course|AircraftTypeId', 'Course', 'Course');
    };
export class AssignmentMetaData implements DwItem2<AssignmentEntity, AssignmentMetaDataAttributes,AssignmentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=188;
        this.ItemDetail.itemName='Assignment';
        this.ItemDetail.keyName='AssignmentId';
    }

    CreateEntity(): AssignmentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AssignmentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AssignmentEntity, AssignmentMetaDataAttributes,AssignmentMetaDataRelations>(query, this);
    }
     Attributes = new AssignmentMetaDataAttributes();
     Relations = new AssignmentMetaDataRelations();

}


export type AssignmentQueryBuilder = DwQueryBuilderGen<AssignmentEntity, AssignmentMetaDataAttributes,AssignmentMetaDataRelations>


export class AssignmentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Assignment';
    // Attributes
    AssignmentId = new DwItemAttribute(this.i, 'AssignmentId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    AssignmentDescription = new DwItemAttribute(this.i, 'AssignmentDescription');
    AssignmentTypeId = new DwItemAttribute(this.i, 'AssignmentTypeId');
    AssignmentStatusId = new DwItemAttribute(this.i, 'AssignmentStatusId');
    AssignmentLinkUrl = new DwItemAttribute(this.i, 'AssignmentLinkUrl');
    MediaId = new DwItemAttribute(this.i, 'MediaId');
    DueDateTime = new DwItemAttribute(this.i, 'DueDateTime');
    FlightSchoolGroupId = new DwItemAttribute(this.i, 'FlightSchoolGroupId');
    AssignedDateTime = new DwItemAttribute(this.i, 'AssignedDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    ResetLessonProgress = new DwItemAttribute(this.i, 'ResetLessonProgress');
    UseGroupAssignment = new DwItemAttribute(this.i, 'UseGroupAssignment');
    InstructorId = new DwItemAttribute(this.i, 'InstructorId');
}

class AssignmentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Assignment Status */
            AssignmentStatus = new DwItemRelationship('AssignmentStatus|Assignment|AssignmentStatusId', 'AssignmentStatus', 'AssignmentStatus');
            /** Relation: Assignment Type */
            AssignmentType = new DwItemRelationship('AssignmentType|Assignment|AssignmentTypeId', 'AssignmentType', 'AssignmentType');
            /** Relation: Course */
            Course = new DwItemRelationship('Course|Assignment|CourseId', 'Course', 'Course');
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('CourseLesson|Assignment|CourseLessonId', 'FlightSchool', 'FlightSchool');
            /** Relation: Flight School Group */
            FlightSchoolGroup = new DwItemRelationship('FlightSchoolGroup|Assignment|FlightSchoolGroupId', 'FlightSchoolGroup', 'FlightSchoolGroup');
            /** Relation: Instructor */
            Instructor = new DwItemRelationship('Instructor|Assignment|InstructorId', 'Instructor', 'Instructor');
            /** Relation: Media */
            DwMedia = new DwItemRelationship('dw_Media|Assignment|MediaId', 'DwMedia', 'DwMedia');
            /** Relation: Assignment Course Lesson */
            AssignmentCourseLesson = new DwItemRelationship('Assignment|AssignmentCourseLesson|AssignmentId', 'AssignmentCourseLesson', 'AssignmentCourseLesson');
            /** Relation: Assignment Student */
            AssignmentStudent = new DwItemRelationship('Assignment|AssignmentStudent|AssignmentId', 'AssignmentStudent', 'AssignmentStudent');
    };
export class AssignmentCourseLessonMetaData implements DwItem2<AssignmentCourseLessonEntity, AssignmentCourseLessonMetaDataAttributes,AssignmentCourseLessonMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=195;
        this.ItemDetail.itemName='AssignmentCourseLesson';
        this.ItemDetail.keyName='AssignmentCourseLessonId';
    }

    CreateEntity(): AssignmentCourseLessonEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AssignmentCourseLessonQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AssignmentCourseLessonEntity, AssignmentCourseLessonMetaDataAttributes,AssignmentCourseLessonMetaDataRelations>(query, this);
    }
     Attributes = new AssignmentCourseLessonMetaDataAttributes();
     Relations = new AssignmentCourseLessonMetaDataRelations();

}


export type AssignmentCourseLessonQueryBuilder = DwQueryBuilderGen<AssignmentCourseLessonEntity, AssignmentCourseLessonMetaDataAttributes,AssignmentCourseLessonMetaDataRelations>


export class AssignmentCourseLessonMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AssignmentCourseLesson';
    // Attributes
    AssignmentCourseLessonId = new DwItemAttribute(this.i, 'AssignmentCourseLessonId');
    AssignmentId = new DwItemAttribute(this.i, 'AssignmentId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
}

class AssignmentCourseLessonMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('Assignment|AssignmentCourseLesson|AssignmentId', 'Assignment', 'Assignment');
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('CourseLesson|AssignmentCourseLesson|CourseLessonId', 'CourseLesson', 'CourseLesson');
    };
export class AssignmentStatusMetaData implements DwItem2<AssignmentStatusEntity, AssignmentStatusMetaDataAttributes,AssignmentStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=190;
        this.ItemDetail.itemName='AssignmentStatus';
        this.ItemDetail.keyName='AssignmentStatusId';
    }

    CreateEntity(): AssignmentStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AssignmentStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AssignmentStatusEntity, AssignmentStatusMetaDataAttributes,AssignmentStatusMetaDataRelations>(query, this);
    }
     Attributes = new AssignmentStatusMetaDataAttributes();
     Relations = new AssignmentStatusMetaDataRelations();

}


export type AssignmentStatusQueryBuilder = DwQueryBuilderGen<AssignmentStatusEntity, AssignmentStatusMetaDataAttributes,AssignmentStatusMetaDataRelations>


export class AssignmentStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AssignmentStatus';
    // Attributes
    AssignmentStatusId = new DwItemAttribute(this.i, 'AssignmentStatusId');
    AssignmentStatus = new DwItemAttribute(this.i, 'AssignmentStatus');
}

class AssignmentStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('AssignmentStatus|Assignment|AssignmentStatusId', 'Assignment', 'Assignment');
    };
export class AssignmentStudentMetaData implements DwItem2<AssignmentStudentEntity, AssignmentStudentMetaDataAttributes,AssignmentStudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=191;
        this.ItemDetail.itemName='AssignmentStudent';
        this.ItemDetail.keyName='AssignmentStudentId';
    }

    CreateEntity(): AssignmentStudentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AssignmentStudentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AssignmentStudentEntity, AssignmentStudentMetaDataAttributes,AssignmentStudentMetaDataRelations>(query, this);
    }
     Attributes = new AssignmentStudentMetaDataAttributes();
     Relations = new AssignmentStudentMetaDataRelations();

}


export type AssignmentStudentQueryBuilder = DwQueryBuilderGen<AssignmentStudentEntity, AssignmentStudentMetaDataAttributes,AssignmentStudentMetaDataRelations>


export class AssignmentStudentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AssignmentStudent';
    // Attributes
    AssignmentStudentId = new DwItemAttribute(this.i, 'AssignmentStudentId');
    AssignmentId = new DwItemAttribute(this.i, 'AssignmentId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
}

class AssignmentStudentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('Assignment|AssignmentStudent|AssignmentId', 'Assignment', 'Assignment');
            /** Relation: Student */
            Student = new DwItemRelationship('Student|AssignmentStudent|StudentId', 'Student', 'Student');
            /** Relation: Ro Assignment Student Completed Details */
            RoAssignmentStudentCompletedDetails = new DwItemRelationship('AssignmentStudent|RoAssignmentStudent|AssignmentStudentId', 'RoAssignmentStudentCompletedDetails', 'RoAssignmentStudentCompletedDetails');
            /** Relation: Ro Assignment Student Course Lesson Progress */
            RoAssignmentStudentCourseLessonProgress = new DwItemRelationship('AssignmentStudent|RoAssignmentStudentCourseLessonProgress', 'RoAssignmentStudentCourseLessonProgress', 'RoAssignmentStudentCourseLessonProgress');
    };
export class AssignmentTypeMetaData implements DwItem2<AssignmentTypeEntity, AssignmentTypeMetaDataAttributes,AssignmentTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=189;
        this.ItemDetail.itemName='AssignmentType';
        this.ItemDetail.keyName='AssignmentTypeId';
    }

    CreateEntity(): AssignmentTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AssignmentTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AssignmentTypeEntity, AssignmentTypeMetaDataAttributes,AssignmentTypeMetaDataRelations>(query, this);
    }
     Attributes = new AssignmentTypeMetaDataAttributes();
     Relations = new AssignmentTypeMetaDataRelations();

}


export type AssignmentTypeQueryBuilder = DwQueryBuilderGen<AssignmentTypeEntity, AssignmentTypeMetaDataAttributes,AssignmentTypeMetaDataRelations>


export class AssignmentTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AssignmentType';
    // Attributes
    AssignmentTypeId = new DwItemAttribute(this.i, 'AssignmentTypeId');
    AssignmentType = new DwItemAttribute(this.i, 'AssignmentType');
}

class AssignmentTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('AssignmentType|Assignment|AssignmentTypeId', 'Assignment', 'Assignment');
    };
export class CourseMetaData implements DwItem2<CourseEntity, CourseMetaDataAttributes,CourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=145;
        this.ItemDetail.itemName='Course';
        this.ItemDetail.keyName='CourseId';
    }

    CreateEntity(): CourseEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseEntity, CourseMetaDataAttributes,CourseMetaDataRelations>(query, this);
    }
     Attributes = new CourseMetaDataAttributes();
     Relations = new CourseMetaDataRelations();

}


export type CourseQueryBuilder = DwQueryBuilderGen<CourseEntity, CourseMetaDataAttributes,CourseMetaDataRelations>


export class CourseMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Course';
    // Attributes
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CourseName = new DwItemAttribute(this.i, 'CourseName');
    CourseDescription = new DwItemAttribute(this.i, 'CourseDescription');
    CourseTypeId = new DwItemAttribute(this.i, 'CourseTypeId');
    CourseImageMediaId = new DwItemAttribute(this.i, 'CourseImageMediaId');
    CourseNotes = new DwItemAttribute(this.i, 'CourseNotes');
    AircraftTypeId = new DwItemAttribute(this.i, 'AircraftTypeId');
    AvionicsType = new DwItemAttribute(this.i, 'AvionicsType');
    ProgramTypeId = new DwItemAttribute(this.i, 'ProgramTypeId');
    RetailPrice = new DwItemAttribute(this.i, 'RetailPrice');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    CourseStatusId = new DwItemAttribute(this.i, 'CourseStatusId');
}

class CourseMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Aircraft Type */
            AircraftType = new DwItemRelationship('AircraftType|Course|AircraftTypeId', 'AircraftType', 'AircraftType');
            /** Relation: Course Type */
            CourseType = new DwItemRelationship('CourseType|Course|CourseTypeId', 'CourseType', 'CourseType');
            /** Relation: Media - Course Image Media */
            DwMediaByCourseImageMediaId = new DwItemRelationship('dw_Media|Course|CourseImageMediaId', 'CourseImageDwMedia', 'CourseImageDwMedia');
            /** Relation: Ro Media - Course Image Media */
            DwRoMediaByCourseImageMediaId = new DwItemRelationship('Course|roMedia', 'CourseImageDwroMedia', 'CourseImageDwroMedia');
            /** Relation: Program Type */
            ProgramType = new DwItemRelationship('ProgramType|Course|ProgramTypeId', 'ProgramType', 'ProgramType');
            /** Relation: Reference Status - Course Status */
            ReferenceStatusByCourseStatusId = new DwItemRelationship('ReferenceStatus|Course|CourseStatusId', 'CourseStatusIdReferenceStatus', 'CourseStatusIdReferenceStatus');
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('Course|Assignment|CourseId', 'Assignment', 'Assignment');
            /** Relation: Course Endorsement */
            CourseEndorsement = new DwItemRelationship('Course|CourseEndorsement|CourseId', 'CourseEndorsement', 'CourseEndorsement');
            /** Relation: Course Lesson Flag */
            CourseLessonFlag = new DwItemRelationship('Course|CourseLessonFlag|CourseId', 'CourseLessonFlag', 'CourseLessonFlag');
            /** Relation: Course Modification History */
            CourseModificationHistory = new DwItemRelationship('Course|CourseModificationHistory|CourseId', 'CourseModificationHistory', 'CourseModificationHistory');
            /** Relation: Module */
            CourseModule = new DwItemRelationship('Course|CourseModule|CourseId', 'CourseModule', 'CourseModule');
            /** Relation: Flight School Course */
            FlightSchoolCourse = new DwItemRelationship('Course|FlightSchoolCourse|CourseId', 'FlightSchoolCourse', 'FlightSchoolCourse');
            /** Relation: Join Request Course */
            JoinRequestCourse = new DwItemRelationship('Course|JoinRequestCourse|CourseId', 'JoinRequestCourse', 'JoinRequestCourse');
            /** Relation: Package Course */
            PackageCourse = new DwItemRelationship('Course|PackageCourse|CourseId', 'PackageCourse', 'PackageCourse');
            /** Relation: Question */
            Question = new DwItemRelationship('Course|Question|CourseId', 'Question', 'Question');
            /** Relation: Ro Flight School Course Unique */
            RoFlightSchoolCourseUnique = new DwItemRelationship('Course|RoFlightSchoolCourseUnique', 'RoFlightSchoolCourseUnique', 'RoFlightSchoolCourseUnique');
            /** Relation: Student Course */
            StudentCourse = new DwItemRelationship('Course|StudentCourse|CourseId', 'StudentCourse', 'StudentCourse');
    };
export class CourseEndorsementMetaData implements DwItem2<CourseEndorsementEntity, CourseEndorsementMetaDataAttributes,CourseEndorsementMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=220;
        this.ItemDetail.itemName='CourseEndorsement';
        this.ItemDetail.keyName='CourseEndorsementId';
    }

    CreateEntity(): CourseEndorsementEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseEndorsementQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseEndorsementEntity, CourseEndorsementMetaDataAttributes,CourseEndorsementMetaDataRelations>(query, this);
    }
     Attributes = new CourseEndorsementMetaDataAttributes();
     Relations = new CourseEndorsementMetaDataRelations();

}


export type CourseEndorsementQueryBuilder = DwQueryBuilderGen<CourseEndorsementEntity, CourseEndorsementMetaDataAttributes,CourseEndorsementMetaDataRelations>


export class CourseEndorsementMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseEndorsement';
    // Attributes
    CourseEndorsementId = new DwItemAttribute(this.i, 'CourseEndorsementId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    EndorsementName = new DwItemAttribute(this.i, 'EndorsementName');
    ExpirationPeriodDays = new DwItemAttribute(this.i, 'ExpirationPeriodDays');
    EndorsementPDFMediaId = new DwItemAttribute(this.i, 'EndorsementPDFMediaId');
    EndorsementDescription = new DwItemAttribute(this.i, 'EndorsementDescription');
    CourseEndorsementTemplateId = new DwItemAttribute(this.i, 'CourseEndorsementTemplateId');
    RequireAllLessonsComplete = new DwItemAttribute(this.i, 'RequireAllLessonsComplete');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    IsEndorsementEnabled = new DwItemAttribute(this.i, 'IsEndorsementEnabled');
}

class CourseEndorsementMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|CourseEndorsement|CourseId', 'Course', 'Course');
            /** Relation: Course Endorsement Template */
            CourseEndorsementTemplate = new DwItemRelationship('CourseEndorsementTemplate|CourseEndorsement|CourseEndorsementTemplateId', 'CourseEndorsementTemplate', 'CourseEndorsementTemplate');
            /** Relation: Media - Endorsement PDF */
            DwMediaByEndorsementPDFMediaId = new DwItemRelationship('dw_Media|CourseEndorsement|EndorsementPDFMediaId', 'EndorsementPDFDwMedia', 'EndorsementPDFDwMedia');
            /** Relation: Course Endorsement Lesson */
            CourseEndorsementLesson = new DwItemRelationship('CourseEndorsement|CourseEndorsementLesson|CourseEndorsementId', 'CourseEndorsementLesson', 'CourseEndorsementLesson');
            /** Relation: Course Endorsement Student */
            CourseEndorsementStudent = new DwItemRelationship('CourseEndorsement|CourseEndorsementStudent|CourseEndorsementId', 'CourseEndorsementStudent', 'CourseEndorsementStudent');
    };
export class CourseEndorsementLessonMetaData implements DwItem2<CourseEndorsementLessonEntity, CourseEndorsementLessonMetaDataAttributes,CourseEndorsementLessonMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=221;
        this.ItemDetail.itemName='CourseEndorsementLesson';
        this.ItemDetail.keyName='CourseEndorsementLessonId';
    }

    CreateEntity(): CourseEndorsementLessonEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseEndorsementLessonQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseEndorsementLessonEntity, CourseEndorsementLessonMetaDataAttributes,CourseEndorsementLessonMetaDataRelations>(query, this);
    }
     Attributes = new CourseEndorsementLessonMetaDataAttributes();
     Relations = new CourseEndorsementLessonMetaDataRelations();

}


export type CourseEndorsementLessonQueryBuilder = DwQueryBuilderGen<CourseEndorsementLessonEntity, CourseEndorsementLessonMetaDataAttributes,CourseEndorsementLessonMetaDataRelations>


export class CourseEndorsementLessonMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseEndorsementLesson';
    // Attributes
    CourseEndorsementLessonId = new DwItemAttribute(this.i, 'CourseEndorsementLessonId');
    CourseEndorsementId = new DwItemAttribute(this.i, 'CourseEndorsementId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
}

class CourseEndorsementLessonMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement */
            CourseEndorsement = new DwItemRelationship('CourseEndorsement|CourseEndorsementLesson|CourseEndorsementId', 'CourseEndorsement', 'CourseEndorsement');
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('CourseLesson|CourseEndorsementLesson|CourseLessonId', 'CourseLesson', 'CourseLesson');
    };
export class CourseEndorsementStudentMetaData implements DwItem2<CourseEndorsementStudentEntity, CourseEndorsementStudentMetaDataAttributes,CourseEndorsementStudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=222;
        this.ItemDetail.itemName='CourseEndorsementStudent';
        this.ItemDetail.keyName='CourseEndorsementStudentId';
    }

    CreateEntity(): CourseEndorsementStudentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseEndorsementStudentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseEndorsementStudentEntity, CourseEndorsementStudentMetaDataAttributes,CourseEndorsementStudentMetaDataRelations>(query, this);
    }
     Attributes = new CourseEndorsementStudentMetaDataAttributes();
     Relations = new CourseEndorsementStudentMetaDataRelations();

}


export type CourseEndorsementStudentQueryBuilder = DwQueryBuilderGen<CourseEndorsementStudentEntity, CourseEndorsementStudentMetaDataAttributes,CourseEndorsementStudentMetaDataRelations>


export class CourseEndorsementStudentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseEndorsementStudent';
    // Attributes
    CourseEndorsementStudentId = new DwItemAttribute(this.i, 'CourseEndorsementStudentId');
    CourseEndorsementId = new DwItemAttribute(this.i, 'CourseEndorsementId');
    StudentCourseId = new DwItemAttribute(this.i, 'StudentCourseId');
    CompletionDateTime = new DwItemAttribute(this.i, 'CompletionDateTime');
    ExpirationDateTime = new DwItemAttribute(this.i, 'ExpirationDateTime');
    EndorsementPDFMediaId = new DwItemAttribute(this.i, 'EndorsementPDFMediaId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class CourseEndorsementStudentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement */
            CourseEndorsement = new DwItemRelationship('CourseEndorsement|CourseEndorsementStudent|CourseEndorsementId', 'CourseEndorsement', 'CourseEndorsement');
            /** Relation: Media - Endorsement P D F Media */
            DwMediaByEndorsementPDFMediaId = new DwItemRelationship('dw_Media|CourseEndorsementStudent|EndorsementPDFMediaId', 'EndorsementPDFDwMedia', 'EndorsementPDFDwMedia');
            /** Relation: Student Course */
            StudentCourse = new DwItemRelationship('StudentCourse|CourseEndorsementStudent|StudentCourseId', 'StudentCourse', 'StudentCourse');
    };
export class CourseEndorsementTemplateMetaData implements DwItem2<CourseEndorsementTemplateEntity, CourseEndorsementTemplateMetaDataAttributes,CourseEndorsementTemplateMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=223;
        this.ItemDetail.itemName='CourseEndorsementTemplate';
        this.ItemDetail.keyName='CourseEndorsementTemplateId';
    }

    CreateEntity(): CourseEndorsementTemplateEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseEndorsementTemplateQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseEndorsementTemplateEntity, CourseEndorsementTemplateMetaDataAttributes,CourseEndorsementTemplateMetaDataRelations>(query, this);
    }
     Attributes = new CourseEndorsementTemplateMetaDataAttributes();
     Relations = new CourseEndorsementTemplateMetaDataRelations();

}


export type CourseEndorsementTemplateQueryBuilder = DwQueryBuilderGen<CourseEndorsementTemplateEntity, CourseEndorsementTemplateMetaDataAttributes,CourseEndorsementTemplateMetaDataRelations>


export class CourseEndorsementTemplateMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseEndorsementTemplate';
    // Attributes
    CourseEndorsementTemplateId = new DwItemAttribute(this.i, 'CourseEndorsementTemplateId');
    TemplateName = new DwItemAttribute(this.i, 'TemplateName');
    TemplatePDFMediaId = new DwItemAttribute(this.i, 'TemplatePDFMediaId');
    OverlayPDFMediaId = new DwItemAttribute(this.i, 'OverlayPDFMediaId');
}

class CourseEndorsementTemplateMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement */
            CourseEndorsement = new DwItemRelationship('CourseEndorsementTemplate|CourseEndorsement|CourseEndorsementTemplateId', 'CourseEndorsement', 'CourseEndorsement');
            /** Relation: Course Endorsement Template Field */
            CourseEndorsementTemplateField = new DwItemRelationship('CourseEndorsementTemplate|CourseEndorsementTemplateField|CourseEndorsementTemplateId', 'CourseEndorsementTemplateField', 'CourseEndorsementTemplateField');
    };
export class CourseEndorsementTemplateFieldMetaData implements DwItem2<CourseEndorsementTemplateFieldEntity, CourseEndorsementTemplateFieldMetaDataAttributes,CourseEndorsementTemplateFieldMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=224;
        this.ItemDetail.itemName='CourseEndorsementTemplateField';
        this.ItemDetail.keyName='CourseEndorsementTemplateFieldId';
    }

    CreateEntity(): CourseEndorsementTemplateFieldEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseEndorsementTemplateFieldQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseEndorsementTemplateFieldEntity, CourseEndorsementTemplateFieldMetaDataAttributes,CourseEndorsementTemplateFieldMetaDataRelations>(query, this);
    }
     Attributes = new CourseEndorsementTemplateFieldMetaDataAttributes();
     Relations = new CourseEndorsementTemplateFieldMetaDataRelations();

}


export type CourseEndorsementTemplateFieldQueryBuilder = DwQueryBuilderGen<CourseEndorsementTemplateFieldEntity, CourseEndorsementTemplateFieldMetaDataAttributes,CourseEndorsementTemplateFieldMetaDataRelations>


export class CourseEndorsementTemplateFieldMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseEndorsementTemplateField';
    // Attributes
    CourseEndorsementTemplateFieldId = new DwItemAttribute(this.i, 'CourseEndorsementTemplateFieldId');
    CourseEndorsementTemplateId = new DwItemAttribute(this.i, 'CourseEndorsementTemplateId');
    TemplateFieldTypeId = new DwItemAttribute(this.i, 'TemplateFieldTypeId');
    TemplateFieldDefaultValue = new DwItemAttribute(this.i, 'TemplateFieldDefaultValue');
    MetaDataAttributeId = new DwItemAttribute(this.i, 'MetaDataAttributeId');
    TopPosition = new DwItemAttribute(this.i, 'TopPosition');
    LeftPosition = new DwItemAttribute(this.i, 'LeftPosition');
    RightPosition = new DwItemAttribute(this.i, 'RightPosition');
    Height = new DwItemAttribute(this.i, 'Height');
    TemplateFontStyleId = new DwItemAttribute(this.i, 'TemplateFontStyleId');
    TemplateFieldAlignmentId = new DwItemAttribute(this.i, 'TemplateFieldAlignmentId');
    ReferenceImageId = new DwItemAttribute(this.i, 'ReferenceImageId');
}

class CourseEndorsementTemplateFieldMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement Template */
            CourseEndorsementTemplate = new DwItemRelationship('CourseEndorsementTemplate|CourseEndorsementTemplateField|CourseEndorsementTemplateId', 'CourseEndorsementTemplate', 'CourseEndorsementTemplate');
            /** Relation: Reference Image */
            ReferenceImage = new DwItemRelationship('ReferenceImage|CourseEndorsementTemplateField|ReferenceImageId', 'ReferenceImage', 'ReferenceImage');
            /** Relation: Template Field Alignment */
            TemplateFieldAlignment = new DwItemRelationship('TemplateFieldAlignment|CourseEndorsementTemplateField|TemplateFieldAlignmentId', 'TemplateFieldAlignment', 'TemplateFieldAlignment');
            /** Relation: Template Field Type */
            TemplateFieldType = new DwItemRelationship('TemplateFieldType|CourseEndorsementTemplateField|TemplateFieldTypeId', 'TemplateFieldType', 'TemplateFieldType');
            /** Relation: Template Font Style */
            TemplateFontStyle = new DwItemRelationship('TemplateFontStyle|CourseEndorsementTemplateField|TemplateFontStyleId', 'TemplateFontStyle', 'TemplateFontStyle');
    };
export class CourseLessonMetaData implements DwItem2<CourseLessonEntity, CourseLessonMetaDataAttributes,CourseLessonMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=153;
        this.ItemDetail.itemName='CourseLesson';
        this.ItemDetail.keyName='CourseLessonId';
    }

    CreateEntity(): CourseLessonEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseLessonQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseLessonEntity, CourseLessonMetaDataAttributes,CourseLessonMetaDataRelations>(query, this);
    }
     Attributes = new CourseLessonMetaDataAttributes();
     Relations = new CourseLessonMetaDataRelations();

}


export type CourseLessonQueryBuilder = DwQueryBuilderGen<CourseLessonEntity, CourseLessonMetaDataAttributes,CourseLessonMetaDataRelations>


export class CourseLessonMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLesson';
    // Attributes
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    LessonName = new DwItemAttribute(this.i, 'LessonName');
    LessonDescription = new DwItemAttribute(this.i, 'LessonDescription');
    LessonTypeId = new DwItemAttribute(this.i, 'LessonTypeId');
    VideoId = new DwItemAttribute(this.i, 'VideoId');
    QuizId = new DwItemAttribute(this.i, 'QuizId');
    LessonOrder = new DwItemAttribute(this.i, 'LessonOrder');
    LessonImageMediaId = new DwItemAttribute(this.i, 'LessonImageMediaId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    LessonReferebcePdfMediaId = new DwItemAttribute(this.i, 'LessonReferebcePdfMediaId');
    LessonReferenceNotes = new DwItemAttribute(this.i, 'LessonReferenceNotes');
    LessonStatusId = new DwItemAttribute(this.i, 'LessonStatusId');
}

class CourseLessonMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Module */
            CourseModule = new DwItemRelationship('CourseModule|CourseLesson|CourseModuleId', 'CourseModule', 'CourseModule');
            /** Relation: Lesson Type */
            LessonType = new DwItemRelationship('LessonType|CourseLesson|LessonTypeId', 'LessonType', 'LessonType');
            /** Relation: Ro Media - Lesson Image Media */
            DwRoMediaByLessonImageMediaId = new DwItemRelationship('RoMedia|CourseLesson|LessonImageMediaId', 'LessonImageDwroMedia', 'LessonImageDwroMedia');
            /** Relation: Quiz */
            Quiz = new DwItemRelationship('Quiz|CourseLesson|QuizId', 'Quiz', 'Quiz');
            /** Relation: Reference Status - Lesson Status */
            ReferenceStatusByLessonStatusId = new DwItemRelationship('ReferenceStatus|CourseLesson|LessonStatusId', 'LessonStatusIdReferenceStatus', 'LessonStatusIdReferenceStatus');
            /** Relation: Video */
            Video = new DwItemRelationship('Video|CourseLesson|VideoId', 'Video', 'Video');
            /** Relation: Assignment Course Lesson */
            AssignmentCourseLesson = new DwItemRelationship('CourseLesson|AssignmentCourseLesson|CourseLessonId', 'AssignmentCourseLesson', 'AssignmentCourseLesson');
            /** Relation: Course Endorsement Lesson */
            CourseEndorsementLesson = new DwItemRelationship('CourseLesson|CourseEndorsementLesson|CourseLessonId', 'CourseEndorsementLesson', 'CourseEndorsementLesson');
            /** Relation: Course Lesson Flag */
            CourseLessonFlag = new DwItemRelationship('CourseLesson|CourseLessonFlag|CourseLessonId', 'CourseLessonFlag', 'CourseLessonFlag');
            /** Relation: Course Lesson Progress */
            CourseLessonProgress = new DwItemRelationship('CourseLesson|CourseLessonProgress|CourseLessonId', 'CourseLessonProgress', 'CourseLessonProgress');
            /** Relation: Ro Assignment Student Course Lesson Progress */
            RoAssignmentStudentCourseLessonProgress = new DwItemRelationship('Lesson|RoAssignmentStudentCourseLessonProgress', 'RoAssignmentStudentCourseLessonProgress', 'RoAssignmentStudentCourseLessonProgress');
    };
export class CourseLessonFlagMetaData implements DwItem2<CourseLessonFlagEntity, CourseLessonFlagMetaDataAttributes,CourseLessonFlagMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=230;
        this.ItemDetail.itemName='CourseLessonFlag';
        this.ItemDetail.keyName='CourseLessonFlagId';
    }

    CreateEntity(): CourseLessonFlagEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseLessonFlagQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseLessonFlagEntity, CourseLessonFlagMetaDataAttributes,CourseLessonFlagMetaDataRelations>(query, this);
    }
     Attributes = new CourseLessonFlagMetaDataAttributes();
     Relations = new CourseLessonFlagMetaDataRelations();

}


export type CourseLessonFlagQueryBuilder = DwQueryBuilderGen<CourseLessonFlagEntity, CourseLessonFlagMetaDataAttributes,CourseLessonFlagMetaDataRelations>


export class CourseLessonFlagMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLessonFlag';
    // Attributes
    CourseLessonFlagId = new DwItemAttribute(this.i, 'CourseLessonFlagId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    QuizId = new DwItemAttribute(this.i, 'QuizId');
    QuestionId = new DwItemAttribute(this.i, 'QuestionId');
    FlagComment = new DwItemAttribute(this.i, 'FlagComment');
    CourseLessonFlagStatusId = new DwItemAttribute(this.i, 'CourseLessonFlagStatusId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    CourseLessonFlagTypeId = new DwItemAttribute(this.i, 'CourseLessonFlagTypeId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
}

class CourseLessonFlagMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|CourseLessonFlag|CourseId', 'Course', 'Course');
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('CourseLesson|CourseLessonFlag|CourseLessonId', 'CourseLesson', 'CourseLesson');
            /** Relation: Course Lesson Flag Status */
            CourseLessonFlagStatus = new DwItemRelationship('CourseLessonFlagStatus|CourseLessonFlag|CourseLessonFlagStatusId', 'CourseLessonFlagStatus', 'CourseLessonFlagStatus');
            /** Relation: Course Lesson Flag Type */
            CourseLessonFlagType = new DwItemRelationship('CourseLessonFlagType|CourseLessonFlag|CourseLessonFlagTypeId', 'CourseLessonFlagType', 'CourseLessonFlagType');
            /** Relation: Question */
            Question = new DwItemRelationship('Question|CourseLessonFlag|QuestionId', 'Question', 'Question');
            /** Relation: Quiz */
            Quiz = new DwItemRelationship('Quiz|CourseLessonFlag|QuizId', 'Quiz', 'Quiz');
            /** Relation: User - Modified By */
            DwUserByModifiedBy = new DwItemRelationship('dw_User|CourseLessonFlag|ModifiedBy', 'ModifiedByDwUser', 'ModifiedByDwUser');
            /** Relation: User - Created By */
            DwUserByCreatedBy = new DwItemRelationship('dw_User|CourseLessonFlag|CreatedBy', 'CreatedByDwUser', 'CreatedByDwUser');
    };
export class CourseLessonFlagStatusMetaData implements DwItem2<CourseLessonFlagStatusEntity, CourseLessonFlagStatusMetaDataAttributes,CourseLessonFlagStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=232;
        this.ItemDetail.itemName='CourseLessonFlagStatus';
        this.ItemDetail.keyName='CourseLessonFlagStatusId';
    }

    CreateEntity(): CourseLessonFlagStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseLessonFlagStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseLessonFlagStatusEntity, CourseLessonFlagStatusMetaDataAttributes,CourseLessonFlagStatusMetaDataRelations>(query, this);
    }
     Attributes = new CourseLessonFlagStatusMetaDataAttributes();
     Relations = new CourseLessonFlagStatusMetaDataRelations();

}


export type CourseLessonFlagStatusQueryBuilder = DwQueryBuilderGen<CourseLessonFlagStatusEntity, CourseLessonFlagStatusMetaDataAttributes,CourseLessonFlagStatusMetaDataRelations>


export class CourseLessonFlagStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLessonFlagStatus';
    // Attributes
    CourseLessonFlagStatusId = new DwItemAttribute(this.i, 'CourseLessonFlagStatusId');
    FlagStatus = new DwItemAttribute(this.i, 'FlagStatus');
}

class CourseLessonFlagStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Lesson Flag */
            CourseLessonFlag = new DwItemRelationship('CourseLessonFlagStatus|CourseLessonFlag|CourseLessonFlagStatusId', 'CourseLessonFlag', 'CourseLessonFlag');
    };
export class CourseLessonFlagTypeMetaData implements DwItem2<CourseLessonFlagTypeEntity, CourseLessonFlagTypeMetaDataAttributes,CourseLessonFlagTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=231;
        this.ItemDetail.itemName='CourseLessonFlagType';
        this.ItemDetail.keyName='CourseLessonFlagTypeId';
    }

    CreateEntity(): CourseLessonFlagTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseLessonFlagTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseLessonFlagTypeEntity, CourseLessonFlagTypeMetaDataAttributes,CourseLessonFlagTypeMetaDataRelations>(query, this);
    }
     Attributes = new CourseLessonFlagTypeMetaDataAttributes();
     Relations = new CourseLessonFlagTypeMetaDataRelations();

}


export type CourseLessonFlagTypeQueryBuilder = DwQueryBuilderGen<CourseLessonFlagTypeEntity, CourseLessonFlagTypeMetaDataAttributes,CourseLessonFlagTypeMetaDataRelations>


export class CourseLessonFlagTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLessonFlagType';
    // Attributes
    CourseLessonFlagTypeId = new DwItemAttribute(this.i, 'CourseLessonFlagTypeId');
    FlagType = new DwItemAttribute(this.i, 'FlagType');
}

class CourseLessonFlagTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Lesson Flag */
            CourseLessonFlag = new DwItemRelationship('CourseLessonFlagType|CourseLessonFlag|CourseLessonFlagTypeId', 'CourseLessonFlag', 'CourseLessonFlag');
    };
export class CourseLessonProgressMetaData implements DwItem2<CourseLessonProgressEntity, CourseLessonProgressMetaDataAttributes,CourseLessonProgressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=135;
        this.ItemDetail.itemName='CourseLessonProgress';
        this.ItemDetail.keyName='CourseLessonProgressId';
    }

    CreateEntity(): CourseLessonProgressEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseLessonProgressQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseLessonProgressEntity, CourseLessonProgressMetaDataAttributes,CourseLessonProgressMetaDataRelations>(query, this);
    }
     Attributes = new CourseLessonProgressMetaDataAttributes();
     Relations = new CourseLessonProgressMetaDataRelations();

}


export type CourseLessonProgressQueryBuilder = DwQueryBuilderGen<CourseLessonProgressEntity, CourseLessonProgressMetaDataAttributes,CourseLessonProgressMetaDataRelations>


export class CourseLessonProgressMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLessonProgress';
    // Attributes
    CourseLessonProgressId = new DwItemAttribute(this.i, 'CourseLessonProgressId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    CourseModuleProgressId = new DwItemAttribute(this.i, 'CourseModuleProgressId');
    LessonComplete = new DwItemAttribute(this.i, 'LessonComplete');
    VideoFurthestTimeSeconds = new DwItemAttribute(this.i, 'VideoFurthestTimeSeconds');
    BestQuizResultId = new DwItemAttribute(this.i, 'BestQuizResultId');
    LastActivityDateTime = new DwItemAttribute(this.i, 'LastActivityDateTime');
    LessonNotes = new DwItemAttribute(this.i, 'LessonNotes');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    FirstActivityDateTime = new DwItemAttribute(this.i, 'FirstActivityDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    LessonProgressStatusId = new DwItemAttribute(this.i, 'LessonProgressStatusId');
}

class CourseLessonProgressMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('CourseLesson|CourseLessonProgress|CourseLessonId', 'CourseLesson', 'CourseLesson');
            /** Relation: Course Module Progress */
            CourseModuleProgress = new DwItemRelationship('CourseModuleProgress|CourseLessonProgress|CourseModuleProgressId', 'CourseModuleProgress', 'CourseModuleProgress');
            /** Relation: Quiz Result - Best Quiz Result */
            QuizResultByBestQuizResultId = new DwItemRelationship('QuizResult|CourseLessonProgress|BestQuizResultId', 'BestQuizResult', 'BestQuizResult');
            /** Relation: Reference Status - Lesson Progress Status */
            ReferenceStatusByLessonProgressStatusId = new DwItemRelationship('ReferenceStatus|CourseLessonProgress|LessonProgressStatusId', 'LessonProgressStatusIdReferenceStatus', 'LessonProgressStatusIdReferenceStatus');
            /** Relation: Course Lesson Session */
            CourseLessonSession = new DwItemRelationship('CourseLessonProgress|CourseLessonSession|CourseLessonProgressId', 'CourseLessonSession', 'CourseLessonSession');
            /** Relation: Quiz Result */
            QuizResult = new DwItemRelationship('CourseLessonProgress|QuizResult|CourseLessonProgressId', 'QuizResult', 'QuizResult');
            /** Relation: Ro Assignment Student Course Lesson Progress */
            RoAssignmentStudentCourseLessonProgress = new DwItemRelationship('CourseLessonProgress|RoAssignmentStudentCourseLessonProgress', 'RoAssignmentStudentCourseLessonProgress', 'RoAssignmentStudentCourseLessonProgress');
    };
export class CourseLessonSessionMetaData implements DwItem2<CourseLessonSessionEntity, CourseLessonSessionMetaDataAttributes,CourseLessonSessionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=166;
        this.ItemDetail.itemName='CourseLessonSession';
        this.ItemDetail.keyName='CourseLessonSessionId';
    }

    CreateEntity(): CourseLessonSessionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseLessonSessionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseLessonSessionEntity, CourseLessonSessionMetaDataAttributes,CourseLessonSessionMetaDataRelations>(query, this);
    }
     Attributes = new CourseLessonSessionMetaDataAttributes();
     Relations = new CourseLessonSessionMetaDataRelations();

}


export type CourseLessonSessionQueryBuilder = DwQueryBuilderGen<CourseLessonSessionEntity, CourseLessonSessionMetaDataAttributes,CourseLessonSessionMetaDataRelations>


export class CourseLessonSessionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLessonSession';
    // Attributes
    CourseLessonSessionId = new DwItemAttribute(this.i, 'CourseLessonSessionId');
    CourseLessonProgressId = new DwItemAttribute(this.i, 'CourseLessonProgressId');
    LessonCompleted = new DwItemAttribute(this.i, 'LessonCompleted');
    VideoId = new DwItemAttribute(this.i, 'VideoId');
    VideoFurthestTimeSeconds = new DwItemAttribute(this.i, 'VideoFurthestTimeSeconds');
    QuizResultId = new DwItemAttribute(this.i, 'QuizResultId');
    SessionStartDateTime = new DwItemAttribute(this.i, 'SessionStartDateTime');
    SessionEndDateTime = new DwItemAttribute(this.i, 'SessionEndDateTime');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    LessonSessionStatusId = new DwItemAttribute(this.i, 'LessonSessionStatusId');
}

class CourseLessonSessionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Lesson Progress */
            CourseLessonProgress = new DwItemRelationship('CourseLessonProgress|CourseLessonSession|CourseLessonProgressId', 'CourseLessonProgress', 'CourseLessonProgress');
            /** Relation: Quiz Result */
            QuizResult = new DwItemRelationship('QuizResult|CourseLessonSession|QuizResultId', 'QuizResult', 'QuizResult');
            /** Relation: Reference Status - Lesson Session Status */
            ReferenceStatusByLessonSessionStatusId = new DwItemRelationship('ReferenceStatus|CourseLessonSession|LessonSessionStatusId', 'LessonSessionStatusIdReferenceStatus', 'LessonSessionStatusIdReferenceStatus');
    };
export class CourseModificationHistoryMetaData implements DwItem2<CourseModificationHistoryEntity, CourseModificationHistoryMetaDataAttributes,CourseModificationHistoryMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=234;
        this.ItemDetail.itemName='CourseModificationHistory';
        this.ItemDetail.keyName='CourseModicationHistoryId';
    }

    CreateEntity(): CourseModificationHistoryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseModificationHistoryQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseModificationHistoryEntity, CourseModificationHistoryMetaDataAttributes,CourseModificationHistoryMetaDataRelations>(query, this);
    }
     Attributes = new CourseModificationHistoryMetaDataAttributes();
     Relations = new CourseModificationHistoryMetaDataRelations();

}


export type CourseModificationHistoryQueryBuilder = DwQueryBuilderGen<CourseModificationHistoryEntity, CourseModificationHistoryMetaDataAttributes,CourseModificationHistoryMetaDataRelations>


export class CourseModificationHistoryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseModificationHistory';
    // Attributes
    CourseModicationHistoryId = new DwItemAttribute(this.i, 'CourseModicationHistoryId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CourseChangeDateTime = new DwItemAttribute(this.i, 'CourseChangeDateTime');
    CourseChangeDetail = new DwItemAttribute(this.i, 'CourseChangeDetail');
    Processed = new DwItemAttribute(this.i, 'Processed');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class CourseModificationHistoryMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|CourseModificationHistory|CourseId', 'Course', 'Course');
    };
export class CourseModuleMetaData implements DwItem2<CourseModuleEntity, CourseModuleMetaDataAttributes,CourseModuleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=152;
        this.ItemDetail.itemName='CourseModule';
        this.ItemDetail.keyName='CourseModuleId';
    }

    CreateEntity(): CourseModuleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseModuleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseModuleEntity, CourseModuleMetaDataAttributes,CourseModuleMetaDataRelations>(query, this);
    }
     Attributes = new CourseModuleMetaDataAttributes();
     Relations = new CourseModuleMetaDataRelations();

}


export type CourseModuleQueryBuilder = DwQueryBuilderGen<CourseModuleEntity, CourseModuleMetaDataAttributes,CourseModuleMetaDataRelations>


export class CourseModuleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseModule';
    // Attributes
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    ModuleName = new DwItemAttribute(this.i, 'ModuleName');
    ModuleDescription = new DwItemAttribute(this.i, 'ModuleDescription');
    ModuleImageMediaId = new DwItemAttribute(this.i, 'ModuleImageMediaId');
    ModuleOrder = new DwItemAttribute(this.i, 'ModuleOrder');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModuleStatusId = new DwItemAttribute(this.i, 'ModuleStatusId');
}

class CourseModuleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|CourseModule|CourseId', 'Course', 'Course');
            /** Relation: Media - Module Image Media */
            DwMediaByModuleImageMediaId = new DwItemRelationship('dw_Media|CourseModule|ModuleImageMediaId', 'ModuleImageDwMedia', 'ModuleImageDwMedia');
            /** Relation: Ro Media - Module Image Media */
            DwRoMediaByModuleImageMediaId = new DwItemRelationship('CourseModule|roMedia', 'ModuleImageDwroMedia', 'ModuleImageDwroMedia');
            /** Relation: Reference Status - Module Status */
            ReferenceStatusByModuleStatusId = new DwItemRelationship('ReferenceStatus|CourseModule|ModuleStatusId', 'ModuleStatusIdReferenceStatus', 'ModuleStatusIdReferenceStatus');
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('CourseModule|CourseLesson|CourseModuleId', 'CourseLesson', 'CourseLesson');
            /** Relation: Course Module Progress */
            CourseModuleProgress = new DwItemRelationship('CourseModule|CourseModuleProgress|CourseModuleId', 'CourseModuleProgress', 'CourseModuleProgress');
            /** Relation: Question */
            Question = new DwItemRelationship('CourseModule|Question|CourseModuleId', 'Question', 'Question');
    };
export class CourseModuleProgressMetaData implements DwItem2<CourseModuleProgressEntity, CourseModuleProgressMetaDataAttributes,CourseModuleProgressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=134;
        this.ItemDetail.itemName='CourseModuleProgress';
        this.ItemDetail.keyName='CourseModuleProgressId';
    }

    CreateEntity(): CourseModuleProgressEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseModuleProgressQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseModuleProgressEntity, CourseModuleProgressMetaDataAttributes,CourseModuleProgressMetaDataRelations>(query, this);
    }
     Attributes = new CourseModuleProgressMetaDataAttributes();
     Relations = new CourseModuleProgressMetaDataRelations();

}


export type CourseModuleProgressQueryBuilder = DwQueryBuilderGen<CourseModuleProgressEntity, CourseModuleProgressMetaDataAttributes,CourseModuleProgressMetaDataRelations>


export class CourseModuleProgressMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseModuleProgress';
    // Attributes
    CourseModuleProgressId = new DwItemAttribute(this.i, 'CourseModuleProgressId');
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    LessonsCompleted = new DwItemAttribute(this.i, 'LessonsCompleted');
    LessonsCount = new DwItemAttribute(this.i, 'LessonsCount');
    StudentCourseId = new DwItemAttribute(this.i, 'StudentCourseId');
    LastActivityDateTime = new DwItemAttribute(this.i, 'LastActivityDateTime');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    FirstActivityDateTime = new DwItemAttribute(this.i, 'FirstActivityDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModuleProgressStatusId = new DwItemAttribute(this.i, 'ModuleProgressStatusId');
}

class CourseModuleProgressMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Module */
            CourseModule = new DwItemRelationship('CourseModule|CourseModuleProgress|CourseModuleId', 'CourseModule', 'CourseModule');
            /** Relation: Reference Status - Module Progress Status */
            ReferenceStatusByModuleProgressStatusId = new DwItemRelationship('ReferenceStatus|CourseModuleProgress|ModuleProgressStatusId', 'ModuleProgressStatusIdReferenceStatus', 'ModuleProgressStatusIdReferenceStatus');
            /** Relation: Student Course */
            StudentCourse = new DwItemRelationship('StudentCourse|CourseModuleProgress|StudentCourseId', 'StudentCourse', 'StudentCourse');
            /** Relation: Course Lesson Progress */
            CourseLessonProgress = new DwItemRelationship('CourseModuleProgress|CourseLessonProgress|CourseModuleProgressId', 'CourseLessonProgress', 'CourseLessonProgress');
    };
export class CourseTypeMetaData implements DwItem2<CourseTypeEntity, CourseTypeMetaDataAttributes,CourseTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=146;
        this.ItemDetail.itemName='CourseType';
        this.ItemDetail.keyName='CourseTypeId';
    }

    CreateEntity(): CourseTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseTypeEntity, CourseTypeMetaDataAttributes,CourseTypeMetaDataRelations>(query, this);
    }
     Attributes = new CourseTypeMetaDataAttributes();
     Relations = new CourseTypeMetaDataRelations();

}


export type CourseTypeQueryBuilder = DwQueryBuilderGen<CourseTypeEntity, CourseTypeMetaDataAttributes,CourseTypeMetaDataRelations>


export class CourseTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseType';
    // Attributes
    CourseTypeId = new DwItemAttribute(this.i, 'CourseTypeId');
    CourseType = new DwItemAttribute(this.i, 'CourseType');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class CourseTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('CourseType|Course|CourseTypeId', 'Course', 'Course');
    };
export class FlightSchoolMetaData implements DwItem2<FlightSchoolEntity, FlightSchoolMetaDataAttributes,FlightSchoolMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=176;
        this.ItemDetail.itemName='FlightSchool';
        this.ItemDetail.keyName='FlightSchoolId';
    }

    CreateEntity(): FlightSchoolEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolEntity, FlightSchoolMetaDataAttributes,FlightSchoolMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolMetaDataAttributes();
     Relations = new FlightSchoolMetaDataRelations();

}


export type FlightSchoolQueryBuilder = DwQueryBuilderGen<FlightSchoolEntity, FlightSchoolMetaDataAttributes,FlightSchoolMetaDataRelations>


export class FlightSchoolMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchool';
    // Attributes
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    FlightSchoolName = new DwItemAttribute(this.i, 'FlightSchoolName');
    FlightSchoolStatusId = new DwItemAttribute(this.i, 'FlightSchoolStatusId');
    NumberOfAircraft = new DwItemAttribute(this.i, 'NumberOfAircraft');
    Location = new DwItemAttribute(this.i, 'Location');
    NumberOfStudents = new DwItemAttribute(this.i, 'NumberOfStudents');
    PercentDiscount = new DwItemAttribute(this.i, 'PercentDiscount');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    IsDefaultFlightSchool = new DwItemAttribute(this.i, 'IsDefaultFlightSchool');
    FlightSchoolBrandingId = new DwItemAttribute(this.i, 'FlightSchoolBrandingId');
}

class FlightSchoolMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School Branding */
            FlightSchoolBranding = new DwItemRelationship('FlightSchoolBranding|FlightSchool|FlightSchoolBrandingId', 'FlightSchoolBranding', 'FlightSchoolBranding');
            /** Relation: Flight School Status */
            FlightSchoolStatus = new DwItemRelationship('FlightSchoolStatus|FlightSchool|FlightSchoolStatusId', 'FlightSchoolStatus', 'FlightSchoolStatus');
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('CourseLesson|Assignment|CourseLessonId', 'Assignment', 'Assignment');
            /** Relation: Flight School Course */
            FlightSchoolCourse = new DwItemRelationship('FlightSchool|FlightSchoolCourse|FlightSchoolId', 'FlightSchoolCourse', 'FlightSchoolCourse');
            /** Relation: Flight School Group */
            FlightSchoolGroup = new DwItemRelationship('FlightSchool|FlightSchoolGroup|FlightSchoolId', 'FlightSchoolGroup', 'FlightSchoolGroup');
            /** Relation: Flight School Instructor */
            FlightSchoolInstructor = new DwItemRelationship('FlightSchool|FlightSchoolInstructor|FlightSchoolId', 'FlightSchoolInstructor', 'FlightSchoolInstructor');
            /** Relation: Flight School Package */
            FlightSchoolPackage = new DwItemRelationship('FlightSchool|FlightSchoolPackage|FlightSchoolId', 'FlightSchoolPackage', 'FlightSchoolPackage');
            /** Relation: Flight School Student Old */
            FlightSchoolStudentOld = new DwItemRelationship('FlightSchool|FlightSchoolStudent|FlightSchoolId', 'FlightSchoolStudentOld', 'FlightSchoolStudentOld');
            /** Relation: Instructor */
            Instructor = new DwItemRelationship('FlightSchool|Instructor|FlightSchoolId', 'Instructor', 'Instructor');
            /** Relation: Join Request */
            JoinRequest = new DwItemRelationship('FlightSchool|JoinRequest|FlightSchoolId', 'JoinRequest', 'JoinRequest');
            /** Relation: Processor Config */
            ProcessorConfig = new DwItemRelationship('FlightSchool|ProcessorConfig|FlightSchoolId', 'ProcessorConfig', 'ProcessorConfig');
            /** Relation: Ro Flight School Course Unique */
            RoFlightSchoolCourseUnique = new DwItemRelationship('FlightSchool|RoFlightSchoolCourseUnique', 'RoFlightSchoolCourseUnique', 'RoFlightSchoolCourseUnique');
            /** Relation: Subscription */
            Subscription = new DwItemRelationship('FlightSchool|Subscription|FlightSchoolId', 'Subscription', 'Subscription');
            /** Relation: User Detail */
            UserDetail = new DwItemRelationship('FlightSchool|UserDetail|FlightSchoolId', 'UserDetail', 'UserDetail');
    };
export class FlightSchoolBrandingMetaData implements DwItem2<FlightSchoolBrandingEntity, FlightSchoolBrandingMetaDataAttributes,FlightSchoolBrandingMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=241;
        this.ItemDetail.itemName='FlightSchoolBranding';
        this.ItemDetail.keyName='FlightSchoolBrandingId';
    }

    CreateEntity(): FlightSchoolBrandingEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolBrandingQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolBrandingEntity, FlightSchoolBrandingMetaDataAttributes,FlightSchoolBrandingMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolBrandingMetaDataAttributes();
     Relations = new FlightSchoolBrandingMetaDataRelations();

}


export type FlightSchoolBrandingQueryBuilder = DwQueryBuilderGen<FlightSchoolBrandingEntity, FlightSchoolBrandingMetaDataAttributes,FlightSchoolBrandingMetaDataRelations>


export class FlightSchoolBrandingMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolBranding';
    // Attributes
    FlightSchoolBrandingId = new DwItemAttribute(this.i, 'FlightSchoolBrandingId');
    SubdomainSetupStatusId = new DwItemAttribute(this.i, 'SubdomainSetupStatusId');
    SubdomainTypeId = new DwItemAttribute(this.i, 'SubdomainTypeId');
    DomainName = new DwItemAttribute(this.i, 'DomainName');
    ThemeId = new DwItemAttribute(this.i, 'ThemeId');
    NavigationColorSetId = new DwItemAttribute(this.i, 'NavigationColorSetId');
    SidebarColorSetId = new DwItemAttribute(this.i, 'SidebarColorSetId');
    UseCustomColors = new DwItemAttribute(this.i, 'UseCustomColors');
    LoginColorSetId = new DwItemAttribute(this.i, 'LoginColorSetId');
}

class FlightSchoolBrandingMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Standard Color Set - Navigation Color Set */
            StandardColorSetByNavigationColorSetId = new DwItemRelationship('StandardColorSet|FlightSchoolBranding|NavigationColorSetId', 'NavigationColorSetIdStandardColorSet', 'NavigationColorSetIdStandardColorSet');
            /** Relation: Standard Color Set - Sidebar Color Set */
            StandardColorSetBySidebarColorSetId = new DwItemRelationship('StandardColorSet|FlightSchoolBranding|SidebarColorSetId', 'SidebarColorSetIdStandardColorSet', 'SidebarColorSetIdStandardColorSet');
            /** Relation: Subdomain Setup Status */
            SubdomainSetupStatus = new DwItemRelationship('SubdomainSetupStatus|FlightSchoolBranding|SubdomainSetupStatusId', 'SubdomainSetupStatus', 'SubdomainSetupStatus');
            /** Relation: Subdomain Type */
            SubdomainType = new DwItemRelationship('SubdomainType|FlightSchoolBranding|SubdomainTypeId', 'SubdomainType', 'SubdomainType');
            /** Relation: Theme */
            DwTheme = new DwItemRelationship('dw_Theme|FlightSchoolBranding|ThemeId', 'DwTheme', 'DwTheme');
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchoolBranding|FlightSchool|FlightSchoolBrandingId', 'FlightSchool', 'FlightSchool');
    };
export class FlightSchoolCourseMetaData implements DwItem2<FlightSchoolCourseEntity, FlightSchoolCourseMetaDataAttributes,FlightSchoolCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=177;
        this.ItemDetail.itemName='FlightSchoolCourse';
        this.ItemDetail.keyName='FlightSchoolCourseId';
    }

    CreateEntity(): FlightSchoolCourseEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolCourseQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolCourseEntity, FlightSchoolCourseMetaDataAttributes,FlightSchoolCourseMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolCourseMetaDataAttributes();
     Relations = new FlightSchoolCourseMetaDataRelations();

}


export type FlightSchoolCourseQueryBuilder = DwQueryBuilderGen<FlightSchoolCourseEntity, FlightSchoolCourseMetaDataAttributes,FlightSchoolCourseMetaDataRelations>


export class FlightSchoolCourseMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolCourse';
    // Attributes
    FlightSchoolCourseId = new DwItemAttribute(this.i, 'FlightSchoolCourseId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    SchoolCoursePrice = new DwItemAttribute(this.i, 'SchoolCoursePrice');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class FlightSchoolCourseMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|FlightSchoolCourse|CourseId', 'Course', 'Course');
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|FlightSchoolCourse|FlightSchoolId', 'FlightSchool', 'FlightSchool');
    };
export class FlightSchoolGroupMetaData implements DwItem2<FlightSchoolGroupEntity, FlightSchoolGroupMetaDataAttributes,FlightSchoolGroupMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=192;
        this.ItemDetail.itemName='FlightSchoolGroup';
        this.ItemDetail.keyName='FlightSchoolGroupId';
    }

    CreateEntity(): FlightSchoolGroupEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolGroupQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolGroupEntity, FlightSchoolGroupMetaDataAttributes,FlightSchoolGroupMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolGroupMetaDataAttributes();
     Relations = new FlightSchoolGroupMetaDataRelations();

}


export type FlightSchoolGroupQueryBuilder = DwQueryBuilderGen<FlightSchoolGroupEntity, FlightSchoolGroupMetaDataAttributes,FlightSchoolGroupMetaDataRelations>


export class FlightSchoolGroupMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolGroup';
    // Attributes
    FlightSchoolGroupId = new DwItemAttribute(this.i, 'FlightSchoolGroupId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    GroupName = new DwItemAttribute(this.i, 'GroupName');
}

class FlightSchoolGroupMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|FlightSchoolGroup|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('FlightSchoolGroup|Assignment|FlightSchoolGroupId', 'Assignment', 'Assignment');
            /** Relation: Flight School Group Student */
            FlightSchoolGroupStudent = new DwItemRelationship('FlightSchoolGroup|FlightSchoolGroupStudent|FlightSchoolGroupId', 'FlightSchoolGroupStudent', 'FlightSchoolGroupStudent');
            /** Relation: Join Request */
            JoinRequest = new DwItemRelationship('FlightSchoolGroup|JoinRequest|FlightSchoolStudentGroupId', 'JoinRequest', 'JoinRequest');
    };
export class FlightSchoolGroupStudentMetaData implements DwItem2<FlightSchoolGroupStudentEntity, FlightSchoolGroupStudentMetaDataAttributes,FlightSchoolGroupStudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=193;
        this.ItemDetail.itemName='FlightSchoolGroupStudent';
        this.ItemDetail.keyName='FlightSchoolGroupStudentId';
    }

    CreateEntity(): FlightSchoolGroupStudentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolGroupStudentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolGroupStudentEntity, FlightSchoolGroupStudentMetaDataAttributes,FlightSchoolGroupStudentMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolGroupStudentMetaDataAttributes();
     Relations = new FlightSchoolGroupStudentMetaDataRelations();

}


export type FlightSchoolGroupStudentQueryBuilder = DwQueryBuilderGen<FlightSchoolGroupStudentEntity, FlightSchoolGroupStudentMetaDataAttributes,FlightSchoolGroupStudentMetaDataRelations>


export class FlightSchoolGroupStudentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolGroupStudent';
    // Attributes
    FlightSchoolGroupStudentId = new DwItemAttribute(this.i, 'FlightSchoolGroupStudentId');
    FlightSchoolGroupId = new DwItemAttribute(this.i, 'FlightSchoolGroupId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
}

class FlightSchoolGroupStudentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School Group */
            FlightSchoolGroup = new DwItemRelationship('FlightSchoolGroup|FlightSchoolGroupStudent|FlightSchoolGroupId', 'FlightSchoolGroup', 'FlightSchoolGroup');
            /** Relation: Student */
            Student = new DwItemRelationship('Student|FlightSchoolGroupStudent|StudentId', 'Student', 'Student');
    };
export class FlightSchoolInstructorMetaData implements DwItem2<FlightSchoolInstructorEntity, FlightSchoolInstructorMetaDataAttributes,FlightSchoolInstructorMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=212;
        this.ItemDetail.itemName='FlightSchoolInstructor';
        this.ItemDetail.keyName='FlightSchoolInstructorId';
    }

    CreateEntity(): FlightSchoolInstructorEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolInstructorQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolInstructorEntity, FlightSchoolInstructorMetaDataAttributes,FlightSchoolInstructorMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolInstructorMetaDataAttributes();
     Relations = new FlightSchoolInstructorMetaDataRelations();

}


export type FlightSchoolInstructorQueryBuilder = DwQueryBuilderGen<FlightSchoolInstructorEntity, FlightSchoolInstructorMetaDataAttributes,FlightSchoolInstructorMetaDataRelations>


export class FlightSchoolInstructorMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolInstructor';
    // Attributes
    FlightSchoolInstructorId = new DwItemAttribute(this.i, 'FlightSchoolInstructorId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    InstructorId = new DwItemAttribute(this.i, 'InstructorId');
}

class FlightSchoolInstructorMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|FlightSchoolInstructor|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Instructor */
            Instructor = new DwItemRelationship('Instructor|FlightSchoolInstructor|InstructorId', 'Instructor', 'Instructor');
    };
export class FlightSchoolPackageMetaData implements DwItem2<FlightSchoolPackageEntity, FlightSchoolPackageMetaDataAttributes,FlightSchoolPackageMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=180;
        this.ItemDetail.itemName='FlightSchoolPackage';
        this.ItemDetail.keyName='FlightSchoolPackageId';
    }

    CreateEntity(): FlightSchoolPackageEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolPackageQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolPackageEntity, FlightSchoolPackageMetaDataAttributes,FlightSchoolPackageMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolPackageMetaDataAttributes();
     Relations = new FlightSchoolPackageMetaDataRelations();

}


export type FlightSchoolPackageQueryBuilder = DwQueryBuilderGen<FlightSchoolPackageEntity, FlightSchoolPackageMetaDataAttributes,FlightSchoolPackageMetaDataRelations>


export class FlightSchoolPackageMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolPackage';
    // Attributes
    FlightSchoolPackageId = new DwItemAttribute(this.i, 'FlightSchoolPackageId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    PackageId = new DwItemAttribute(this.i, 'PackageId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class FlightSchoolPackageMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|FlightSchoolPackage|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Package */
            Package = new DwItemRelationship('Package|FlightSchoolPackage|PackageId', 'Package', 'Package');
    };
export class FlightSchoolStatusMetaData implements DwItem2<FlightSchoolStatusEntity, FlightSchoolStatusMetaDataAttributes,FlightSchoolStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=175;
        this.ItemDetail.itemName='FlightSchoolStatus';
        this.ItemDetail.keyName='FlightSchoolStatusId';
    }

    CreateEntity(): FlightSchoolStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolStatusEntity, FlightSchoolStatusMetaDataAttributes,FlightSchoolStatusMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolStatusMetaDataAttributes();
     Relations = new FlightSchoolStatusMetaDataRelations();

}


export type FlightSchoolStatusQueryBuilder = DwQueryBuilderGen<FlightSchoolStatusEntity, FlightSchoolStatusMetaDataAttributes,FlightSchoolStatusMetaDataRelations>


export class FlightSchoolStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolStatus';
    // Attributes
    FlightSchoolStatusId = new DwItemAttribute(this.i, 'FlightSchoolStatusId');
    FlightSchoolStatus = new DwItemAttribute(this.i, 'FlightSchoolStatus');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class FlightSchoolStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchoolStatus|FlightSchool|FlightSchoolStatusId', 'FlightSchool', 'FlightSchool');
    };
export class FlightSchoolStudentOldMetaData implements DwItem2<FlightSchoolStudentOldEntity, FlightSchoolStudentOldMetaDataAttributes,FlightSchoolStudentOldMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=254;
        this.ItemDetail.itemName='FlightSchoolStudent_Old';
        this.ItemDetail.keyName='FlightSchoolStudentId';
    }

    CreateEntity(): FlightSchoolStudentOldEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolStudentOldQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolStudentOldEntity, FlightSchoolStudentOldMetaDataAttributes,FlightSchoolStudentOldMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolStudentOldMetaDataAttributes();
     Relations = new FlightSchoolStudentOldMetaDataRelations();

}


export type FlightSchoolStudentOldQueryBuilder = DwQueryBuilderGen<FlightSchoolStudentOldEntity, FlightSchoolStudentOldMetaDataAttributes,FlightSchoolStudentOldMetaDataRelations>


export class FlightSchoolStudentOldMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolStudent_Old';
    // Attributes
    FlightSchoolStudentId = new DwItemAttribute(this.i, 'FlightSchoolStudentId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class FlightSchoolStudentOldMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|FlightSchoolStudent|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Student */
            Student = new DwItemRelationship('Student|FlightSchoolStudent|StudentId', 'Student', 'Student');
    };
export class InstructorMetaData implements DwItem2<InstructorEntity, InstructorMetaDataAttributes,InstructorMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=211;
        this.ItemDetail.itemName='Instructor';
        this.ItemDetail.keyName='InstructorId';
    }

    CreateEntity(): InstructorEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): InstructorQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<InstructorEntity, InstructorMetaDataAttributes,InstructorMetaDataRelations>(query, this);
    }
     Attributes = new InstructorMetaDataAttributes();
     Relations = new InstructorMetaDataRelations();

}


export type InstructorQueryBuilder = DwQueryBuilderGen<InstructorEntity, InstructorMetaDataAttributes,InstructorMetaDataRelations>


export class InstructorMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Instructor';
    // Attributes
    InstructorId = new DwItemAttribute(this.i, 'InstructorId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    InstructorStatusId = new DwItemAttribute(this.i, 'InstructorStatusId');
}

class InstructorMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|Instructor|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Instructor Status */
            InstructorStatus = new DwItemRelationship('InstructorStatus|Instructor|InstructorStatusId', 'InstructorStatus', 'InstructorStatus');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|Instructor|UserId', 'DwUser', 'DwUser');
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('Instructor|Assignment|InstructorId', 'Assignment', 'Assignment');
            /** Relation: Flight School Instructor */
            FlightSchoolInstructor = new DwItemRelationship('Instructor|FlightSchoolInstructor|InstructorId', 'FlightSchoolInstructor', 'FlightSchoolInstructor');
            /** Relation: Instructor Student */
            InstructorStudent = new DwItemRelationship('Instructor|InstructorStudent|InstructorId', 'InstructorStudent', 'InstructorStudent');
    };
export class InstructorStatusMetaData implements DwItem2<InstructorStatusEntity, InstructorStatusMetaDataAttributes,InstructorStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=218;
        this.ItemDetail.itemName='InstructorStatus';
        this.ItemDetail.keyName='InstructorStatusId';
    }

    CreateEntity(): InstructorStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): InstructorStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<InstructorStatusEntity, InstructorStatusMetaDataAttributes,InstructorStatusMetaDataRelations>(query, this);
    }
     Attributes = new InstructorStatusMetaDataAttributes();
     Relations = new InstructorStatusMetaDataRelations();

}


export type InstructorStatusQueryBuilder = DwQueryBuilderGen<InstructorStatusEntity, InstructorStatusMetaDataAttributes,InstructorStatusMetaDataRelations>


export class InstructorStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'InstructorStatus';
    // Attributes
    InstructorStatusId = new DwItemAttribute(this.i, 'InstructorStatusId');
    InstructorStatus = new DwItemAttribute(this.i, 'InstructorStatus');
}

class InstructorStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Instructor */
            Instructor = new DwItemRelationship('InstructorStatus|Instructor|InstructorStatusId', 'Instructor', 'Instructor');
    };
export class InstructorStudentMetaData implements DwItem2<InstructorStudentEntity, InstructorStudentMetaDataAttributes,InstructorStudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=213;
        this.ItemDetail.itemName='InstructorStudent';
        this.ItemDetail.keyName='InstructorStudentId';
    }

    CreateEntity(): InstructorStudentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): InstructorStudentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<InstructorStudentEntity, InstructorStudentMetaDataAttributes,InstructorStudentMetaDataRelations>(query, this);
    }
     Attributes = new InstructorStudentMetaDataAttributes();
     Relations = new InstructorStudentMetaDataRelations();

}


export type InstructorStudentQueryBuilder = DwQueryBuilderGen<InstructorStudentEntity, InstructorStudentMetaDataAttributes,InstructorStudentMetaDataRelations>


export class InstructorStudentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'InstructorStudent';
    // Attributes
    InstructorStudentId = new DwItemAttribute(this.i, 'InstructorStudentId');
    InstructorId = new DwItemAttribute(this.i, 'InstructorId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
}

class InstructorStudentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Instructor */
            Instructor = new DwItemRelationship('Instructor|InstructorStudent|InstructorId', 'Instructor', 'Instructor');
            /** Relation: Student */
            Student = new DwItemRelationship('Student|InstructorStudent|StudentId', 'Student', 'Student');
    };
export class JoinRequestMetaData implements DwItem2<JoinRequestEntity, JoinRequestMetaDataAttributes,JoinRequestMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=198;
        this.ItemDetail.itemName='JoinRequest';
        this.ItemDetail.keyName='JoinRequestId';
    }

    CreateEntity(): JoinRequestEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): JoinRequestQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<JoinRequestEntity, JoinRequestMetaDataAttributes,JoinRequestMetaDataRelations>(query, this);
    }
     Attributes = new JoinRequestMetaDataAttributes();
     Relations = new JoinRequestMetaDataRelations();

}


export type JoinRequestQueryBuilder = DwQueryBuilderGen<JoinRequestEntity, JoinRequestMetaDataAttributes,JoinRequestMetaDataRelations>


export class JoinRequestMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'JoinRequest';
    // Attributes
    JoinRequestId = new DwItemAttribute(this.i, 'JoinRequestId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    JoinRequestTypeId = new DwItemAttribute(this.i, 'JoinRequestTypeId');
    MessageHtml = new DwItemAttribute(this.i, 'MessageHtml');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    UsePackage = new DwItemAttribute(this.i, 'UsePackage');
    CoursePackageId = new DwItemAttribute(this.i, 'CoursePackageId');
    JoinRequestStatusId = new DwItemAttribute(this.i, 'JoinRequestStatusId');
    AssignToGroup = new DwItemAttribute(this.i, 'AssignToGroup');
    FlightSchoolGroupId = new DwItemAttribute(this.i, 'FlightSchoolGroupId');
}

class JoinRequestMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|JoinRequest|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Flight School Group */
            FlightSchoolGroup = new DwItemRelationship('FlightSchoolGroup|JoinRequest|FlightSchoolStudentGroupId', 'FlightSchoolGroup', 'FlightSchoolGroup');
            /** Relation: Join Request Status */
            JoinRequestStatus = new DwItemRelationship('JoinRequestStatus|JoinRequest|JoinRequestStatusId', 'JoinRequestStatus', 'JoinRequestStatus');
            /** Relation: Join Request Type */
            JoinRequestType = new DwItemRelationship('JoinRequestType|JoinRequest|JoinRequestTypeId', 'JoinRequestType', 'JoinRequestType');
            /** Relation: Package - Course Package */
            PackageByCoursePackageId = new DwItemRelationship('Package|JoinRequest|CoursePackageId', 'CoursePackage', 'CoursePackage');
            /** Relation: Join Request Course */
            JoinRequestCourse = new DwItemRelationship('JoinRequest|JoinRequestCourse|JoinRequestId', 'JoinRequestCourse', 'JoinRequestCourse');
            /** Relation: Join Request Email */
            JoinRequestEmail = new DwItemRelationship('JoinRequest|JoinRequestEmail|JoinRequestId', 'JoinRequestEmail', 'JoinRequestEmail');
    };
export class JoinRequestCourseMetaData implements DwItem2<JoinRequestCourseEntity, JoinRequestCourseMetaDataAttributes,JoinRequestCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=202;
        this.ItemDetail.itemName='JoinRequestCourse';
        this.ItemDetail.keyName='JoinRequestCourseId';
    }

    CreateEntity(): JoinRequestCourseEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): JoinRequestCourseQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<JoinRequestCourseEntity, JoinRequestCourseMetaDataAttributes,JoinRequestCourseMetaDataRelations>(query, this);
    }
     Attributes = new JoinRequestCourseMetaDataAttributes();
     Relations = new JoinRequestCourseMetaDataRelations();

}


export type JoinRequestCourseQueryBuilder = DwQueryBuilderGen<JoinRequestCourseEntity, JoinRequestCourseMetaDataAttributes,JoinRequestCourseMetaDataRelations>


export class JoinRequestCourseMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'JoinRequestCourse';
    // Attributes
    JoinRequestCourseId = new DwItemAttribute(this.i, 'JoinRequestCourseId');
    JoinRequestId = new DwItemAttribute(this.i, 'JoinRequestId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
}

class JoinRequestCourseMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|JoinRequestCourse|CourseId', 'Course', 'Course');
            /** Relation: Join Request */
            JoinRequest = new DwItemRelationship('JoinRequest|JoinRequestCourse|JoinRequestId', 'JoinRequest', 'JoinRequest');
    };
export class JoinRequestEmailMetaData implements DwItem2<JoinRequestEmailEntity, JoinRequestEmailMetaDataAttributes,JoinRequestEmailMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=197;
        this.ItemDetail.itemName='JoinRequestEmail';
        this.ItemDetail.keyName='JoinRequestEmailId';
    }

    CreateEntity(): JoinRequestEmailEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): JoinRequestEmailQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<JoinRequestEmailEntity, JoinRequestEmailMetaDataAttributes,JoinRequestEmailMetaDataRelations>(query, this);
    }
     Attributes = new JoinRequestEmailMetaDataAttributes();
     Relations = new JoinRequestEmailMetaDataRelations();

}


export type JoinRequestEmailQueryBuilder = DwQueryBuilderGen<JoinRequestEmailEntity, JoinRequestEmailMetaDataAttributes,JoinRequestEmailMetaDataRelations>


export class JoinRequestEmailMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'JoinRequestEmail';
    // Attributes
    JoinRequestEmailId = new DwItemAttribute(this.i, 'JoinRequestEmailId');
    JoinRequestId = new DwItemAttribute(this.i, 'JoinRequestId');
    EmailAddress = new DwItemAttribute(this.i, 'EmailAddress');
    Accepted = new DwItemAttribute(this.i, 'Accepted');
    UserId = new DwItemAttribute(this.i, 'UserId');
    AcceptedDateTime = new DwItemAttribute(this.i, 'AcceptedDateTime');
    LastResendRequestDateTime = new DwItemAttribute(this.i, 'LastResendRequestDateTime');
}

class JoinRequestEmailMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Join Request */
            JoinRequest = new DwItemRelationship('JoinRequest|JoinRequestEmail|JoinRequestId', 'JoinRequest', 'JoinRequest');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|JoinRequestEmail|UserId', 'DwUser', 'DwUser');
    };
export class JoinRequestStatusMetaData implements DwItem2<JoinRequestStatusEntity, JoinRequestStatusMetaDataAttributes,JoinRequestStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=203;
        this.ItemDetail.itemName='JoinRequestStatus';
        this.ItemDetail.keyName='JoinRequestStatusId';
    }

    CreateEntity(): JoinRequestStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): JoinRequestStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<JoinRequestStatusEntity, JoinRequestStatusMetaDataAttributes,JoinRequestStatusMetaDataRelations>(query, this);
    }
     Attributes = new JoinRequestStatusMetaDataAttributes();
     Relations = new JoinRequestStatusMetaDataRelations();

}


export type JoinRequestStatusQueryBuilder = DwQueryBuilderGen<JoinRequestStatusEntity, JoinRequestStatusMetaDataAttributes,JoinRequestStatusMetaDataRelations>


export class JoinRequestStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'JoinRequestStatus';
    // Attributes
    JoinRequestStatusId = new DwItemAttribute(this.i, 'JoinRequestStatusId');
    JoinRequestStatus = new DwItemAttribute(this.i, 'JoinRequestStatus');
}

class JoinRequestStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Join Request */
            JoinRequest = new DwItemRelationship('JoinRequestStatus|JoinRequest|JoinRequestStatusId', 'JoinRequest', 'JoinRequest');
    };
export class JoinRequestTypeMetaData implements DwItem2<JoinRequestTypeEntity, JoinRequestTypeMetaDataAttributes,JoinRequestTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=201;
        this.ItemDetail.itemName='JoinRequestType';
        this.ItemDetail.keyName='JoinRequestTypeId';
    }

    CreateEntity(): JoinRequestTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): JoinRequestTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<JoinRequestTypeEntity, JoinRequestTypeMetaDataAttributes,JoinRequestTypeMetaDataRelations>(query, this);
    }
     Attributes = new JoinRequestTypeMetaDataAttributes();
     Relations = new JoinRequestTypeMetaDataRelations();

}


export type JoinRequestTypeQueryBuilder = DwQueryBuilderGen<JoinRequestTypeEntity, JoinRequestTypeMetaDataAttributes,JoinRequestTypeMetaDataRelations>


export class JoinRequestTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'JoinRequestType';
    // Attributes
    JoinRequestTypeId = new DwItemAttribute(this.i, 'JoinRequestTypeId');
    JoinRequestType = new DwItemAttribute(this.i, 'JoinRequestType');
    UserTypeId = new DwItemAttribute(this.i, 'UserTypeId');
    IncludeCourses = new DwItemAttribute(this.i, 'IncludeCourses');
}

class JoinRequestTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: User Type */
            DwUserType = new DwItemRelationship('dw_UserType|JoinRequestType|UserTypeId', 'DwUserType', 'DwUserType');
            /** Relation: Join Request */
            JoinRequest = new DwItemRelationship('JoinRequestType|JoinRequest|JoinRequestTypeId', 'JoinRequest', 'JoinRequest');
            /** Relation: Join Request Type Role */
            JoinRequestTypeRole = new DwItemRelationship('JoinRequestType|JoinRequestTypeRole|JoinRequestTypeId', 'JoinRequestTypeRole', 'JoinRequestTypeRole');
    };
export class JoinRequestTypeRoleMetaData implements DwItem2<JoinRequestTypeRoleEntity, JoinRequestTypeRoleMetaDataAttributes,JoinRequestTypeRoleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=200;
        this.ItemDetail.itemName='JoinRequestTypeRole';
        this.ItemDetail.keyName='JoinRequestTypeRoleId';
    }

    CreateEntity(): JoinRequestTypeRoleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): JoinRequestTypeRoleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<JoinRequestTypeRoleEntity, JoinRequestTypeRoleMetaDataAttributes,JoinRequestTypeRoleMetaDataRelations>(query, this);
    }
     Attributes = new JoinRequestTypeRoleMetaDataAttributes();
     Relations = new JoinRequestTypeRoleMetaDataRelations();

}


export type JoinRequestTypeRoleQueryBuilder = DwQueryBuilderGen<JoinRequestTypeRoleEntity, JoinRequestTypeRoleMetaDataAttributes,JoinRequestTypeRoleMetaDataRelations>


export class JoinRequestTypeRoleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'JoinRequestTypeRole';
    // Attributes
    JoinRequestTypeRoleId = new DwItemAttribute(this.i, 'JoinRequestTypeRoleId');
    JoinRequestTypeId = new DwItemAttribute(this.i, 'JoinRequestTypeId');
    RoleId = new DwItemAttribute(this.i, 'RoleId');
}

class JoinRequestTypeRoleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Join Request Type */
            JoinRequestType = new DwItemRelationship('JoinRequestType|JoinRequestTypeRole|JoinRequestTypeId', 'JoinRequestType', 'JoinRequestType');
    };
export class LessonTypeMetaData implements DwItem2<LessonTypeEntity, LessonTypeMetaDataAttributes,LessonTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=154;
        this.ItemDetail.itemName='LessonType';
        this.ItemDetail.keyName='LessonTypeId';
    }

    CreateEntity(): LessonTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): LessonTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<LessonTypeEntity, LessonTypeMetaDataAttributes,LessonTypeMetaDataRelations>(query, this);
    }
     Attributes = new LessonTypeMetaDataAttributes();
     Relations = new LessonTypeMetaDataRelations();

}


export type LessonTypeQueryBuilder = DwQueryBuilderGen<LessonTypeEntity, LessonTypeMetaDataAttributes,LessonTypeMetaDataRelations>


export class LessonTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'LessonType';
    // Attributes
    LessonTypeId = new DwItemAttribute(this.i, 'LessonTypeId');
    LessonType = new DwItemAttribute(this.i, 'LessonType');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class LessonTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('LessonType|CourseLesson|LessonTypeId', 'CourseLesson', 'CourseLesson');
    };
export class DwMediaMetaData implements DwItem2<DwMediaEntity, DwMediaMetaDataAttributes,DwMediaMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=74;
        this.ItemDetail.itemName='dw_Media';
        this.ItemDetail.keyName='MediaId';
    }

    CreateEntity(): DwMediaEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwMediaQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwMediaEntity, DwMediaMetaDataAttributes,DwMediaMetaDataRelations>(query, this);
    }
     Attributes = new DwMediaMetaDataAttributes();
     Relations = new DwMediaMetaDataRelations();

}


export type DwMediaQueryBuilder = DwQueryBuilderGen<DwMediaEntity, DwMediaMetaDataAttributes,DwMediaMetaDataRelations>


export class DwMediaMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_Media';
    // Attributes
    ContainerName = new DwItemAttribute(this.i, 'ContainerName');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    IsDeleted = new DwItemAttribute(this.i, 'IsDeleted');
    IsSecure = new DwItemAttribute(this.i, 'IsSecure');
    MediaFolderId = new DwItemAttribute(this.i, 'MediaFolderId');
    MediaGroupId = new DwItemAttribute(this.i, 'MediaGroupId');
    MediaId = new DwItemAttribute(this.i, 'MediaId');
    MediaName = new DwItemAttribute(this.i, 'MediaName');
    MediaStorageTypeId = new DwItemAttribute(this.i, 'MediaStorageTypeId');
    MediaTypeId = new DwItemAttribute(this.i, 'MediaTypeId');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class DwMediaMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Media Group */
            DwMediaGroup = new DwItemRelationship('dw_MediaGroup|dw_Media|MediaGroupId', 'DwMediaGroup', 'DwMediaGroup');
            /** Relation: Assignment */
            Assignment = new DwItemRelationship('dw_Media|Assignment|MediaId', 'Assignment', 'Assignment');
            /** Relation: Course - Course Image - Many */
            CourseByCourseImageMediaId = new DwItemRelationship('dw_Media|Course|CourseImageMediaId', 'CourseImageCourse', 'CourseImageCourse');
            /** Relation: Course Endorsement - Endorsement P D F - Many */
            CourseEndorsementByEndorsementPDFMediaId = new DwItemRelationship('dw_Media|CourseEndorsement|EndorsementPDFMediaId', 'EndorsementPDFCourseEndorsement', 'EndorsementPDFCourseEndorsement');
            /** Relation: Course Endorsement Student - Endorsement P D F - Many */
            CourseEndorsementStudentByEndorsementPDFMediaId = new DwItemRelationship('dw_Media|CourseEndorsementStudent|EndorsementPDFMediaId', 'EndorsementPDFCourseEndorsementStudent', 'EndorsementPDFCourseEndorsementStudent');
            /** Relation: Module - Module Image - Many */
            CourseModuleByModuleImageMediaId = new DwItemRelationship('dw_Media|CourseModule|ModuleImageMediaId', 'ModuleImageCourseModule', 'ModuleImageCourseModule');
            /** Relation: Ro Media */
            DwRoMedia = new DwItemRelationship('roMedia|Media|MediaId', 'DwroMedia', 'DwroMedia');
            /** Relation: Theme - Alternate Logo - Many */
            DwThemeByAlternateLogoMediaId = new DwItemRelationship('dw_Media|dw_Theme|AlternateLogoMediaId', 'AlternateLogoDwTheme', 'AlternateLogoDwTheme');
            /** Relation: Theme - Logo - Many */
            DwThemeByLogoMediaId = new DwItemRelationship('dw_Media|dw_Theme|LogoMediaId', 'LogoDwTheme', 'LogoDwTheme');
            /** Relation: Theme - Logo Login - Many */
            DwThemeByLogoLoginMediaId = new DwItemRelationship('dw_Media|dw_Theme|LogoLoginMediaId', 'LogoLoginDwTheme', 'LogoLoginDwTheme');
            /** Relation: User - User Picture - Many */
            DwUserByUserPictureMediaId = new DwItemRelationship('dw_Media|dw_User|UserPictureMediaId', 'UserPictureDwUser', 'UserPictureDwUser');
            /** Relation: User - User Background - Many */
            DwUserByUserBackgroundMediaId = new DwItemRelationship('dw_Media|dw_User|UserBackgroundMediaId', 'UserBackgroundDwUser', 'UserBackgroundDwUser');
    };
export class DwMediaGroupMetaData implements DwItem2<DwMediaGroupEntity, DwMediaGroupMetaDataAttributes,DwMediaGroupMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=58;
        this.ItemDetail.itemName='dw_MediaGroup';
        this.ItemDetail.keyName='MediaGroupId';
    }

    CreateEntity(): DwMediaGroupEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwMediaGroupQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwMediaGroupEntity, DwMediaGroupMetaDataAttributes,DwMediaGroupMetaDataRelations>(query, this);
    }
     Attributes = new DwMediaGroupMetaDataAttributes();
     Relations = new DwMediaGroupMetaDataRelations();

}


export type DwMediaGroupQueryBuilder = DwQueryBuilderGen<DwMediaGroupEntity, DwMediaGroupMetaDataAttributes,DwMediaGroupMetaDataRelations>


export class DwMediaGroupMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_MediaGroup';
    // Attributes
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    GroupName = new DwItemAttribute(this.i, 'GroupName');
    IsGlobalGroup = new DwItemAttribute(this.i, 'IsGlobalGroup');
    IsSecure = new DwItemAttribute(this.i, 'IsSecure');
    MediaGroupId = new DwItemAttribute(this.i, 'MediaGroupId');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    PartyReferenceId = new DwItemAttribute(this.i, 'PartyReferenceId');
}

class DwMediaGroupMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Media */
            DwMedia = new DwItemRelationship('dw_MediaGroup|dw_Media|MediaGroupId', 'DwMedia', 'DwMedia');
            /** Relation: Ro Media */
            DwRoMedia = new DwItemRelationship('MediaGroup_roMedia', 'DwroMedia', 'DwroMedia');
    };
export class PackageMetaData implements DwItem2<PackageEntity, PackageMetaDataAttributes,PackageMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=183;
        this.ItemDetail.itemName='Package';
        this.ItemDetail.keyName='PackageId';
    }

    CreateEntity(): PackageEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PackageQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PackageEntity, PackageMetaDataAttributes,PackageMetaDataRelations>(query, this);
    }
     Attributes = new PackageMetaDataAttributes();
     Relations = new PackageMetaDataRelations();

}


export type PackageQueryBuilder = DwQueryBuilderGen<PackageEntity, PackageMetaDataAttributes,PackageMetaDataRelations>


export class PackageMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Package';
    // Attributes
    PackageId = new DwItemAttribute(this.i, 'PackageId');
    PackageName = new DwItemAttribute(this.i, 'PackageName');
    PackageDescription = new DwItemAttribute(this.i, 'PackageDescription');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class PackageMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School Package */
            FlightSchoolPackage = new DwItemRelationship('Package|FlightSchoolPackage|PackageId', 'FlightSchoolPackage', 'FlightSchoolPackage');
            /** Relation: Join Request - Course - Many */
            JoinRequestByCoursePackageId = new DwItemRelationship('Package|JoinRequest|CoursePackageId', 'CourseJoinRequest', 'CourseJoinRequest');
            /** Relation: Package Course */
            PackageCourse = new DwItemRelationship('Package|PackageCourse|PackageId', 'PackageCourse', 'PackageCourse');
    };
export class PackageCourseMetaData implements DwItem2<PackageCourseEntity, PackageCourseMetaDataAttributes,PackageCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=182;
        this.ItemDetail.itemName='PackageCourse';
        this.ItemDetail.keyName='PackageCourseId';
    }

    CreateEntity(): PackageCourseEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PackageCourseQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PackageCourseEntity, PackageCourseMetaDataAttributes,PackageCourseMetaDataRelations>(query, this);
    }
     Attributes = new PackageCourseMetaDataAttributes();
     Relations = new PackageCourseMetaDataRelations();

}


export type PackageCourseQueryBuilder = DwQueryBuilderGen<PackageCourseEntity, PackageCourseMetaDataAttributes,PackageCourseMetaDataRelations>


export class PackageCourseMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PackageCourse';
    // Attributes
    PackageCourseId = new DwItemAttribute(this.i, 'PackageCourseId');
    PackageId = new DwItemAttribute(this.i, 'PackageId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class PackageCourseMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|PackageCourse|CourseId', 'Course', 'Course');
            /** Relation: Package */
            Package = new DwItemRelationship('Package|PackageCourse|PackageId', 'Package', 'Package');
    };
export class PaymentMethodMetaData implements DwItem2<PaymentMethodEntity, PaymentMethodMetaDataAttributes,PaymentMethodMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=252;
        this.ItemDetail.itemName='PaymentMethod';
        this.ItemDetail.keyName='PaymentMethodId';
    }

    CreateEntity(): PaymentMethodEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentMethodQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentMethodEntity, PaymentMethodMetaDataAttributes,PaymentMethodMetaDataRelations>(query, this);
    }
     Attributes = new PaymentMethodMetaDataAttributes();
     Relations = new PaymentMethodMetaDataRelations();

}


export type PaymentMethodQueryBuilder = DwQueryBuilderGen<PaymentMethodEntity, PaymentMethodMetaDataAttributes,PaymentMethodMetaDataRelations>


export class PaymentMethodMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentMethod';
    // Attributes
    PaymentMethodId = new DwItemAttribute(this.i, 'PaymentMethodId');
    ProcessorConfigId = new DwItemAttribute(this.i, 'ProcessorConfigId');
    PaymentId = new DwItemAttribute(this.i, 'PaymentId');
    Last4 = new DwItemAttribute(this.i, 'Last4');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    IsDefault = new DwItemAttribute(this.i, 'IsDefault');
}

class PaymentMethodMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Processor Config */
            ProcessorConfig = new DwItemRelationship('ProcessorConfig|PaymentMethod|ProcessorConfigId', 'ProcessorConfig', 'ProcessorConfig');
    };
export class ProcessorConfigMetaData implements DwItem2<ProcessorConfigEntity, ProcessorConfigMetaDataAttributes,ProcessorConfigMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=250;
        this.ItemDetail.itemName='ProcessorConfig';
        this.ItemDetail.keyName='ProcessorConfigId';
    }

    CreateEntity(): ProcessorConfigEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProcessorConfigQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProcessorConfigEntity, ProcessorConfigMetaDataAttributes,ProcessorConfigMetaDataRelations>(query, this);
    }
     Attributes = new ProcessorConfigMetaDataAttributes();
     Relations = new ProcessorConfigMetaDataRelations();

}


export type ProcessorConfigQueryBuilder = DwQueryBuilderGen<ProcessorConfigEntity, ProcessorConfigMetaDataAttributes,ProcessorConfigMetaDataRelations>


export class ProcessorConfigMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProcessorConfig';
    // Attributes
    ProcessorConfigId = new DwItemAttribute(this.i, 'ProcessorConfigId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    CustomerId = new DwItemAttribute(this.i, 'CustomerId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    BillingName = new DwItemAttribute(this.i, 'BillingName');
    BillingEmail = new DwItemAttribute(this.i, 'BillingEmail');
}

class ProcessorConfigMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|ProcessorConfig|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Payment Method */
            PaymentMethod = new DwItemRelationship('ProcessorConfig|PaymentMethod|ProcessorConfigId', 'PaymentMethod', 'PaymentMethod');
    };
export class ProgramTypeMetaData implements DwItem2<ProgramTypeEntity, ProgramTypeMetaDataAttributes,ProgramTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=147;
        this.ItemDetail.itemName='ProgramType';
        this.ItemDetail.keyName='ProgramTypeId';
    }

    CreateEntity(): ProgramTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramTypeEntity, ProgramTypeMetaDataAttributes,ProgramTypeMetaDataRelations>(query, this);
    }
     Attributes = new ProgramTypeMetaDataAttributes();
     Relations = new ProgramTypeMetaDataRelations();

}


export type ProgramTypeQueryBuilder = DwQueryBuilderGen<ProgramTypeEntity, ProgramTypeMetaDataAttributes,ProgramTypeMetaDataRelations>


export class ProgramTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramType';
    // Attributes
    ProgramTypeId = new DwItemAttribute(this.i, 'ProgramTypeId');
    ProgramType = new DwItemAttribute(this.i, 'ProgramType');
    ProgramTypeDescription = new DwItemAttribute(this.i, 'ProgramTypeDescription');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class ProgramTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('ProgramType|Course|ProgramTypeId', 'Course', 'Course');
    };
export class QuestionMetaData implements DwItem2<QuestionEntity, QuestionMetaDataAttributes,QuestionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=138;
        this.ItemDetail.itemName='Question';
        this.ItemDetail.keyName='QuestionId';
    }

    CreateEntity(): QuestionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionEntity, QuestionMetaDataAttributes,QuestionMetaDataRelations>(query, this);
    }
     Attributes = new QuestionMetaDataAttributes();
     Relations = new QuestionMetaDataRelations();

}


export type QuestionQueryBuilder = DwQueryBuilderGen<QuestionEntity, QuestionMetaDataAttributes,QuestionMetaDataRelations>


export class QuestionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Question';
    // Attributes
    QuestionName = new DwItemAttribute(this.i, 'QuestionName');
    Question = new DwItemAttribute(this.i, 'Question');
    QuestionImageMediaId = new DwItemAttribute(this.i, 'QuestionImageMediaId');
    CorrectAnswer = new DwItemAttribute(this.i, 'CorrectAnswer');
    CorrectAnswerNotes = new DwItemAttribute(this.i, 'CorrectAnswerNotes');
    CorrectAnswerImageMediaId = new DwItemAttribute(this.i, 'CorrectAnswerImageMediaId');
    QuestionTypeId = new DwItemAttribute(this.i, 'QuestionTypeId');
    QuestionId = new DwItemAttribute(this.i, 'QuestionId');
    UseRandomOrder = new DwItemAttribute(this.i, 'UseRandomOrder');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    QuestionStatusId = new DwItemAttribute(this.i, 'QuestionStatusId');
}

class QuestionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|Question|CourseId', 'Course', 'Course');
            /** Relation: Module */
            CourseModule = new DwItemRelationship('CourseModule|Question|CourseModuleId', 'CourseModule', 'CourseModule');
            /** Relation: Ro Media - Question Image Media */
            DwRoMediaByQuestionImageMediaId = new DwItemRelationship('Question|RoMedia|QuestionImageMediaId', 'QuestionImageDwroMedia', 'QuestionImageDwroMedia');
            /** Relation: Ro Media - Correct Answer Image Media */
            DwRoMediaByCorrectAnswerImageMediaId = new DwItemRelationship('Question|RoMedia|CorrectAnswerImageMediaId', 'CorrectAnswerImageDwroMedia', 'CorrectAnswerImageDwroMedia');
            /** Relation: Question Status */
            QuestionStatus = new DwItemRelationship('QuestionStatus|Question|QuestionStatusId', 'QuestionStatus', 'QuestionStatus');
            /** Relation: Question Type */
            QuestionType = new DwItemRelationship('QuestionType|QuizQuestion|QuestionTypeId', 'QuestionType', 'QuestionType');
            /** Relation: Course Lesson Flag */
            CourseLessonFlag = new DwItemRelationship('Question|CourseLessonFlag|QuestionId', 'CourseLessonFlag', 'CourseLessonFlag');
            /** Relation: Question Answer */
            QuestionAnswer = new DwItemRelationship('Question|QuestionAnswer|QuestionId', 'QuestionAnswer', 'QuestionAnswer');
            /** Relation: Question Tag Link */
            QuestionTagLink = new DwItemRelationship('Question|QuestionTagLink|QuestionId', 'QuestionTagLink', 'QuestionTagLink');
            /** Relation: Quiz Question Link */
            QuizQuestionLink = new DwItemRelationship('Question|QuizQuestionLink|QuestionId', 'QuizQuestionLink', 'QuizQuestionLink');
            /** Relation: Quiz Result Question */
            QuizResultQuestion = new DwItemRelationship('Question|QuizResultQuestion|QuestionId', 'QuizResultQuestion', 'QuizResultQuestion');
    };
export class QuestionNotUsedMetaData implements DwItem2<QuestionNotUsedEntity, QuestionNotUsedMetaDataAttributes,QuestionNotUsedMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=168;
        this.ItemDetail.itemName='Question_NotUsed';
        this.ItemDetail.keyName='QuizQuestionId';
    }

    CreateEntity(): QuestionNotUsedEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionNotUsedQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionNotUsedEntity, QuestionNotUsedMetaDataAttributes,QuestionNotUsedMetaDataRelations>(query, this);
    }
     Attributes = new QuestionNotUsedMetaDataAttributes();
     Relations = new QuestionNotUsedMetaDataRelations();

}


export type QuestionNotUsedQueryBuilder = DwQueryBuilderGen<QuestionNotUsedEntity, QuestionNotUsedMetaDataAttributes,QuestionNotUsedMetaDataRelations>


export class QuestionNotUsedMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Question_NotUsed';
    // Attributes
    QuizQuestionId = new DwItemAttribute(this.i, 'QuizQuestionId');
    QuestionName = new DwItemAttribute(this.i, 'QuestionName');
    Question = new DwItemAttribute(this.i, 'Question');
    QuestionImageMediaId = new DwItemAttribute(this.i, 'QuestionImageMediaId');
    QuestionOrder = new DwItemAttribute(this.i, 'QuestionOrder');
    QuestionTypeId = new DwItemAttribute(this.i, 'QuestionTypeId');
    CorrectAnswer = new DwItemAttribute(this.i, 'CorrectAnswer');
    CorrectAnswerNotes = new DwItemAttribute(this.i, 'CorrectAnswerNotes');
    CorrectAnswerImageMediaId = new DwItemAttribute(this.i, 'CorrectAnswerImageMediaId');
    QuestionWeight = new DwItemAttribute(this.i, 'QuestionWeight');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuestionNotUsedMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Quiz Question Answer Not Used */
            QuizQuestionAnswerNotUsed = new DwItemRelationship('QuizQuestion|QuizQuestionAnswer|QuizQuestionId', 'QuizQuestionAnswerNotUsed', 'QuizQuestionAnswerNotUsed');
    };
export class QuestionAnswerMetaData implements DwItem2<QuestionAnswerEntity, QuestionAnswerMetaDataAttributes,QuestionAnswerMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=172;
        this.ItemDetail.itemName='QuestionAnswer';
        this.ItemDetail.keyName='QuestionAnswerId';
    }

    CreateEntity(): QuestionAnswerEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionAnswerQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionAnswerEntity, QuestionAnswerMetaDataAttributes,QuestionAnswerMetaDataRelations>(query, this);
    }
     Attributes = new QuestionAnswerMetaDataAttributes();
     Relations = new QuestionAnswerMetaDataRelations();

}


export type QuestionAnswerQueryBuilder = DwQueryBuilderGen<QuestionAnswerEntity, QuestionAnswerMetaDataAttributes,QuestionAnswerMetaDataRelations>


export class QuestionAnswerMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionAnswer';
    // Attributes
    QuestionAnswerId = new DwItemAttribute(this.i, 'QuestionAnswerId');
    QuestionId = new DwItemAttribute(this.i, 'QuestionId');
    AnswerText = new DwItemAttribute(this.i, 'AnswerText');
    AnswerImageMediaId = new DwItemAttribute(this.i, 'AnswerImageMediaId');
    IsCorrectAnswer = new DwItemAttribute(this.i, 'IsCorrectAnswer');
    ExplanationText = new DwItemAttribute(this.i, 'ExplanationText');
    AnswerOrder = new DwItemAttribute(this.i, 'AnswerOrder');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuestionAnswerMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question */
            Question = new DwItemRelationship('Question|QuestionAnswer|QuestionId', 'Question', 'Question');
            /** Relation: Quiz Result Question */
            QuizResultQuestion = new DwItemRelationship('QuestionAnswer|QuizResultQuestion|QuestionAnswerId', 'QuizResultQuestion', 'QuizResultQuestion');
    };
export class QuestionImportCharacterConversionMetaData implements DwItem2<QuestionImportCharacterConversionEntity, QuestionImportCharacterConversionMetaDataAttributes,QuestionImportCharacterConversionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=210;
        this.ItemDetail.itemName='QuestionImportCharacterConversion';
        this.ItemDetail.keyName='QuestionImportCharacterConversionId';
    }

    CreateEntity(): QuestionImportCharacterConversionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionImportCharacterConversionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionImportCharacterConversionEntity, QuestionImportCharacterConversionMetaDataAttributes,QuestionImportCharacterConversionMetaDataRelations>(query, this);
    }
     Attributes = new QuestionImportCharacterConversionMetaDataAttributes();
     Relations = new QuestionImportCharacterConversionMetaDataRelations();

}


export type QuestionImportCharacterConversionQueryBuilder = DwQueryBuilderGen<QuestionImportCharacterConversionEntity, QuestionImportCharacterConversionMetaDataAttributes,QuestionImportCharacterConversionMetaDataRelations>


export class QuestionImportCharacterConversionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionImportCharacterConversion';
    // Attributes
    QuestionImportCharacterConversionId = new DwItemAttribute(this.i, 'QuestionImportCharacterConversionId');
    AsciiValue = new DwItemAttribute(this.i, 'AsciiValue');
    RemoveValue = new DwItemAttribute(this.i, 'RemoveValue');
    ReplacementValue = new DwItemAttribute(this.i, 'ReplacementValue');
}

class QuestionImportCharacterConversionMetaDataRelations implements DwItemRelationshipCollection {
    };
export class QuestionStatusMetaData implements DwItem2<QuestionStatusEntity, QuestionStatusMetaDataAttributes,QuestionStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=184;
        this.ItemDetail.itemName='QuestionStatus';
        this.ItemDetail.keyName='QuestionStatusId';
    }

    CreateEntity(): QuestionStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionStatusEntity, QuestionStatusMetaDataAttributes,QuestionStatusMetaDataRelations>(query, this);
    }
     Attributes = new QuestionStatusMetaDataAttributes();
     Relations = new QuestionStatusMetaDataRelations();

}


export type QuestionStatusQueryBuilder = DwQueryBuilderGen<QuestionStatusEntity, QuestionStatusMetaDataAttributes,QuestionStatusMetaDataRelations>


export class QuestionStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionStatus';
    // Attributes
    QuestionStatusId = new DwItemAttribute(this.i, 'QuestionStatusId');
    QuestionStatus = new DwItemAttribute(this.i, 'QuestionStatus');
}

class QuestionStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question */
            Question = new DwItemRelationship('QuestionStatus|Question|QuestionStatusId', 'Question', 'Question');
    };
export class QuestionTagMetaData implements DwItem2<QuestionTagEntity, QuestionTagMetaDataAttributes,QuestionTagMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=169;
        this.ItemDetail.itemName='QuestionTag';
        this.ItemDetail.keyName='QuestionTagId';
    }

    CreateEntity(): QuestionTagEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionTagQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionTagEntity, QuestionTagMetaDataAttributes,QuestionTagMetaDataRelations>(query, this);
    }
     Attributes = new QuestionTagMetaDataAttributes();
     Relations = new QuestionTagMetaDataRelations();

}


export type QuestionTagQueryBuilder = DwQueryBuilderGen<QuestionTagEntity, QuestionTagMetaDataAttributes,QuestionTagMetaDataRelations>


export class QuestionTagMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionTag';
    // Attributes
    QuestionTagId = new DwItemAttribute(this.i, 'QuestionTagId');
    TagName = new DwItemAttribute(this.i, 'TagName');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuestionTagMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question Tag Link */
            QuestionTagLink = new DwItemRelationship('QuestionTag|QuestionTagLink|QuestionTagId', 'QuestionTagLink', 'QuestionTagLink');
            /** Relation: Video Tag Link - Tag - Many */
            VideoTagLinkByTagId = new DwItemRelationship('QuestionTag|VideoTagLink|TagId', 'TagIdVideoTagLink', 'TagIdVideoTagLink');
    };
export class QuestionTagLinkMetaData implements DwItem2<QuestionTagLinkEntity, QuestionTagLinkMetaDataAttributes,QuestionTagLinkMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=170;
        this.ItemDetail.itemName='QuestionTagLink';
        this.ItemDetail.keyName='QuestionTagLinkd';
    }

    CreateEntity(): QuestionTagLinkEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionTagLinkQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionTagLinkEntity, QuestionTagLinkMetaDataAttributes,QuestionTagLinkMetaDataRelations>(query, this);
    }
     Attributes = new QuestionTagLinkMetaDataAttributes();
     Relations = new QuestionTagLinkMetaDataRelations();

}


export type QuestionTagLinkQueryBuilder = DwQueryBuilderGen<QuestionTagLinkEntity, QuestionTagLinkMetaDataAttributes,QuestionTagLinkMetaDataRelations>


export class QuestionTagLinkMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionTagLink';
    // Attributes
    QuestionTagLinkd = new DwItemAttribute(this.i, 'QuestionTagLinkd');
    QuestionId = new DwItemAttribute(this.i, 'QuestionId');
    QuestionTagId = new DwItemAttribute(this.i, 'QuestionTagId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuestionTagLinkMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question */
            Question = new DwItemRelationship('Question|QuestionTagLink|QuestionId', 'Question', 'Question');
            /** Relation: Tag */
            QuestionTag = new DwItemRelationship('QuestionTag|QuestionTagLink|QuestionTagId', 'QuestionTag', 'QuestionTag');
    };
export class QuestionTypeMetaData implements DwItem2<QuestionTypeEntity, QuestionTypeMetaDataAttributes,QuestionTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=163;
        this.ItemDetail.itemName='QuestionType';
        this.ItemDetail.keyName='QuestionTypeId';
    }

    CreateEntity(): QuestionTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionTypeEntity, QuestionTypeMetaDataAttributes,QuestionTypeMetaDataRelations>(query, this);
    }
     Attributes = new QuestionTypeMetaDataAttributes();
     Relations = new QuestionTypeMetaDataRelations();

}


export type QuestionTypeQueryBuilder = DwQueryBuilderGen<QuestionTypeEntity, QuestionTypeMetaDataAttributes,QuestionTypeMetaDataRelations>


export class QuestionTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionType';
    // Attributes
    QuestionTypeId = new DwItemAttribute(this.i, 'QuestionTypeId');
    QuestionType = new DwItemAttribute(this.i, 'QuestionType');
    ConfigureAnswers = new DwItemAttribute(this.i, 'ConfigureAnswers');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuestionTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question */
            Question = new DwItemRelationship('QuestionType|QuizQuestion|QuestionTypeId', 'Question', 'Question');
    };
export class QuizMetaData implements DwItem2<QuizEntity, QuizMetaDataAttributes,QuizMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=137;
        this.ItemDetail.itemName='Quiz';
        this.ItemDetail.keyName='QuizId';
    }

    CreateEntity(): QuizEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizEntity, QuizMetaDataAttributes,QuizMetaDataRelations>(query, this);
    }
     Attributes = new QuizMetaDataAttributes();
     Relations = new QuizMetaDataRelations();

}


export type QuizQueryBuilder = DwQueryBuilderGen<QuizEntity, QuizMetaDataAttributes,QuizMetaDataRelations>


export class QuizMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Quiz';
    // Attributes
    QuizId = new DwItemAttribute(this.i, 'QuizId');
    QuizName = new DwItemAttribute(this.i, 'QuizName');
    QuizTypeId = new DwItemAttribute(this.i, 'QuizTypeId');
    UseRandomOrder = new DwItemAttribute(this.i, 'UseRandomOrder');
    MaxQuestionsToShow = new DwItemAttribute(this.i, 'MaxQuestionsToShow');
    TimeLimitMinutes = new DwItemAttribute(this.i, 'TimeLimitMinutes');
    MaxAttempts = new DwItemAttribute(this.i, 'MaxAttempts');
    PassingPercent = new DwItemAttribute(this.i, 'PassingPercent');
    ShowAnswersOnFail = new DwItemAttribute(this.i, 'ShowAnswersOnFail');
    ShowAnswersOnPass = new DwItemAttribute(this.i, 'ShowAnswersOnPass');
    UseWeightedAnswers = new DwItemAttribute(this.i, 'UseWeightedAnswers');
    QuizDescription = new DwItemAttribute(this.i, 'QuizDescription');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    QuizStatusId = new DwItemAttribute(this.i, 'QuizStatusId');
}

class QuizMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Quiz Type */
            QuizType = new DwItemRelationship('QuizType|Quiz|QuizTypeId', 'QuizType', 'QuizType');
            /** Relation: Reference Status - Quiz Status */
            ReferenceStatusByQuizStatusId = new DwItemRelationship('ReferenceStatus|Quiz|QuizStatusId', 'QuizStatusIdReferenceStatus', 'QuizStatusIdReferenceStatus');
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('Quiz|CourseLesson|QuizId', 'CourseLesson', 'CourseLesson');
            /** Relation: Course Lesson Flag */
            CourseLessonFlag = new DwItemRelationship('Quiz|CourseLessonFlag|QuizId', 'CourseLessonFlag', 'CourseLessonFlag');
            /** Relation: Quiz Question Link */
            QuizQuestionLink = new DwItemRelationship('Quiz|QuizQuestionLink|QuizId', 'QuizQuestionLink', 'QuizQuestionLink');
            /** Relation: Quiz Reference Quiz Link */
            QuizReferenceQuizLink = new DwItemRelationship('Quiz|QuizReferenceQuizLink|QuizId', 'QuizReferenceQuizLink', 'QuizReferenceQuizLink');
            /** Relation: Quiz Reference Quiz Link - Reference - Many */
            QuizReferenceQuizLinkByReferenceQuizId = new DwItemRelationship('Quiz|QuizReferenceQuizLink|ReferenceQuizId', 'ReferenceQuizReferenceQuizLink', 'ReferenceQuizReferenceQuizLink');
            /** Relation: Quiz Result */
            QuizResult = new DwItemRelationship('Quiz|QuizResult|QuizId', 'QuizResult', 'QuizResult');
    };
export class QuizOutcomeMetaData implements DwItem2<QuizOutcomeEntity, QuizOutcomeMetaDataAttributes,QuizOutcomeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=162;
        this.ItemDetail.itemName='QuizOutcome';
        this.ItemDetail.keyName='QuizOutcomeId';
    }

    CreateEntity(): QuizOutcomeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizOutcomeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizOutcomeEntity, QuizOutcomeMetaDataAttributes,QuizOutcomeMetaDataRelations>(query, this);
    }
     Attributes = new QuizOutcomeMetaDataAttributes();
     Relations = new QuizOutcomeMetaDataRelations();

}


export type QuizOutcomeQueryBuilder = DwQueryBuilderGen<QuizOutcomeEntity, QuizOutcomeMetaDataAttributes,QuizOutcomeMetaDataRelations>


export class QuizOutcomeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizOutcome';
    // Attributes
    QuizOutcomeId = new DwItemAttribute(this.i, 'QuizOutcomeId');
    QuizOutcome = new DwItemAttribute(this.i, 'QuizOutcome');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuizOutcomeMetaDataRelations implements DwItemRelationshipCollection {
    };
export class QuizQuestionAnswerNotUsedMetaData implements DwItem2<QuizQuestionAnswerNotUsedEntity, QuizQuestionAnswerNotUsedMetaDataAttributes,QuizQuestionAnswerNotUsedMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=171;
        this.ItemDetail.itemName='QuizQuestionAnswer_NotUsed';
        this.ItemDetail.keyName='QuizQuestionAnswerId';
    }

    CreateEntity(): QuizQuestionAnswerNotUsedEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizQuestionAnswerNotUsedQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizQuestionAnswerNotUsedEntity, QuizQuestionAnswerNotUsedMetaDataAttributes,QuizQuestionAnswerNotUsedMetaDataRelations>(query, this);
    }
     Attributes = new QuizQuestionAnswerNotUsedMetaDataAttributes();
     Relations = new QuizQuestionAnswerNotUsedMetaDataRelations();

}


export type QuizQuestionAnswerNotUsedQueryBuilder = DwQueryBuilderGen<QuizQuestionAnswerNotUsedEntity, QuizQuestionAnswerNotUsedMetaDataAttributes,QuizQuestionAnswerNotUsedMetaDataRelations>


export class QuizQuestionAnswerNotUsedMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizQuestionAnswer_NotUsed';
    // Attributes
    QuizQuestionAnswerId = new DwItemAttribute(this.i, 'QuizQuestionAnswerId');
    QuizQuestionId = new DwItemAttribute(this.i, 'QuizQuestionId');
    AnswerText = new DwItemAttribute(this.i, 'AnswerText');
    AnswerImageMediaId = new DwItemAttribute(this.i, 'AnswerImageMediaId');
    IsCorrectAnswer = new DwItemAttribute(this.i, 'IsCorrectAnswer');
    ExplanationText = new DwItemAttribute(this.i, 'ExplanationText');
    AnswerOrder = new DwItemAttribute(this.i, 'AnswerOrder');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuizQuestionAnswerNotUsedMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question Not Used */
            QuestionNotUsed = new DwItemRelationship('QuizQuestion|QuizQuestionAnswer|QuizQuestionId', 'QuestionNotUsed', 'QuestionNotUsed');
    };
export class QuizQuestionLinkMetaData implements DwItem2<QuizQuestionLinkEntity, QuizQuestionLinkMetaDataAttributes,QuizQuestionLinkMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=167;
        this.ItemDetail.itemName='QuizQuestionLink';
        this.ItemDetail.keyName='QuizQuestionLinkId';
    }

    CreateEntity(): QuizQuestionLinkEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizQuestionLinkQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizQuestionLinkEntity, QuizQuestionLinkMetaDataAttributes,QuizQuestionLinkMetaDataRelations>(query, this);
    }
     Attributes = new QuizQuestionLinkMetaDataAttributes();
     Relations = new QuizQuestionLinkMetaDataRelations();

}


export type QuizQuestionLinkQueryBuilder = DwQueryBuilderGen<QuizQuestionLinkEntity, QuizQuestionLinkMetaDataAttributes,QuizQuestionLinkMetaDataRelations>


export class QuizQuestionLinkMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizQuestionLink';
    // Attributes
    QuizId = new DwItemAttribute(this.i, 'QuizId');
    QuestionId = new DwItemAttribute(this.i, 'QuestionId');
    QuizQuestionLinkId = new DwItemAttribute(this.i, 'QuizQuestionLinkId');
    QuestionOrder = new DwItemAttribute(this.i, 'QuestionOrder');
    QuestionWeight = new DwItemAttribute(this.i, 'QuestionWeight');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuizQuestionLinkMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question */
            Question = new DwItemRelationship('Question|QuizQuestionLink|QuestionId', 'Question', 'Question');
            /** Relation: Quiz */
            Quiz = new DwItemRelationship('Quiz|QuizQuestionLink|QuizId', 'Quiz', 'Quiz');
    };
export class QuizReferenceQuizLinkMetaData implements DwItem2<QuizReferenceQuizLinkEntity, QuizReferenceQuizLinkMetaDataAttributes,QuizReferenceQuizLinkMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=214;
        this.ItemDetail.itemName='QuizReferenceQuizLink';
        this.ItemDetail.keyName='QuizReferenceQuizLinkId';
    }

    CreateEntity(): QuizReferenceQuizLinkEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizReferenceQuizLinkQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizReferenceQuizLinkEntity, QuizReferenceQuizLinkMetaDataAttributes,QuizReferenceQuizLinkMetaDataRelations>(query, this);
    }
     Attributes = new QuizReferenceQuizLinkMetaDataAttributes();
     Relations = new QuizReferenceQuizLinkMetaDataRelations();

}


export type QuizReferenceQuizLinkQueryBuilder = DwQueryBuilderGen<QuizReferenceQuizLinkEntity, QuizReferenceQuizLinkMetaDataAttributes,QuizReferenceQuizLinkMetaDataRelations>


export class QuizReferenceQuizLinkMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizReferenceQuizLink';
    // Attributes
    QuizReferenceQuizLinkId = new DwItemAttribute(this.i, 'QuizReferenceQuizLinkId');
    QuizId = new DwItemAttribute(this.i, 'QuizId');
    ReferenceQuizId = new DwItemAttribute(this.i, 'ReferenceQuizId');
    QuizWeight = new DwItemAttribute(this.i, 'QuizWeight');
    RequireCompletion = new DwItemAttribute(this.i, 'RequireCompletion');
    PrioritizeMissedQuestions = new DwItemAttribute(this.i, 'PrioritizeMissedQuestions');
}

class QuizReferenceQuizLinkMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Quiz */
            Quiz = new DwItemRelationship('Quiz|QuizReferenceQuizLink|QuizId', 'Quiz', 'Quiz');
            /** Relation: Quiz - Reference Quiz */
            QuizByReferenceQuizId = new DwItemRelationship('Quiz|QuizReferenceQuizLink|ReferenceQuizId', 'ReferenceQuiz', 'ReferenceQuiz');
    };
export class QuizResultMetaData implements DwItem2<QuizResultEntity, QuizResultMetaDataAttributes,QuizResultMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=140;
        this.ItemDetail.itemName='QuizResult';
        this.ItemDetail.keyName='QuizResultId';
    }

    CreateEntity(): QuizResultEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizResultQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizResultEntity, QuizResultMetaDataAttributes,QuizResultMetaDataRelations>(query, this);
    }
     Attributes = new QuizResultMetaDataAttributes();
     Relations = new QuizResultMetaDataRelations();

}


export type QuizResultQueryBuilder = DwQueryBuilderGen<QuizResultEntity, QuizResultMetaDataAttributes,QuizResultMetaDataRelations>


export class QuizResultMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizResult';
    // Attributes
    QuizResultId = new DwItemAttribute(this.i, 'QuizResultId');
    QuizId = new DwItemAttribute(this.i, 'QuizId');
    IsComplete = new DwItemAttribute(this.i, 'IsComplete');
    CompletedQuestions = new DwItemAttribute(this.i, 'CompletedQuestions');
    TotalQuestions = new DwItemAttribute(this.i, 'TotalQuestions');
    QuizScore = new DwItemAttribute(this.i, 'QuizScore');
    PerfectScore = new DwItemAttribute(this.i, 'PerfectScore');
    QuizStartDateTime = new DwItemAttribute(this.i, 'QuizStartDateTime');
    QuizStopDateTime = new DwItemAttribute(this.i, 'QuizStopDateTime');
    PercentageScore = new DwItemAttribute(this.i, 'PercentageScore');
    QuizResultStatusId = new DwItemAttribute(this.i, 'QuizResultStatusId');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    CorrectQuestions = new DwItemAttribute(this.i, 'CorrectQuestions');
    StudentCourseId = new DwItemAttribute(this.i, 'StudentCourseId');
    CourseLessonProgressId = new DwItemAttribute(this.i, 'CourseLessonProgressId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuizResultMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Lesson Progress */
            CourseLessonProgress = new DwItemRelationship('CourseLessonProgress|QuizResult|CourseLessonProgressId', 'CourseLessonProgress', 'CourseLessonProgress');
            /** Relation: Quiz */
            Quiz = new DwItemRelationship('Quiz|QuizResult|QuizId', 'Quiz', 'Quiz');
            /** Relation: Quiz Result Status */
            QuizResultStatus = new DwItemRelationship('QuizResultStatus|QuizResult|QuizResultStatusId', 'QuizResultStatus', 'QuizResultStatus');
            /** Relation: Student Course */
            StudentCourse = new DwItemRelationship('StudentCourse|QuizResult|StudentCourseId', 'StudentCourse', 'StudentCourse');
            /** Relation: Course Lesson Progress - Best - Many */
            CourseLessonProgressByBestQuizResultId = new DwItemRelationship('QuizResult|CourseLessonProgress|BestQuizResultId', 'BestCourseLessonProgress', 'BestCourseLessonProgress');
            /** Relation: Course Lesson Session */
            CourseLessonSession = new DwItemRelationship('QuizResult|CourseLessonSession|QuizResultId', 'CourseLessonSession', 'CourseLessonSession');
            /** Relation: Quiz Result Question */
            QuizResultQuestion = new DwItemRelationship('QuizResult|QuizResultQuestion|QuizResultId', 'QuizResultQuestion', 'QuizResultQuestion');
    };
export class QuizResultQuestionMetaData implements DwItem2<QuizResultQuestionEntity, QuizResultQuestionMetaDataAttributes,QuizResultQuestionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=141;
        this.ItemDetail.itemName='QuizResultQuestion';
        this.ItemDetail.keyName='QuizResultQuestionId';
    }

    CreateEntity(): QuizResultQuestionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizResultQuestionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizResultQuestionEntity, QuizResultQuestionMetaDataAttributes,QuizResultQuestionMetaDataRelations>(query, this);
    }
     Attributes = new QuizResultQuestionMetaDataAttributes();
     Relations = new QuizResultQuestionMetaDataRelations();

}


export type QuizResultQuestionQueryBuilder = DwQueryBuilderGen<QuizResultQuestionEntity, QuizResultQuestionMetaDataAttributes,QuizResultQuestionMetaDataRelations>


export class QuizResultQuestionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizResultQuestion';
    // Attributes
    QuizResultQuestionId = new DwItemAttribute(this.i, 'QuizResultQuestionId');
    QuizResultId = new DwItemAttribute(this.i, 'QuizResultId');
    AnswerValue = new DwItemAttribute(this.i, 'AnswerValue');
    IsCorrect = new DwItemAttribute(this.i, 'IsCorrect');
    QuestionStartDateTime = new DwItemAttribute(this.i, 'QuestionStartDateTime');
    QuestionEndDateTime = new DwItemAttribute(this.i, 'QuestionEndDateTime');
    QuestionId = new DwItemAttribute(this.i, 'QuestionId');
    QuestionAnswerId = new DwItemAttribute(this.i, 'QuestionAnswerId');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    IsAnswered = new DwItemAttribute(this.i, 'IsAnswered');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuizResultQuestionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Question */
            Question = new DwItemRelationship('Question|QuizResultQuestion|QuestionId', 'Question', 'Question');
            /** Relation: Question Answer */
            QuestionAnswer = new DwItemRelationship('QuestionAnswer|QuizResultQuestion|QuestionAnswerId', 'QuestionAnswer', 'QuestionAnswer');
            /** Relation: Quiz Result */
            QuizResult = new DwItemRelationship('QuizResult|QuizResultQuestion|QuizResultId', 'QuizResult', 'QuizResult');
    };
export class QuizResultStatusMetaData implements DwItem2<QuizResultStatusEntity, QuizResultStatusMetaDataAttributes,QuizResultStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=173;
        this.ItemDetail.itemName='QuizResultStatus';
        this.ItemDetail.keyName='QuizResultStatusId';
    }

    CreateEntity(): QuizResultStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizResultStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizResultStatusEntity, QuizResultStatusMetaDataAttributes,QuizResultStatusMetaDataRelations>(query, this);
    }
     Attributes = new QuizResultStatusMetaDataAttributes();
     Relations = new QuizResultStatusMetaDataRelations();

}


export type QuizResultStatusQueryBuilder = DwQueryBuilderGen<QuizResultStatusEntity, QuizResultStatusMetaDataAttributes,QuizResultStatusMetaDataRelations>


export class QuizResultStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizResultStatus';
    // Attributes
    QuizResultStatusId = new DwItemAttribute(this.i, 'QuizResultStatusId');
    QuizResultStatus = new DwItemAttribute(this.i, 'QuizResultStatus');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class QuizResultStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Quiz Result */
            QuizResult = new DwItemRelationship('QuizResultStatus|QuizResult|QuizResultStatusId', 'QuizResult', 'QuizResult');
    };
export class QuizTypeMetaData implements DwItem2<QuizTypeEntity, QuizTypeMetaDataAttributes,QuizTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=161;
        this.ItemDetail.itemName='QuizType';
        this.ItemDetail.keyName='QuizTypeId';
    }

    CreateEntity(): QuizTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuizTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuizTypeEntity, QuizTypeMetaDataAttributes,QuizTypeMetaDataRelations>(query, this);
    }
     Attributes = new QuizTypeMetaDataAttributes();
     Relations = new QuizTypeMetaDataRelations();

}


export type QuizTypeQueryBuilder = DwQueryBuilderGen<QuizTypeEntity, QuizTypeMetaDataAttributes,QuizTypeMetaDataRelations>


export class QuizTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuizType';
    // Attributes
    QuizTypeId = new DwItemAttribute(this.i, 'QuizTypeId');
    QuizType = new DwItemAttribute(this.i, 'QuizType');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ShowQuizReferences = new DwItemAttribute(this.i, 'ShowQuizReferences');
}

class QuizTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Quiz */
            Quiz = new DwItemRelationship('QuizType|Quiz|QuizTypeId', 'Quiz', 'Quiz');
    };
export class ReferenceImageMetaData implements DwItem2<ReferenceImageEntity, ReferenceImageMetaDataAttributes,ReferenceImageMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=228;
        this.ItemDetail.itemName='ReferenceImage';
        this.ItemDetail.keyName='ReferenceImageId';
    }

    CreateEntity(): ReferenceImageEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ReferenceImageQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ReferenceImageEntity, ReferenceImageMetaDataAttributes,ReferenceImageMetaDataRelations>(query, this);
    }
     Attributes = new ReferenceImageMetaDataAttributes();
     Relations = new ReferenceImageMetaDataRelations();

}


export type ReferenceImageQueryBuilder = DwQueryBuilderGen<ReferenceImageEntity, ReferenceImageMetaDataAttributes,ReferenceImageMetaDataRelations>


export class ReferenceImageMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ReferenceImage';
    // Attributes
    ReferenceImageId = new DwItemAttribute(this.i, 'ReferenceImageId');
    ReferenceImageName = new DwItemAttribute(this.i, 'ReferenceImageName');
    ImageMediaId = new DwItemAttribute(this.i, 'ImageMediaId');
}

class ReferenceImageMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement Template Field */
            CourseEndorsementTemplateField = new DwItemRelationship('ReferenceImage|CourseEndorsementTemplateField|ReferenceImageId', 'CourseEndorsementTemplateField', 'CourseEndorsementTemplateField');
    };
export class ReferenceStatusMetaData implements DwItem2<ReferenceStatusEntity, ReferenceStatusMetaDataAttributes,ReferenceStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=233;
        this.ItemDetail.itemName='ReferenceStatus';
        this.ItemDetail.keyName='ReferenceStatusId';
    }

    CreateEntity(): ReferenceStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ReferenceStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ReferenceStatusEntity, ReferenceStatusMetaDataAttributes,ReferenceStatusMetaDataRelations>(query, this);
    }
     Attributes = new ReferenceStatusMetaDataAttributes();
     Relations = new ReferenceStatusMetaDataRelations();

}


export type ReferenceStatusQueryBuilder = DwQueryBuilderGen<ReferenceStatusEntity, ReferenceStatusMetaDataAttributes,ReferenceStatusMetaDataRelations>


export class ReferenceStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ReferenceStatus';
    // Attributes
    ReferenceStatusId = new DwItemAttribute(this.i, 'ReferenceStatusId');
    ReferenceStatus = new DwItemAttribute(this.i, 'ReferenceStatus');
}

class ReferenceStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course - Course Status - Many */
            CourseByCourseStatusId = new DwItemRelationship('ReferenceStatus|Course|CourseStatusId', 'CourseStatusIdCourse', 'CourseStatusIdCourse');
            /** Relation: Lesson - Lesson Status - Many */
            CourseLessonByLessonStatusId = new DwItemRelationship('ReferenceStatus|CourseLesson|LessonStatusId', 'LessonStatusIdCourseLesson', 'LessonStatusIdCourseLesson');
            /** Relation: Course Lesson Progress - Lesson Progress Status - Many */
            CourseLessonProgressByLessonProgressStatusId = new DwItemRelationship('ReferenceStatus|CourseLessonProgress|LessonProgressStatusId', 'LessonProgressStatusIdCourseLessonProgress', 'LessonProgressStatusIdCourseLessonProgress');
            /** Relation: Course Lesson Session - Lesson Session Status - Many */
            CourseLessonSessionByLessonSessionStatusId = new DwItemRelationship('ReferenceStatus|CourseLessonSession|LessonSessionStatusId', 'LessonSessionStatusIdCourseLessonSession', 'LessonSessionStatusIdCourseLessonSession');
            /** Relation: Module - Module Status - Many */
            CourseModuleByModuleStatusId = new DwItemRelationship('ReferenceStatus|CourseModule|ModuleStatusId', 'ModuleStatusIdCourseModule', 'ModuleStatusIdCourseModule');
            /** Relation: Course Module Progress - Module Progress Status - Many */
            CourseModuleProgressByModuleProgressStatusId = new DwItemRelationship('ReferenceStatus|CourseModuleProgress|ModuleProgressStatusId', 'ModuleProgressStatusIdCourseModuleProgress', 'ModuleProgressStatusIdCourseModuleProgress');
            /** Relation: Quiz - Quiz Status - Many */
            QuizByQuizStatusId = new DwItemRelationship('ReferenceStatus|Quiz|QuizStatusId', 'QuizStatusIdQuiz', 'QuizStatusIdQuiz');
            /** Relation: Student Course - Student Course Status - Many */
            StudentCourseByStudentCourseStatusId = new DwItemRelationship('ReferenceStatus|StudentCourse|StudentCourseStatusId', 'StudentCourseStatusIdStudentCourse', 'StudentCourseStatusIdStudentCourse');
    };
export class RoAssignmentStudentCompletedDetailsMetaData implements DwItem2<RoAssignmentStudentCompletedDetailsEntity, RoAssignmentStudentCompletedDetailsMetaDataAttributes,RoAssignmentStudentCompletedDetailsMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=229;
        this.ItemDetail.itemName='roAssignmentStudentCompletedDetails';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): RoAssignmentStudentCompletedDetailsEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoAssignmentStudentCompletedDetailsQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoAssignmentStudentCompletedDetailsEntity, RoAssignmentStudentCompletedDetailsMetaDataAttributes,RoAssignmentStudentCompletedDetailsMetaDataRelations>(query, this);
    }
     Attributes = new RoAssignmentStudentCompletedDetailsMetaDataAttributes();
     Relations = new RoAssignmentStudentCompletedDetailsMetaDataRelations();

}


export type RoAssignmentStudentCompletedDetailsQueryBuilder = DwQueryBuilderGen<RoAssignmentStudentCompletedDetailsEntity, RoAssignmentStudentCompletedDetailsMetaDataAttributes,RoAssignmentStudentCompletedDetailsMetaDataRelations>


export class RoAssignmentStudentCompletedDetailsMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roAssignmentStudentCompletedDetails';
    // Attributes
    AssignmentStudentId = new DwItemAttribute(this.i, 'AssignmentStudentId');
    AssignmentId = new DwItemAttribute(this.i, 'AssignmentId');
    CompletedOnTime = new DwItemAttribute(this.i, 'CompletedOnTime');
}

class RoAssignmentStudentCompletedDetailsMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Assignment Student */
            AssignmentStudent = new DwItemRelationship('AssignmentStudent|RoAssignmentStudent|AssignmentStudentId', 'AssignmentStudent', 'AssignmentStudent');
    };
export class RoAssignmentStudentCourseLessonProgressMetaData implements DwItem2<RoAssignmentStudentCourseLessonProgressEntity, RoAssignmentStudentCourseLessonProgressMetaDataAttributes,RoAssignmentStudentCourseLessonProgressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=196;
        this.ItemDetail.itemName='roAssignmentStudentCourseLessonProgress';
        this.ItemDetail.keyName='CourseLessonProgressId';
    }

    CreateEntity(): RoAssignmentStudentCourseLessonProgressEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoAssignmentStudentCourseLessonProgressQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoAssignmentStudentCourseLessonProgressEntity, RoAssignmentStudentCourseLessonProgressMetaDataAttributes,RoAssignmentStudentCourseLessonProgressMetaDataRelations>(query, this);
    }
     Attributes = new RoAssignmentStudentCourseLessonProgressMetaDataAttributes();
     Relations = new RoAssignmentStudentCourseLessonProgressMetaDataRelations();

}


export type RoAssignmentStudentCourseLessonProgressQueryBuilder = DwQueryBuilderGen<RoAssignmentStudentCourseLessonProgressEntity, RoAssignmentStudentCourseLessonProgressMetaDataAttributes,RoAssignmentStudentCourseLessonProgressMetaDataRelations>


export class RoAssignmentStudentCourseLessonProgressMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roAssignmentStudentCourseLessonProgress';
    // Attributes
    AssignmentStudentId = new DwItemAttribute(this.i, 'AssignmentStudentId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    CourseLessonProgressId = new DwItemAttribute(this.i, 'CourseLessonProgressId');
}

class RoAssignmentStudentCourseLessonProgressMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Assignment Student */
            AssignmentStudent = new DwItemRelationship('AssignmentStudent|RoAssignmentStudentCourseLessonProgress', 'AssignmentStudent', 'AssignmentStudent');
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('Lesson|RoAssignmentStudentCourseLessonProgress', 'CourseLesson', 'CourseLesson');
            /** Relation: Course Lesson Progress */
            CourseLessonProgress = new DwItemRelationship('CourseLessonProgress|RoAssignmentStudentCourseLessonProgress', 'CourseLessonProgress', 'CourseLessonProgress');
    };
export class RoFlightSchoolCourseUniqueMetaData implements DwItem2<RoFlightSchoolCourseUniqueEntity, RoFlightSchoolCourseUniqueMetaDataAttributes,RoFlightSchoolCourseUniqueMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=194;
        this.ItemDetail.itemName='roFlightSchoolCourseUnique';
        this.ItemDetail.keyName='FlightSchoolId';
    }

    CreateEntity(): RoFlightSchoolCourseUniqueEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoFlightSchoolCourseUniqueQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoFlightSchoolCourseUniqueEntity, RoFlightSchoolCourseUniqueMetaDataAttributes,RoFlightSchoolCourseUniqueMetaDataRelations>(query, this);
    }
     Attributes = new RoFlightSchoolCourseUniqueMetaDataAttributes();
     Relations = new RoFlightSchoolCourseUniqueMetaDataRelations();

}


export type RoFlightSchoolCourseUniqueQueryBuilder = DwQueryBuilderGen<RoFlightSchoolCourseUniqueEntity, RoFlightSchoolCourseUniqueMetaDataAttributes,RoFlightSchoolCourseUniqueMetaDataRelations>


export class RoFlightSchoolCourseUniqueMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roFlightSchoolCourseUnique';
    // Attributes
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
}

class RoFlightSchoolCourseUniqueMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|RoFlightSchoolCourseUnique', 'Course', 'Course');
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|RoFlightSchoolCourseUnique', 'FlightSchool', 'FlightSchool');
    };
export class DwRoMediaMetaData implements DwItem2<DwRoMediaEntity, DwRoMediaMetaDataAttributes,DwRoMediaMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=71;
        this.ItemDetail.itemName='dw_roMedia';
        this.ItemDetail.keyName='MediaId';
    }

    CreateEntity(): DwRoMediaEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwRoMediaQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwRoMediaEntity, DwRoMediaMetaDataAttributes,DwRoMediaMetaDataRelations>(query, this);
    }
     Attributes = new DwRoMediaMetaDataAttributes();
     Relations = new DwRoMediaMetaDataRelations();

}


export type DwRoMediaQueryBuilder = DwQueryBuilderGen<DwRoMediaEntity, DwRoMediaMetaDataAttributes,DwRoMediaMetaDataRelations>


export class DwRoMediaMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_roMedia';
    // Attributes
    ContainerName = new DwItemAttribute(this.i, 'ContainerName');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    FileName = new DwItemAttribute(this.i, 'FileName');
    ImageHeight = new DwItemAttribute(this.i, 'ImageHeight');
    ImageWidth = new DwItemAttribute(this.i, 'ImageWidth');
    IsDeleted = new DwItemAttribute(this.i, 'IsDeleted');
    IsSecure = new DwItemAttribute(this.i, 'IsSecure');
    MediaDetailKey = new DwItemAttribute(this.i, 'MediaDetailKey');
    MediaFolderId = new DwItemAttribute(this.i, 'MediaFolderId');
    MediaGroupId = new DwItemAttribute(this.i, 'MediaGroupId');
    MediaId = new DwItemAttribute(this.i, 'MediaId');
    MediaName = new DwItemAttribute(this.i, 'MediaName');
    MediaPath = new DwItemAttribute(this.i, 'MediaPath');
    MediaSizeType = new DwItemAttribute(this.i, 'MediaSizeType');
    MediaSizeTypeId = new DwItemAttribute(this.i, 'MediaSizeTypeId');
    MediaTypeId = new DwItemAttribute(this.i, 'MediaTypeId');
    SizeInBytes = new DwItemAttribute(this.i, 'SizeInBytes');
    Version = new DwItemAttribute(this.i, 'Version');
}

class DwRoMediaMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Media */
            DwMedia = new DwItemRelationship('roMedia|Media|MediaId', 'DwMedia', 'DwMedia');
            /** Relation: Media Group */
            DwMediaGroup = new DwItemRelationship('MediaGroup_roMedia', 'DwMediaGroup', 'DwMediaGroup');
            /** Relation: Course - Media - Many */
            CourseByCourseImageMediaId = new DwItemRelationship('Course|roMedia', 'CourseImageCourse', 'CourseImageCourse');
            /** Relation: Lesson - Media - Many */
            CourseLessonByLessonImageMediaId = new DwItemRelationship('RoMedia|CourseLesson|LessonImageMediaId', 'LessonImageCourseLesson', 'LessonImageCourseLesson');
            /** Relation: Module - Media - Many */
            CourseModuleByModuleImageMediaId = new DwItemRelationship('CourseModule|roMedia', 'ModuleImageCourseModule', 'ModuleImageCourseModule');
            /** Relation: Question - Media - Many */
            QuestionByQuestionImageMediaId = new DwItemRelationship('Question|RoMedia|QuestionImageMediaId', 'QuestionImageQuestion', 'QuestionImageQuestion');
            /** Relation: Question - Media - Many */
            QuestionByCorrectAnswerImageMediaId = new DwItemRelationship('Question|RoMedia|CorrectAnswerImageMediaId', 'CorrectAnswerImageQuestion', 'CorrectAnswerImageQuestion');
    };
export class RoModuleLessonMaxOrderMetaData implements DwItem2<RoModuleLessonMaxOrderEntity, RoModuleLessonMaxOrderMetaDataAttributes,RoModuleLessonMaxOrderMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=164;
        this.ItemDetail.itemName='roModuleLessonMaxOrder';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): RoModuleLessonMaxOrderEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoModuleLessonMaxOrderQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoModuleLessonMaxOrderEntity, RoModuleLessonMaxOrderMetaDataAttributes,RoModuleLessonMaxOrderMetaDataRelations>(query, this);
    }
     Attributes = new RoModuleLessonMaxOrderMetaDataAttributes();
     Relations = new RoModuleLessonMaxOrderMetaDataRelations();

}


export type RoModuleLessonMaxOrderQueryBuilder = DwQueryBuilderGen<RoModuleLessonMaxOrderEntity, RoModuleLessonMaxOrderMetaDataAttributes,RoModuleLessonMaxOrderMetaDataRelations>


export class RoModuleLessonMaxOrderMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roModuleLessonMaxOrder';
    // Attributes
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CourseName = new DwItemAttribute(this.i, 'CourseName');
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    ModuleName = new DwItemAttribute(this.i, 'ModuleName');
    ModuleMaxOrder = new DwItemAttribute(this.i, 'ModuleMaxOrder');
    LessonMaxOrder = new DwItemAttribute(this.i, 'LessonMaxOrder');
}

class RoModuleLessonMaxOrderMetaDataRelations implements DwItemRelationshipCollection {
    };
export class RoUserRoleDetailMetaData implements DwItem2<RoUserRoleDetailEntity, RoUserRoleDetailMetaDataAttributes,RoUserRoleDetailMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=219;
        this.ItemDetail.itemName='roUserRoleDetail';
        this.ItemDetail.keyName='UserId';
    }

    CreateEntity(): RoUserRoleDetailEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoUserRoleDetailQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoUserRoleDetailEntity, RoUserRoleDetailMetaDataAttributes,RoUserRoleDetailMetaDataRelations>(query, this);
    }
     Attributes = new RoUserRoleDetailMetaDataAttributes();
     Relations = new RoUserRoleDetailMetaDataRelations();

}


export type RoUserRoleDetailQueryBuilder = DwQueryBuilderGen<RoUserRoleDetailEntity, RoUserRoleDetailMetaDataAttributes,RoUserRoleDetailMetaDataRelations>


export class RoUserRoleDetailMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roUserRoleDetail';
    // Attributes
    UserId = new DwItemAttribute(this.i, 'UserId');
    IsStudent = new DwItemAttribute(this.i, 'IsStudent');
    IsInstructor = new DwItemAttribute(this.i, 'IsInstructor');
    IsLeadInstructor = new DwItemAttribute(this.i, 'IsLeadInstructor');
    IsFlightSchoolAdmin = new DwItemAttribute(this.i, 'IsFlightSchoolAdmin');
    IsFlightSchool = new DwItemAttribute(this.i, 'IsFlightSchool');
}

class RoUserRoleDetailMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: User */
            DwUser = new DwItemRelationship('RoUserRoleDetail|User|UserId', 'DwUser', 'DwUser');
    };
export class StandardColorSetMetaData implements DwItem2<StandardColorSetEntity, StandardColorSetMetaDataAttributes,StandardColorSetMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=251;
        this.ItemDetail.itemName='StandardColorSet';
        this.ItemDetail.keyName='StandardColorSetId';
    }

    CreateEntity(): StandardColorSetEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): StandardColorSetQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<StandardColorSetEntity, StandardColorSetMetaDataAttributes,StandardColorSetMetaDataRelations>(query, this);
    }
     Attributes = new StandardColorSetMetaDataAttributes();
     Relations = new StandardColorSetMetaDataRelations();

}


export type StandardColorSetQueryBuilder = DwQueryBuilderGen<StandardColorSetEntity, StandardColorSetMetaDataAttributes,StandardColorSetMetaDataRelations>


export class StandardColorSetMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'StandardColorSet';
    // Attributes
    StandardColorSetId = new DwItemAttribute(this.i, 'StandardColorSetId');
    ForegroundColor = new DwItemAttribute(this.i, 'ForegroundColor');
    BackgroundColor = new DwItemAttribute(this.i, 'BackgroundColor');
    ColorSetName = new DwItemAttribute(this.i, 'ColorSetName');
}

class StandardColorSetMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School Branding - Navigation Color Set - Many */
            FlightSchoolBrandingByNavigationColorSetId = new DwItemRelationship('StandardColorSet|FlightSchoolBranding|NavigationColorSetId', 'NavigationColorSetIdFlightSchoolBranding', 'NavigationColorSetIdFlightSchoolBranding');
            /** Relation: Flight School Branding - Sidebar Color Set - Many */
            FlightSchoolBrandingBySidebarColorSetId = new DwItemRelationship('StandardColorSet|FlightSchoolBranding|SidebarColorSetId', 'SidebarColorSetIdFlightSchoolBranding', 'SidebarColorSetIdFlightSchoolBranding');
    };
export class StudentMetaData implements DwItem2<StudentEntity, StudentMetaDataAttributes,StudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=144;
        this.ItemDetail.itemName='Student';
        this.ItemDetail.keyName='StudentId';
    }

    CreateEntity(): StudentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): StudentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<StudentEntity, StudentMetaDataAttributes,StudentMetaDataRelations>(query, this);
    }
     Attributes = new StudentMetaDataAttributes();
     Relations = new StudentMetaDataRelations();

}


export type StudentQueryBuilder = DwQueryBuilderGen<StudentEntity, StudentMetaDataAttributes,StudentMetaDataRelations>


export class StudentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Student';
    // Attributes
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class StudentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|Student|UserId', 'DwUser', 'DwUser');
            /** Relation: Assignment Student */
            AssignmentStudent = new DwItemRelationship('Student|AssignmentStudent|StudentId', 'AssignmentStudent', 'AssignmentStudent');
            /** Relation: Flight School Group Student */
            FlightSchoolGroupStudent = new DwItemRelationship('Student|FlightSchoolGroupStudent|StudentId', 'FlightSchoolGroupStudent', 'FlightSchoolGroupStudent');
            /** Relation: Flight School Student Old */
            FlightSchoolStudentOld = new DwItemRelationship('Student|FlightSchoolStudent|StudentId', 'FlightSchoolStudentOld', 'FlightSchoolStudentOld');
            /** Relation: Instructor Student */
            InstructorStudent = new DwItemRelationship('Student|InstructorStudent|StudentId', 'InstructorStudent', 'InstructorStudent');
            /** Relation: Student Course */
            StudentCourse = new DwItemRelationship('Student|StudentCourse|StudentId', 'StudentCourse', 'StudentCourse');
    };
export class StudentCourseMetaData implements DwItem2<StudentCourseEntity, StudentCourseMetaDataAttributes,StudentCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=165;
        this.ItemDetail.itemName='StudentCourse';
        this.ItemDetail.keyName='StudentCourseId';
    }

    CreateEntity(): StudentCourseEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): StudentCourseQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<StudentCourseEntity, StudentCourseMetaDataAttributes,StudentCourseMetaDataRelations>(query, this);
    }
     Attributes = new StudentCourseMetaDataAttributes();
     Relations = new StudentCourseMetaDataRelations();

}


export type StudentCourseQueryBuilder = DwQueryBuilderGen<StudentCourseEntity, StudentCourseMetaDataAttributes,StudentCourseMetaDataRelations>


export class StudentCourseMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'StudentCourse';
    // Attributes
    StudentCourseId = new DwItemAttribute(this.i, 'StudentCourseId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    ModulesCompleted = new DwItemAttribute(this.i, 'ModulesCompleted');
    ModulesCount = new DwItemAttribute(this.i, 'ModulesCount');
    LastActivityDateTime = new DwItemAttribute(this.i, 'LastActivityDateTime');
    LessonsCompleted = new DwItemAttribute(this.i, 'LessonsCompleted');
    LessonCount = new DwItemAttribute(this.i, 'LessonCount');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    FirstActivityDateTime = new DwItemAttribute(this.i, 'FirstActivityDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    StudentCourseStatusId = new DwItemAttribute(this.i, 'StudentCourseStatusId');
    IsEndorsed = new DwItemAttribute(this.i, 'IsEndorsed');
}

class StudentCourseMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|StudentCourse|CourseId', 'Course', 'Course');
            /** Relation: Reference Status - Student Course Status */
            ReferenceStatusByStudentCourseStatusId = new DwItemRelationship('ReferenceStatus|StudentCourse|StudentCourseStatusId', 'StudentCourseStatusIdReferenceStatus', 'StudentCourseStatusIdReferenceStatus');
            /** Relation: Student */
            Student = new DwItemRelationship('Student|StudentCourse|StudentId', 'Student', 'Student');
            /** Relation: Course Endorsement Student */
            CourseEndorsementStudent = new DwItemRelationship('StudentCourse|CourseEndorsementStudent|StudentCourseId', 'CourseEndorsementStudent', 'CourseEndorsementStudent');
            /** Relation: Course Module Progress */
            CourseModuleProgress = new DwItemRelationship('StudentCourse|CourseModuleProgress|StudentCourseId', 'CourseModuleProgress', 'CourseModuleProgress');
            /** Relation: Quiz Result */
            QuizResult = new DwItemRelationship('StudentCourse|QuizResult|StudentCourseId', 'QuizResult', 'QuizResult');
    };
export class SubdomainSetupStatusMetaData implements DwItem2<SubdomainSetupStatusEntity, SubdomainSetupStatusMetaDataAttributes,SubdomainSetupStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=240;
        this.ItemDetail.itemName='SubdomainSetupStatus';
        this.ItemDetail.keyName='SubdomainSetupStatusId';
    }

    CreateEntity(): SubdomainSetupStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubdomainSetupStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubdomainSetupStatusEntity, SubdomainSetupStatusMetaDataAttributes,SubdomainSetupStatusMetaDataRelations>(query, this);
    }
     Attributes = new SubdomainSetupStatusMetaDataAttributes();
     Relations = new SubdomainSetupStatusMetaDataRelations();

}


export type SubdomainSetupStatusQueryBuilder = DwQueryBuilderGen<SubdomainSetupStatusEntity, SubdomainSetupStatusMetaDataAttributes,SubdomainSetupStatusMetaDataRelations>


export class SubdomainSetupStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubdomainSetupStatus';
    // Attributes
    SubdomainSetupStatusId = new DwItemAttribute(this.i, 'SubdomainSetupStatusId');
    SubdomainSetupStatus = new DwItemAttribute(this.i, 'SubdomainSetupStatus');
}

class SubdomainSetupStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School Branding */
            FlightSchoolBranding = new DwItemRelationship('SubdomainSetupStatus|FlightSchoolBranding|SubdomainSetupStatusId', 'FlightSchoolBranding', 'FlightSchoolBranding');
    };
export class SubdomainTypeMetaData implements DwItem2<SubdomainTypeEntity, SubdomainTypeMetaDataAttributes,SubdomainTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=239;
        this.ItemDetail.itemName='SubdomainType';
        this.ItemDetail.keyName='SubdomainTypeId';
    }

    CreateEntity(): SubdomainTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubdomainTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubdomainTypeEntity, SubdomainTypeMetaDataAttributes,SubdomainTypeMetaDataRelations>(query, this);
    }
     Attributes = new SubdomainTypeMetaDataAttributes();
     Relations = new SubdomainTypeMetaDataRelations();

}


export type SubdomainTypeQueryBuilder = DwQueryBuilderGen<SubdomainTypeEntity, SubdomainTypeMetaDataAttributes,SubdomainTypeMetaDataRelations>


export class SubdomainTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubdomainType';
    // Attributes
    SubdomainTypeId = new DwItemAttribute(this.i, 'SubdomainTypeId');
    SubdomainType = new DwItemAttribute(this.i, 'SubdomainType');
}

class SubdomainTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School Branding */
            FlightSchoolBranding = new DwItemRelationship('SubdomainType|FlightSchoolBranding|SubdomainTypeId', 'FlightSchoolBranding', 'FlightSchoolBranding');
    };
export class SubscriptionMetaData implements DwItem2<SubscriptionEntity, SubscriptionMetaDataAttributes,SubscriptionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=244;
        this.ItemDetail.itemName='Subscription';
        this.ItemDetail.keyName='SubscriptionId';
    }

    CreateEntity(): SubscriptionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubscriptionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubscriptionEntity, SubscriptionMetaDataAttributes,SubscriptionMetaDataRelations>(query, this);
    }
     Attributes = new SubscriptionMetaDataAttributes();
     Relations = new SubscriptionMetaDataRelations();

}


export type SubscriptionQueryBuilder = DwQueryBuilderGen<SubscriptionEntity, SubscriptionMetaDataAttributes,SubscriptionMetaDataRelations>


export class SubscriptionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Subscription';
    // Attributes
    SubscriptionId = new DwItemAttribute(this.i, 'SubscriptionId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    SubscriptionTierId = new DwItemAttribute(this.i, 'SubscriptionTierId');
    SubscriptionStatusId = new DwItemAttribute(this.i, 'SubscriptionStatusId');
    EffectiveStartDate = new DwItemAttribute(this.i, 'EffectiveStartDate');
    EffectiveEndDate = new DwItemAttribute(this.i, 'EffectiveEndDate');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class SubscriptionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|Subscription|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Subscription Status */
            SubscriptionStatus = new DwItemRelationship('SubscriptionStatus|Subscription|SubscriptionStatusId', 'SubscriptionStatus', 'SubscriptionStatus');
            /** Relation: Subscription Tier */
            SubscriptionTier = new DwItemRelationship('SubscriptionTier|Subscription|SubscriptionTierId', 'SubscriptionTier', 'SubscriptionTier');
            /** Relation: Subscription Role Tracking */
            SubscriptionRoleTracking = new DwItemRelationship('Subscription|SubscriptionRoleTracking|SubscriptionId', 'SubscriptionRoleTracking', 'SubscriptionRoleTracking');
    };
export class SubscriptionRoleMetaData implements DwItem2<SubscriptionRoleEntity, SubscriptionRoleMetaDataAttributes,SubscriptionRoleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=246;
        this.ItemDetail.itemName='SubscriptionRole';
        this.ItemDetail.keyName='SubscriptionRoleId';
    }

    CreateEntity(): SubscriptionRoleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubscriptionRoleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubscriptionRoleEntity, SubscriptionRoleMetaDataAttributes,SubscriptionRoleMetaDataRelations>(query, this);
    }
     Attributes = new SubscriptionRoleMetaDataAttributes();
     Relations = new SubscriptionRoleMetaDataRelations();

}


export type SubscriptionRoleQueryBuilder = DwQueryBuilderGen<SubscriptionRoleEntity, SubscriptionRoleMetaDataAttributes,SubscriptionRoleMetaDataRelations>


export class SubscriptionRoleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubscriptionRole';
    // Attributes
    SubscriptionRoleId = new DwItemAttribute(this.i, 'SubscriptionRoleId');
    SubscriptionRole = new DwItemAttribute(this.i, 'SubscriptionRole');
    DevwareRoleId = new DwItemAttribute(this.i, 'DevwareRoleId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ProductId = new DwItemAttribute(this.i, 'ProductId');
}

class SubscriptionRoleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Subscription Role Tracking */
            SubscriptionRoleTracking = new DwItemRelationship('SubscriptionRole|SubscriptionRoleTracking|SubscriptionRoleId', 'SubscriptionRoleTracking', 'SubscriptionRoleTracking');
            /** Relation: Subscription Tier Role */
            SubscriptionTierRole = new DwItemRelationship('SubscriptionRole|SubscriptionTierRole|SubscriptionRoleId', 'SubscriptionTierRole', 'SubscriptionTierRole');
            /** Relation: User Subscription Role */
            UserSubscriptionRole = new DwItemRelationship('SubscriptionRole|UserSubscriptionRole|SubscriptionRoleId', 'UserSubscriptionRole', 'UserSubscriptionRole');
    };
export class SubscriptionRoleTrackingMetaData implements DwItem2<SubscriptionRoleTrackingEntity, SubscriptionRoleTrackingMetaDataAttributes,SubscriptionRoleTrackingMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=249;
        this.ItemDetail.itemName='SubscriptionRoleTracking';
        this.ItemDetail.keyName='SubscriptionRoleTrackingId';
    }

    CreateEntity(): SubscriptionRoleTrackingEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubscriptionRoleTrackingQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubscriptionRoleTrackingEntity, SubscriptionRoleTrackingMetaDataAttributes,SubscriptionRoleTrackingMetaDataRelations>(query, this);
    }
     Attributes = new SubscriptionRoleTrackingMetaDataAttributes();
     Relations = new SubscriptionRoleTrackingMetaDataRelations();

}


export type SubscriptionRoleTrackingQueryBuilder = DwQueryBuilderGen<SubscriptionRoleTrackingEntity, SubscriptionRoleTrackingMetaDataAttributes,SubscriptionRoleTrackingMetaDataRelations>


export class SubscriptionRoleTrackingMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubscriptionRoleTracking';
    // Attributes
    SubscriptionRoleTrackingId = new DwItemAttribute(this.i, 'SubscriptionRoleTrackingId');
    SubscriptionId = new DwItemAttribute(this.i, 'SubscriptionId');
    SubscriptionRoleId = new DwItemAttribute(this.i, 'SubscriptionRoleId');
    StartDate = new DwItemAttribute(this.i, 'StartDate');
    EndDate = new DwItemAttribute(this.i, 'EndDate');
    MinCount = new DwItemAttribute(this.i, 'MinCount');
    MinCountDate = new DwItemAttribute(this.i, 'MinCountDate');
    MaxCount = new DwItemAttribute(this.i, 'MaxCount');
    MaxCountDate = new DwItemAttribute(this.i, 'MaxCountDate');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class SubscriptionRoleTrackingMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Subscription */
            Subscription = new DwItemRelationship('Subscription|SubscriptionRoleTracking|SubscriptionId', 'Subscription', 'Subscription');
            /** Relation: Subscription Role */
            SubscriptionRole = new DwItemRelationship('SubscriptionRole|SubscriptionRoleTracking|SubscriptionRoleId', 'SubscriptionRole', 'SubscriptionRole');
    };
export class SubscriptionStatusMetaData implements DwItem2<SubscriptionStatusEntity, SubscriptionStatusMetaDataAttributes,SubscriptionStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=245;
        this.ItemDetail.itemName='SubscriptionStatus';
        this.ItemDetail.keyName='SubscriptionStatusId';
    }

    CreateEntity(): SubscriptionStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubscriptionStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubscriptionStatusEntity, SubscriptionStatusMetaDataAttributes,SubscriptionStatusMetaDataRelations>(query, this);
    }
     Attributes = new SubscriptionStatusMetaDataAttributes();
     Relations = new SubscriptionStatusMetaDataRelations();

}


export type SubscriptionStatusQueryBuilder = DwQueryBuilderGen<SubscriptionStatusEntity, SubscriptionStatusMetaDataAttributes,SubscriptionStatusMetaDataRelations>


export class SubscriptionStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubscriptionStatus';
    // Attributes
    SubscriptionStatusId = new DwItemAttribute(this.i, 'SubscriptionStatusId');
    SubscriptionStatus = new DwItemAttribute(this.i, 'SubscriptionStatus');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class SubscriptionStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Subscription */
            Subscription = new DwItemRelationship('SubscriptionStatus|Subscription|SubscriptionStatusId', 'Subscription', 'Subscription');
    };
export class SubscriptionTierMetaData implements DwItem2<SubscriptionTierEntity, SubscriptionTierMetaDataAttributes,SubscriptionTierMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=242;
        this.ItemDetail.itemName='SubscriptionTier';
        this.ItemDetail.keyName='SubscriptionTierId';
    }

    CreateEntity(): SubscriptionTierEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubscriptionTierQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubscriptionTierEntity, SubscriptionTierMetaDataAttributes,SubscriptionTierMetaDataRelations>(query, this);
    }
     Attributes = new SubscriptionTierMetaDataAttributes();
     Relations = new SubscriptionTierMetaDataRelations();

}


export type SubscriptionTierQueryBuilder = DwQueryBuilderGen<SubscriptionTierEntity, SubscriptionTierMetaDataAttributes,SubscriptionTierMetaDataRelations>


export class SubscriptionTierMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubscriptionTier';
    // Attributes
    SubscriptionTierId = new DwItemAttribute(this.i, 'SubscriptionTierId');
    SubscriptionTierName = new DwItemAttribute(this.i, 'SubscriptionTierName');
    SubscriptionTierStatusId = new DwItemAttribute(this.i, 'SubscriptionTierStatusId');
    EffectiveStartDate = new DwItemAttribute(this.i, 'EffectiveStartDate');
    EffectiveEndDate = new DwItemAttribute(this.i, 'EffectiveEndDate');
    PlatformMonthlyCost = new DwItemAttribute(this.i, 'PlatformMonthlyCost');
    ProfitSharingPercentage = new DwItemAttribute(this.i, 'ProfitSharingPercentage');
    AllowProgressView = new DwItemAttribute(this.i, 'AllowProgressView');
    AllowReports = new DwItemAttribute(this.i, 'AllowReports');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ProductId = new DwItemAttribute(this.i, 'ProductId');
    PriceId = new DwItemAttribute(this.i, 'PriceId');
}

class SubscriptionTierMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Subscription Tier Status */
            SubscriptionTierStatus = new DwItemRelationship('SubscriptionTierStatus|SubscriptionTier|SubscriptionTierStatusId', 'SubscriptionTierStatus', 'SubscriptionTierStatus');
            /** Relation: Subscription */
            Subscription = new DwItemRelationship('SubscriptionTier|Subscription|SubscriptionTierId', 'Subscription', 'Subscription');
            /** Relation: Subscription Tier Role */
            SubscriptionTierRole = new DwItemRelationship('SubscriptionTier|SubscriptionTierRole|SubscriptionTierId', 'SubscriptionTierRole', 'SubscriptionTierRole');
    };
export class SubscriptionTierRoleMetaData implements DwItem2<SubscriptionTierRoleEntity, SubscriptionTierRoleMetaDataAttributes,SubscriptionTierRoleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=247;
        this.ItemDetail.itemName='SubscriptionTierRole';
        this.ItemDetail.keyName='SubscriptionTierRoleId';
    }

    CreateEntity(): SubscriptionTierRoleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubscriptionTierRoleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubscriptionTierRoleEntity, SubscriptionTierRoleMetaDataAttributes,SubscriptionTierRoleMetaDataRelations>(query, this);
    }
     Attributes = new SubscriptionTierRoleMetaDataAttributes();
     Relations = new SubscriptionTierRoleMetaDataRelations();

}


export type SubscriptionTierRoleQueryBuilder = DwQueryBuilderGen<SubscriptionTierRoleEntity, SubscriptionTierRoleMetaDataAttributes,SubscriptionTierRoleMetaDataRelations>


export class SubscriptionTierRoleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubscriptionTierRole';
    // Attributes
    SubscriptionTierRoleId = new DwItemAttribute(this.i, 'SubscriptionTierRoleId');
    SubscriptionTierId = new DwItemAttribute(this.i, 'SubscriptionTierId');
    SubscriptionRoleId = new DwItemAttribute(this.i, 'SubscriptionRoleId');
    MonthlyCost = new DwItemAttribute(this.i, 'MonthlyCost');
    MinimumBilled = new DwItemAttribute(this.i, 'MinimumBilled');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    PriceId = new DwItemAttribute(this.i, 'PriceId');
}

class SubscriptionTierRoleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Subscription Role */
            SubscriptionRole = new DwItemRelationship('SubscriptionRole|SubscriptionTierRole|SubscriptionRoleId', 'SubscriptionRole', 'SubscriptionRole');
            /** Relation: Subscription Tier */
            SubscriptionTier = new DwItemRelationship('SubscriptionTier|SubscriptionTierRole|SubscriptionTierId', 'SubscriptionTier', 'SubscriptionTier');
    };
export class SubscriptionTierStatusMetaData implements DwItem2<SubscriptionTierStatusEntity, SubscriptionTierStatusMetaDataAttributes,SubscriptionTierStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=243;
        this.ItemDetail.itemName='SubscriptionTierStatus';
        this.ItemDetail.keyName='SubscriptionTierStatusId';
    }

    CreateEntity(): SubscriptionTierStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SubscriptionTierStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SubscriptionTierStatusEntity, SubscriptionTierStatusMetaDataAttributes,SubscriptionTierStatusMetaDataRelations>(query, this);
    }
     Attributes = new SubscriptionTierStatusMetaDataAttributes();
     Relations = new SubscriptionTierStatusMetaDataRelations();

}


export type SubscriptionTierStatusQueryBuilder = DwQueryBuilderGen<SubscriptionTierStatusEntity, SubscriptionTierStatusMetaDataAttributes,SubscriptionTierStatusMetaDataRelations>


export class SubscriptionTierStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SubscriptionTierStatus';
    // Attributes
    SubscriptionTierStatusId = new DwItemAttribute(this.i, 'SubscriptionTierStatusId');
    SubscriptionTierStatus = new DwItemAttribute(this.i, 'SubscriptionTierStatus');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class SubscriptionTierStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Subscription Tier */
            SubscriptionTier = new DwItemRelationship('SubscriptionTierStatus|SubscriptionTier|SubscriptionTierStatusId', 'SubscriptionTier', 'SubscriptionTier');
    };
export class TemplateFieldAlignmentMetaData implements DwItem2<TemplateFieldAlignmentEntity, TemplateFieldAlignmentMetaDataAttributes,TemplateFieldAlignmentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=227;
        this.ItemDetail.itemName='TemplateFieldAlignment';
        this.ItemDetail.keyName='TemplateFieldAlignmentId';
    }

    CreateEntity(): TemplateFieldAlignmentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): TemplateFieldAlignmentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<TemplateFieldAlignmentEntity, TemplateFieldAlignmentMetaDataAttributes,TemplateFieldAlignmentMetaDataRelations>(query, this);
    }
     Attributes = new TemplateFieldAlignmentMetaDataAttributes();
     Relations = new TemplateFieldAlignmentMetaDataRelations();

}


export type TemplateFieldAlignmentQueryBuilder = DwQueryBuilderGen<TemplateFieldAlignmentEntity, TemplateFieldAlignmentMetaDataAttributes,TemplateFieldAlignmentMetaDataRelations>


export class TemplateFieldAlignmentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'TemplateFieldAlignment';
    // Attributes
    TemplateFieldAlignmentId = new DwItemAttribute(this.i, 'TemplateFieldAlignmentId');
    AlignmentDisplay = new DwItemAttribute(this.i, 'AlignmentDisplay');
}

class TemplateFieldAlignmentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement Template Field */
            CourseEndorsementTemplateField = new DwItemRelationship('TemplateFieldAlignment|CourseEndorsementTemplateField|TemplateFieldAlignmentId', 'CourseEndorsementTemplateField', 'CourseEndorsementTemplateField');
    };
export class TemplateFieldTypeMetaData implements DwItem2<TemplateFieldTypeEntity, TemplateFieldTypeMetaDataAttributes,TemplateFieldTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=225;
        this.ItemDetail.itemName='TemplateFieldType';
        this.ItemDetail.keyName='TemplateFieldTypeId';
    }

    CreateEntity(): TemplateFieldTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): TemplateFieldTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<TemplateFieldTypeEntity, TemplateFieldTypeMetaDataAttributes,TemplateFieldTypeMetaDataRelations>(query, this);
    }
     Attributes = new TemplateFieldTypeMetaDataAttributes();
     Relations = new TemplateFieldTypeMetaDataRelations();

}


export type TemplateFieldTypeQueryBuilder = DwQueryBuilderGen<TemplateFieldTypeEntity, TemplateFieldTypeMetaDataAttributes,TemplateFieldTypeMetaDataRelations>


export class TemplateFieldTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'TemplateFieldType';
    // Attributes
    TemplateFieldTypeId = new DwItemAttribute(this.i, 'TemplateFieldTypeId');
    FieldType = new DwItemAttribute(this.i, 'FieldType');
}

class TemplateFieldTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement Template Field */
            CourseEndorsementTemplateField = new DwItemRelationship('TemplateFieldType|CourseEndorsementTemplateField|TemplateFieldTypeId', 'CourseEndorsementTemplateField', 'CourseEndorsementTemplateField');
    };
export class TemplateFontStyleMetaData implements DwItem2<TemplateFontStyleEntity, TemplateFontStyleMetaDataAttributes,TemplateFontStyleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=226;
        this.ItemDetail.itemName='TemplateFontStyle';
        this.ItemDetail.keyName='TemplateFontStyleId';
    }

    CreateEntity(): TemplateFontStyleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): TemplateFontStyleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<TemplateFontStyleEntity, TemplateFontStyleMetaDataAttributes,TemplateFontStyleMetaDataRelations>(query, this);
    }
     Attributes = new TemplateFontStyleMetaDataAttributes();
     Relations = new TemplateFontStyleMetaDataRelations();

}


export type TemplateFontStyleQueryBuilder = DwQueryBuilderGen<TemplateFontStyleEntity, TemplateFontStyleMetaDataAttributes,TemplateFontStyleMetaDataRelations>


export class TemplateFontStyleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'TemplateFontStyle';
    // Attributes
    TemplateFontStyleId = new DwItemAttribute(this.i, 'TemplateFontStyleId');
    StyleName = new DwItemAttribute(this.i, 'StyleName');
    FontFamily = new DwItemAttribute(this.i, 'FontFamily');
    FontSize = new DwItemAttribute(this.i, 'FontSize');
    IsBold = new DwItemAttribute(this.i, 'IsBold');
    IsItalics = new DwItemAttribute(this.i, 'IsItalics');
    Color = new DwItemAttribute(this.i, 'Color');
}

class TemplateFontStyleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Endorsement Template Field */
            CourseEndorsementTemplateField = new DwItemRelationship('TemplateFontStyle|CourseEndorsementTemplateField|TemplateFontStyleId', 'CourseEndorsementTemplateField', 'CourseEndorsementTemplateField');
    };
export class DwThemeMetaData implements DwItem2<DwThemeEntity, DwThemeMetaDataAttributes,DwThemeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=235;
        this.ItemDetail.itemName='dw_Theme';
        this.ItemDetail.keyName='ThemeId';
    }

    CreateEntity(): DwThemeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwThemeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwThemeEntity, DwThemeMetaDataAttributes,DwThemeMetaDataRelations>(query, this);
    }
     Attributes = new DwThemeMetaDataAttributes();
     Relations = new DwThemeMetaDataRelations();

}


export type DwThemeQueryBuilder = DwQueryBuilderGen<DwThemeEntity, DwThemeMetaDataAttributes,DwThemeMetaDataRelations>


export class DwThemeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_Theme';
    // Attributes
    ThemeId = new DwItemAttribute(this.i, 'ThemeId');
    ThemeName = new DwItemAttribute(this.i, 'ThemeName');
    IsDefault = new DwItemAttribute(this.i, 'IsDefault');
    LogoMediaId = new DwItemAttribute(this.i, 'LogoMediaId');
    LogoWidth = new DwItemAttribute(this.i, 'LogoWidth');
    LogoHeight = new DwItemAttribute(this.i, 'LogoHeight');
    AlternateLogoMediaId = new DwItemAttribute(this.i, 'AlternateLogoMediaId');
    AlternateLogoWidth = new DwItemAttribute(this.i, 'AlternateLogoWidth');
    AlternateLogoHeight = new DwItemAttribute(this.i, 'AlternateLogoHeight');
    LogoLoginMediaId = new DwItemAttribute(this.i, 'LogoLoginMediaId');
    LogoLoginWidth = new DwItemAttribute(this.i, 'LogoLoginWidth');
    LogoLoginHeight = new DwItemAttribute(this.i, 'LogoLoginHeight');
}

class DwThemeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Media - Alternate Logo Media */
            DwMediaByAlternateLogoMediaId = new DwItemRelationship('dw_Media|dw_Theme|AlternateLogoMediaId', 'AlternateLogoDwMedia', 'AlternateLogoDwMedia');
            /** Relation: Media - Logo Media */
            DwMediaByLogoMediaId = new DwItemRelationship('dw_Media|dw_Theme|LogoMediaId', 'LogoDwMedia', 'LogoDwMedia');
            /** Relation: Media - Logo Login Media */
            DwMediaByLogoLoginMediaId = new DwItemRelationship('dw_Media|dw_Theme|LogoLoginMediaId', 'LogoLoginDwMedia', 'LogoLoginDwMedia');
            /** Relation: Flight School Branding */
            FlightSchoolBranding = new DwItemRelationship('dw_Theme|FlightSchoolBranding|ThemeId', 'FlightSchoolBranding', 'FlightSchoolBranding');
            /** Relation: Theme Property */
            DwThemeProperty = new DwItemRelationship('dw_Theme|dw_ThemeProperty|ThemeId', 'DwThemeProperty', 'DwThemeProperty');
    };
export class DwThemePropertyMetaData implements DwItem2<DwThemePropertyEntity, DwThemePropertyMetaDataAttributes,DwThemePropertyMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=236;
        this.ItemDetail.itemName='dw_ThemeProperty';
        this.ItemDetail.keyName='ThemePropertyId';
    }

    CreateEntity(): DwThemePropertyEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwThemePropertyQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwThemePropertyEntity, DwThemePropertyMetaDataAttributes,DwThemePropertyMetaDataRelations>(query, this);
    }
     Attributes = new DwThemePropertyMetaDataAttributes();
     Relations = new DwThemePropertyMetaDataRelations();

}


export type DwThemePropertyQueryBuilder = DwQueryBuilderGen<DwThemePropertyEntity, DwThemePropertyMetaDataAttributes,DwThemePropertyMetaDataRelations>


export class DwThemePropertyMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_ThemeProperty';
    // Attributes
    ThemePropertyId = new DwItemAttribute(this.i, 'ThemePropertyId');
    ThemeId = new DwItemAttribute(this.i, 'ThemeId');
    ThemePropertyDefinitionId = new DwItemAttribute(this.i, 'ThemePropertyDefinitionId');
    PropertyValue = new DwItemAttribute(this.i, 'PropertyValue');
}

class DwThemePropertyMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Theme */
            DwTheme = new DwItemRelationship('dw_Theme|dw_ThemeProperty|ThemeId', 'DwTheme', 'DwTheme');
            /** Relation: Theme Property Definition */
            DwThemePropertyDefinition = new DwItemRelationship('dw_ThemePropertyDefinition|dw_ThemeProperty|ThemePropertyDefinitionId', 'DwThemePropertyDefinition', 'DwThemePropertyDefinition');
    };
export class DwThemePropertyDefinitionMetaData implements DwItem2<DwThemePropertyDefinitionEntity, DwThemePropertyDefinitionMetaDataAttributes,DwThemePropertyDefinitionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=237;
        this.ItemDetail.itemName='dw_ThemePropertyDefinition';
        this.ItemDetail.keyName='ThemePropertyDefinitionId';
    }

    CreateEntity(): DwThemePropertyDefinitionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwThemePropertyDefinitionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwThemePropertyDefinitionEntity, DwThemePropertyDefinitionMetaDataAttributes,DwThemePropertyDefinitionMetaDataRelations>(query, this);
    }
     Attributes = new DwThemePropertyDefinitionMetaDataAttributes();
     Relations = new DwThemePropertyDefinitionMetaDataRelations();

}


export type DwThemePropertyDefinitionQueryBuilder = DwQueryBuilderGen<DwThemePropertyDefinitionEntity, DwThemePropertyDefinitionMetaDataAttributes,DwThemePropertyDefinitionMetaDataRelations>


export class DwThemePropertyDefinitionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_ThemePropertyDefinition';
    // Attributes
    ThemePropertyDefinitionId = new DwItemAttribute(this.i, 'ThemePropertyDefinitionId');
    PropertyDisplay = new DwItemAttribute(this.i, 'PropertyDisplay');
    PropertyName = new DwItemAttribute(this.i, 'PropertyName');
    ThemePropertyTypeId = new DwItemAttribute(this.i, 'ThemePropertyTypeId');
}

class DwThemePropertyDefinitionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Theme Property Type */
            DwThemePropertyType = new DwItemRelationship('dw_ThemePropertyType|dw_ThemePropertyDefinition|ThemePropertyTypeId', 'DwThemePropertyType', 'DwThemePropertyType');
            /** Relation: Theme Property */
            DwThemeProperty = new DwItemRelationship('dw_ThemePropertyDefinition|dw_ThemeProperty|ThemePropertyDefinitionId', 'DwThemeProperty', 'DwThemeProperty');
    };
export class DwThemePropertyTypeMetaData implements DwItem2<DwThemePropertyTypeEntity, DwThemePropertyTypeMetaDataAttributes,DwThemePropertyTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=238;
        this.ItemDetail.itemName='dw_ThemePropertyType';
        this.ItemDetail.keyName='ThemePropertyTypeId';
    }

    CreateEntity(): DwThemePropertyTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwThemePropertyTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwThemePropertyTypeEntity, DwThemePropertyTypeMetaDataAttributes,DwThemePropertyTypeMetaDataRelations>(query, this);
    }
     Attributes = new DwThemePropertyTypeMetaDataAttributes();
     Relations = new DwThemePropertyTypeMetaDataRelations();

}


export type DwThemePropertyTypeQueryBuilder = DwQueryBuilderGen<DwThemePropertyTypeEntity, DwThemePropertyTypeMetaDataAttributes,DwThemePropertyTypeMetaDataRelations>


export class DwThemePropertyTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_ThemePropertyType';
    // Attributes
    ThemePropertyTypeId = new DwItemAttribute(this.i, 'ThemePropertyTypeId');
    PropertyType = new DwItemAttribute(this.i, 'PropertyType');
}

class DwThemePropertyTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Theme Property Definition */
            DwThemePropertyDefinition = new DwItemRelationship('dw_ThemePropertyType|dw_ThemePropertyDefinition|ThemePropertyTypeId', 'DwThemePropertyDefinition', 'DwThemePropertyDefinition');
    };
export class DwUserMetaData implements DwItem2<DwUserEntity, DwUserMetaDataAttributes,DwUserMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=46;
        this.ItemDetail.itemName='dw_User';
        this.ItemDetail.keyName='UserId';
    }

    CreateEntity(): DwUserEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwUserQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwUserEntity, DwUserMetaDataAttributes,DwUserMetaDataRelations>(query, this);
    }
     Attributes = new DwUserMetaDataAttributes();
     Relations = new DwUserMetaDataRelations();

}


export type DwUserQueryBuilder = DwQueryBuilderGen<DwUserEntity, DwUserMetaDataAttributes,DwUserMetaDataRelations>


export class DwUserMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_User';
    // Attributes
    AlternateName = new DwItemAttribute(this.i, 'AlternateName');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    Email = new DwItemAttribute(this.i, 'Email');
    FirstName = new DwItemAttribute(this.i, 'FirstName');
    ForcePasswordChange = new DwItemAttribute(this.i, 'ForcePasswordChange');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    IsEmailVerified = new DwItemAttribute(this.i, 'IsEmailVerified');
    IsTwoFactorAuthenticationEnabled = new DwItemAttribute(this.i, 'IsTwoFactorAuthenticationEnabled');
    LastLoginDateTime = new DwItemAttribute(this.i, 'LastLoginDateTime');
    LastName = new DwItemAttribute(this.i, 'LastName');
    LastPasswordChangeDateTime = new DwItemAttribute(this.i, 'LastPasswordChangeDateTime');
    MobilePhone = new DwItemAttribute(this.i, 'MobilePhone');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    OrganizationId = new DwItemAttribute(this.i, 'OrganizationId');
    PartyReferenceId = new DwItemAttribute(this.i, 'PartyReferenceId');
    TwoFactorAuthenticationTypeId = new DwItemAttribute(this.i, 'TwoFactorAuthenticationTypeId');
    UserBackgroundMediaId = new DwItemAttribute(this.i, 'UserBackgroundMediaId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    UserKey = new DwItemAttribute(this.i, 'UserKey');
    UserName = new DwItemAttribute(this.i, 'UserName');
    UserPictureMediaId = new DwItemAttribute(this.i, 'UserPictureMediaId');
    UserTypeId = new DwItemAttribute(this.i, 'UserTypeId');
    Password = new DwItemAttribute(this.i, 'Password');
}

class DwUserMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Media - User Picture Media */
            DwMediaByUserPictureMediaId = new DwItemRelationship('dw_Media|dw_User|UserPictureMediaId', 'UserPictureDwMedia', 'UserPictureDwMedia');
            /** Relation: Media - User Background Media */
            DwMediaByUserBackgroundMediaId = new DwItemRelationship('dw_Media|dw_User|UserBackgroundMediaId', 'UserBackgroundDwMedia', 'UserBackgroundDwMedia');
            /** Relation: User Type */
            DwUserType = new DwItemRelationship('dw_UserType|dw_User|UserTypeId', 'DwUserType', 'DwUserType');
            /** Relation: Course Lesson Flag - Modified By - Many */
            CourseLessonFlagByModifiedBy = new DwItemRelationship('dw_User|CourseLessonFlag|ModifiedBy', 'ModifiedByCourseLessonFlag', 'ModifiedByCourseLessonFlag');
            /** Relation: Course Lesson Flag - Created By - Many */
            CourseLessonFlagByCreatedBy = new DwItemRelationship('dw_User|CourseLessonFlag|CreatedBy', 'CreatedByCourseLessonFlag', 'CreatedByCourseLessonFlag');
            /** Relation: Instructor */
            Instructor = new DwItemRelationship('dw_User|Instructor|UserId', 'Instructor', 'Instructor');
            /** Relation: Join Request Email */
            JoinRequestEmail = new DwItemRelationship('dw_User|JoinRequestEmail|UserId', 'JoinRequestEmail', 'JoinRequestEmail');
            /** Relation: Ro User Role Detail */
            RoUserRoleDetail = new DwItemRelationship('RoUserRoleDetail|User|UserId', 'RoUserRoleDetail', 'RoUserRoleDetail');
            /** Relation: Student */
            Student = new DwItemRelationship('dw_User|Student|UserId', 'Student', 'Student');
            /** Relation: User Detail */
            UserDetail = new DwItemRelationship('dw_User|UserDetail|UserId', 'UserDetail', 'UserDetail');
            /** Relation: User Role */
            DwUserRole = new DwItemRelationship('dw_User|dw_UserRole|UserId', 'DwUserRole', 'DwUserRole');
            /** Relation: User Subscription Role */
            UserSubscriptionRole = new DwItemRelationship('dw_User|UserSubscriptionRole|UserId', 'UserSubscriptionRole', 'UserSubscriptionRole');
    };
export class UserDetailMetaData implements DwItem2<UserDetailEntity, UserDetailMetaDataAttributes,UserDetailMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=217;
        this.ItemDetail.itemName='UserDetail';
        this.ItemDetail.keyName='UserDetailId';
    }

    CreateEntity(): UserDetailEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): UserDetailQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<UserDetailEntity, UserDetailMetaDataAttributes,UserDetailMetaDataRelations>(query, this);
    }
     Attributes = new UserDetailMetaDataAttributes();
     Relations = new UserDetailMetaDataRelations();

}


export type UserDetailQueryBuilder = DwQueryBuilderGen<UserDetailEntity, UserDetailMetaDataAttributes,UserDetailMetaDataRelations>


export class UserDetailMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'UserDetail';
    // Attributes
    UserDetailId = new DwItemAttribute(this.i, 'UserDetailId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
}

class UserDetailMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|UserDetail|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|UserDetail|UserId', 'DwUser', 'DwUser');
    };
export class DwUserRoleMetaData implements DwItem2<DwUserRoleEntity, DwUserRoleMetaDataAttributes,DwUserRoleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=43;
        this.ItemDetail.itemName='dw_UserRole';
        this.ItemDetail.keyName='UserRoleId';
    }

    CreateEntity(): DwUserRoleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwUserRoleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwUserRoleEntity, DwUserRoleMetaDataAttributes,DwUserRoleMetaDataRelations>(query, this);
    }
     Attributes = new DwUserRoleMetaDataAttributes();
     Relations = new DwUserRoleMetaDataRelations();

}


export type DwUserRoleQueryBuilder = DwQueryBuilderGen<DwUserRoleEntity, DwUserRoleMetaDataAttributes,DwUserRoleMetaDataRelations>


export class DwUserRoleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_UserRole';
    // Attributes
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    RoleId = new DwItemAttribute(this.i, 'RoleId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    UserRoleId = new DwItemAttribute(this.i, 'UserRoleId');
}

class DwUserRoleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|dw_UserRole|UserId', 'DwUser', 'DwUser');
    };
export class UserSubscriptionRoleMetaData implements DwItem2<UserSubscriptionRoleEntity, UserSubscriptionRoleMetaDataAttributes,UserSubscriptionRoleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=248;
        this.ItemDetail.itemName='UserSubscriptionRole';
        this.ItemDetail.keyName='UserSubscriptionRoleId';
    }

    CreateEntity(): UserSubscriptionRoleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): UserSubscriptionRoleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<UserSubscriptionRoleEntity, UserSubscriptionRoleMetaDataAttributes,UserSubscriptionRoleMetaDataRelations>(query, this);
    }
     Attributes = new UserSubscriptionRoleMetaDataAttributes();
     Relations = new UserSubscriptionRoleMetaDataRelations();

}


export type UserSubscriptionRoleQueryBuilder = DwQueryBuilderGen<UserSubscriptionRoleEntity, UserSubscriptionRoleMetaDataAttributes,UserSubscriptionRoleMetaDataRelations>


export class UserSubscriptionRoleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'UserSubscriptionRole';
    // Attributes
    UserSubscriptionRoleId = new DwItemAttribute(this.i, 'UserSubscriptionRoleId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    SubscriptionRoleId = new DwItemAttribute(this.i, 'SubscriptionRoleId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class UserSubscriptionRoleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Subscription Role */
            SubscriptionRole = new DwItemRelationship('SubscriptionRole|UserSubscriptionRole|SubscriptionRoleId', 'SubscriptionRole', 'SubscriptionRole');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|UserSubscriptionRole|UserId', 'DwUser', 'DwUser');
    };
export class DwUserTypeMetaData implements DwItem2<DwUserTypeEntity, DwUserTypeMetaDataAttributes,DwUserTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=133;
        this.ItemDetail.itemName='dw_UserType';
        this.ItemDetail.keyName='UserTypeId';
    }

    CreateEntity(): DwUserTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwUserTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DwUserTypeEntity, DwUserTypeMetaDataAttributes,DwUserTypeMetaDataRelations>(query, this);
    }
     Attributes = new DwUserTypeMetaDataAttributes();
     Relations = new DwUserTypeMetaDataRelations();

}


export type DwUserTypeQueryBuilder = DwQueryBuilderGen<DwUserTypeEntity, DwUserTypeMetaDataAttributes,DwUserTypeMetaDataRelations>


export class DwUserTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_UserType';
    // Attributes
    ShowOnRegistration = new DwItemAttribute(this.i, 'ShowOnRegistration');
    UserTypeClasses = new DwItemAttribute(this.i, 'UserTypeClasses');
    UserTypeDescription = new DwItemAttribute(this.i, 'UserTypeDescription');
    UserTypeDisplay = new DwItemAttribute(this.i, 'UserTypeDisplay');
    UserTypeId = new DwItemAttribute(this.i, 'UserTypeId');
    UserTypeOrder = new DwItemAttribute(this.i, 'UserTypeOrder');
}

class DwUserTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Join Request Type */
            JoinRequestType = new DwItemRelationship('dw_UserType|JoinRequestType|UserTypeId', 'JoinRequestType', 'JoinRequestType');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_UserType|dw_User|UserTypeId', 'DwUser', 'DwUser');
    };
export class VideoMetaData implements DwItem2<VideoEntity, VideoMetaDataAttributes,VideoMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=156;
        this.ItemDetail.itemName='Video';
        this.ItemDetail.keyName='VideoId';
    }

    CreateEntity(): VideoEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): VideoQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<VideoEntity, VideoMetaDataAttributes,VideoMetaDataRelations>(query, this);
    }
     Attributes = new VideoMetaDataAttributes();
     Relations = new VideoMetaDataRelations();

}


export type VideoQueryBuilder = DwQueryBuilderGen<VideoEntity, VideoMetaDataAttributes,VideoMetaDataRelations>


export class VideoMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Video';
    // Attributes
    VideoId = new DwItemAttribute(this.i, 'VideoId');
    VideoName = new DwItemAttribute(this.i, 'VideoName');
    VideoLibraryId = new DwItemAttribute(this.i, 'VideoLibraryId');
    VideoKey = new DwItemAttribute(this.i, 'VideoKey');
    DirectPlayUrl = new DwItemAttribute(this.i, 'DirectPlayUrl');
    ThumbnailUrl = new DwItemAttribute(this.i, 'ThumbnailUrl');
    VideoStreamingProviderId = new DwItemAttribute(this.i, 'VideoStreamingProviderId');
    VideoStatusId = new DwItemAttribute(this.i, 'VideoStatusId');
    VideoSizeBytes = new DwItemAttribute(this.i, 'VideoSizeBytes');
    VideoDurationSeconds = new DwItemAttribute(this.i, 'VideoDurationSeconds');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    AllowMp4 = new DwItemAttribute(this.i, 'AllowMp4');
    IsPrivateVideo = new DwItemAttribute(this.i, 'IsPrivateVideo');
    OriginalFileName = new DwItemAttribute(this.i, 'OriginalFileName');
}

class VideoMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Video Library */
            VideoLibrary = new DwItemRelationship('VideoLibrary|Video|VideoLibraryId', 'VideoLibrary', 'VideoLibrary');
            /** Relation: Video Status */
            VideoStatus = new DwItemRelationship('VideoStatus|Video|VideoStatusId', 'VideoStatus', 'VideoStatus');
            /** Relation: Video Streaming Provider */
            VideoStreamingProvider = new DwItemRelationship('VideoStreamingProvider|Video|VideoStreamingProviderId', 'VideoStreamingProvider', 'VideoStreamingProvider');
            /** Relation: Lesson */
            CourseLesson = new DwItemRelationship('Video|CourseLesson|VideoId', 'CourseLesson', 'CourseLesson');
            /** Relation: Video History */
            VideoHistory = new DwItemRelationship('Video|VideoHistory|VideoId', 'VideoHistory', 'VideoHistory');
            /** Relation: Video Tag Link */
            VideoTagLink = new DwItemRelationship('Video|VideoTagLink|VideoId', 'VideoTagLink', 'VideoTagLink');
    };
export class VideoHistoryMetaData implements DwItem2<VideoHistoryEntity, VideoHistoryMetaDataAttributes,VideoHistoryMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=216;
        this.ItemDetail.itemName='VideoHistory';
        this.ItemDetail.keyName='VideoHistoryId';
    }

    CreateEntity(): VideoHistoryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): VideoHistoryQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<VideoHistoryEntity, VideoHistoryMetaDataAttributes,VideoHistoryMetaDataRelations>(query, this);
    }
     Attributes = new VideoHistoryMetaDataAttributes();
     Relations = new VideoHistoryMetaDataRelations();

}


export type VideoHistoryQueryBuilder = DwQueryBuilderGen<VideoHistoryEntity, VideoHistoryMetaDataAttributes,VideoHistoryMetaDataRelations>


export class VideoHistoryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'VideoHistory';
    // Attributes
    VideoHistoryId = new DwItemAttribute(this.i, 'VideoHistoryId');
    VideoId = new DwItemAttribute(this.i, 'VideoId');
    VideoKey = new DwItemAttribute(this.i, 'VideoKey');
    DirectPlayUrl = new DwItemAttribute(this.i, 'DirectPlayUrl');
    ThumbnailUrl = new DwItemAttribute(this.i, 'ThumbnailUrl');
    VideoSizeBytes = new DwItemAttribute(this.i, 'VideoSizeBytes');
    VideoDurationSeconds = new DwItemAttribute(this.i, 'VideoDurationSeconds');
    AllowMp4 = new DwItemAttribute(this.i, 'AllowMp4');
    IsPrivateVideo = new DwItemAttribute(this.i, 'IsPrivateVideo');
    OriginalFileName = new DwItemAttribute(this.i, 'OriginalFileName');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class VideoHistoryMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Video */
            Video = new DwItemRelationship('Video|VideoHistory|VideoId', 'Video', 'Video');
    };
export class VideoLibraryMetaData implements DwItem2<VideoLibraryEntity, VideoLibraryMetaDataAttributes,VideoLibraryMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=158;
        this.ItemDetail.itemName='VideoLibrary';
        this.ItemDetail.keyName='VideoLibraryId';
    }

    CreateEntity(): VideoLibraryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): VideoLibraryQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<VideoLibraryEntity, VideoLibraryMetaDataAttributes,VideoLibraryMetaDataRelations>(query, this);
    }
     Attributes = new VideoLibraryMetaDataAttributes();
     Relations = new VideoLibraryMetaDataRelations();

}


export type VideoLibraryQueryBuilder = DwQueryBuilderGen<VideoLibraryEntity, VideoLibraryMetaDataAttributes,VideoLibraryMetaDataRelations>


export class VideoLibraryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'VideoLibrary';
    // Attributes
    VideoLibraryId = new DwItemAttribute(this.i, 'VideoLibraryId');
    VideoLibraryName = new DwItemAttribute(this.i, 'VideoLibraryName');
    VideoLibraryKey = new DwItemAttribute(this.i, 'VideoLibraryKey');
    VideoStreamingProviderId = new DwItemAttribute(this.i, 'VideoStreamingProviderId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class VideoLibraryMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Video Streaming Provider */
            VideoStreamingProvider = new DwItemRelationship('VideoStreamingProvider|VideoLibrary|VideoStreamingProviderId', 'VideoStreamingProvider', 'VideoStreamingProvider');
            /** Relation: Video */
            Video = new DwItemRelationship('VideoLibrary|Video|VideoLibraryId', 'Video', 'Video');
    };
export class VideoStatusMetaData implements DwItem2<VideoStatusEntity, VideoStatusMetaDataAttributes,VideoStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=174;
        this.ItemDetail.itemName='VideoStatus';
        this.ItemDetail.keyName='VideoStatusId';
    }

    CreateEntity(): VideoStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): VideoStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<VideoStatusEntity, VideoStatusMetaDataAttributes,VideoStatusMetaDataRelations>(query, this);
    }
     Attributes = new VideoStatusMetaDataAttributes();
     Relations = new VideoStatusMetaDataRelations();

}


export type VideoStatusQueryBuilder = DwQueryBuilderGen<VideoStatusEntity, VideoStatusMetaDataAttributes,VideoStatusMetaDataRelations>


export class VideoStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'VideoStatus';
    // Attributes
    VideoStatusId = new DwItemAttribute(this.i, 'VideoStatusId');
    VideoStatus = new DwItemAttribute(this.i, 'VideoStatus');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class VideoStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Video */
            Video = new DwItemRelationship('VideoStatus|Video|VideoStatusId', 'Video', 'Video');
    };
export class VideoStreamingProviderMetaData implements DwItem2<VideoStreamingProviderEntity, VideoStreamingProviderMetaDataAttributes,VideoStreamingProviderMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=159;
        this.ItemDetail.itemName='VideoStreamingProvider';
        this.ItemDetail.keyName='VideoStreamingProviderId';
    }

    CreateEntity(): VideoStreamingProviderEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): VideoStreamingProviderQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<VideoStreamingProviderEntity, VideoStreamingProviderMetaDataAttributes,VideoStreamingProviderMetaDataRelations>(query, this);
    }
     Attributes = new VideoStreamingProviderMetaDataAttributes();
     Relations = new VideoStreamingProviderMetaDataRelations();

}


export type VideoStreamingProviderQueryBuilder = DwQueryBuilderGen<VideoStreamingProviderEntity, VideoStreamingProviderMetaDataAttributes,VideoStreamingProviderMetaDataRelations>


export class VideoStreamingProviderMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'VideoStreamingProvider';
    // Attributes
    VideoStreamingProviderId = new DwItemAttribute(this.i, 'VideoStreamingProviderId');
    ProviderName = new DwItemAttribute(this.i, 'ProviderName');
    ProviderManagementUrl = new DwItemAttribute(this.i, 'ProviderManagementUrl');
    AutoPlay = new DwItemAttribute(this.i, 'AutoPlay');
    ContinueFromLastPosition = new DwItemAttribute(this.i, 'ContinueFromLastPosition');
    AllowSeek = new DwItemAttribute(this.i, 'AllowSeek');
    SeekThreshholdSeconds = new DwItemAttribute(this.i, 'SeekThreshholdSeconds');
    PreloadVideo = new DwItemAttribute(this.i, 'PreloadVideo');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    AllowMp4Default = new DwItemAttribute(this.i, 'AllowMp4Default');
    IsPrivateVideoDefault = new DwItemAttribute(this.i, 'IsPrivateVideoDefault');
}

class VideoStreamingProviderMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Video */
            Video = new DwItemRelationship('VideoStreamingProvider|Video|VideoStreamingProviderId', 'Video', 'Video');
            /** Relation: Video Library */
            VideoLibrary = new DwItemRelationship('VideoStreamingProvider|VideoLibrary|VideoStreamingProviderId', 'VideoLibrary', 'VideoLibrary');
    };
export class VideoTagLinkMetaData implements DwItem2<VideoTagLinkEntity, VideoTagLinkMetaDataAttributes,VideoTagLinkMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=215;
        this.ItemDetail.itemName='VideoTagLink';
        this.ItemDetail.keyName='VideoTagLink';
    }

    CreateEntity(): VideoTagLinkEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): VideoTagLinkQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<VideoTagLinkEntity, VideoTagLinkMetaDataAttributes,VideoTagLinkMetaDataRelations>(query, this);
    }
     Attributes = new VideoTagLinkMetaDataAttributes();
     Relations = new VideoTagLinkMetaDataRelations();

}


export type VideoTagLinkQueryBuilder = DwQueryBuilderGen<VideoTagLinkEntity, VideoTagLinkMetaDataAttributes,VideoTagLinkMetaDataRelations>


export class VideoTagLinkMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'VideoTagLink';
    // Attributes
    VideoTagLink = new DwItemAttribute(this.i, 'VideoTagLink');
    VideoId = new DwItemAttribute(this.i, 'VideoId');
    TagId = new DwItemAttribute(this.i, 'TagId');
}

class VideoTagLinkMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Tag - Question Tag */
            QuestionTagByTagId = new DwItemRelationship('QuestionTag|VideoTagLink|TagId', 'TagIdQuestionTag', 'TagIdQuestionTag');
            /** Relation: Video */
            Video = new DwItemRelationship('Video|VideoTagLink|VideoId', 'Video', 'Video');
    };
// Generated Query Classes for MetaData Service
export class CourseListForStudentQueryMetaData implements DwItem2<CourseListForStudentQueryEntity, CourseListForStudentQueryMetaDataAttributes,CourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Course_List-_For_Student';

    constructor() {
        this.ItemDetail.itemId=145;
        this.ItemDetail.itemName='Course';
        this.ItemDetail.keyName='CourseId';
    }

    CreateEntity(): CourseListForStudentQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): CourseListForStudentQueryBuilder {
        return new DwQueryBuilderGen<CourseListForStudentQueryEntity, CourseListForStudentQueryMetaDataAttributes,CourseMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<CourseListForStudentQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: CourseListForStudentQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<CourseListForStudentQueryEntity, CourseListForStudentQueryMetaDataAttributes,CourseMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new CourseListForStudentQueryMetaDataAttributes();
     Relations = new CourseMetaDataRelations();

}


export type CourseListForStudentQueryBuilder = DwQueryBuilderGen<CourseListForStudentQueryEntity, CourseListForStudentQueryMetaDataAttributes,CourseMetaDataRelations>


export class CourseListForStudentQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Course';
    // Attributes
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CourseName = new DwItemAttribute(this.i, 'CourseName');
    CourseDescription = new DwItemAttribute(this.i, 'CourseDescription');
    CourseTypeId = new DwItemAttribute(this.i, 'CourseTypeId');
    CourseImageMediaId = new DwItemAttribute(this.i, 'CourseImageMediaId');
    CourseNotes = new DwItemAttribute(this.i, 'CourseNotes');
    AircraftTypeId = new DwItemAttribute(this.i, 'AircraftTypeId');
    AvionicsType = new DwItemAttribute(this.i, 'AvionicsType');
    ProgramTypeId = new DwItemAttribute(this.i, 'ProgramTypeId');
    RetailPrice = new DwItemAttribute(this.i, 'RetailPrice');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    CourseStatusId = new DwItemAttribute(this.i, 'CourseStatusId');
    StudentCourseStudentId = new DwItemAttribute('StudentCourse', 'StudentCourseStudentId');
    StudentCourseCourseId = new DwItemAttribute('StudentCourse', 'StudentCourseCourseId');
    StudentCourseIsActive = new DwItemAttribute('StudentCourse', 'StudentCourseIsActive');
    StudentCoursePercentComplete = new DwItemAttribute('StudentCourse', 'StudentCoursePercentComplete');
    StudentCourseModulesCompleted = new DwItemAttribute('StudentCourse', 'StudentCourseModulesCompleted');
    StudentCourseModulesCount = new DwItemAttribute('StudentCourse', 'StudentCourseModulesCount');
    StudentCourseLastActivityDateTime = new DwItemAttribute('StudentCourse', 'StudentCourseLastActivityDateTime');
    StudentCourseIsEndorsed = new DwItemAttribute('StudentCourse', 'StudentCourseIsEndorsed');
    StudentCourseStudentCourseStatusId = new DwItemAttribute('StudentCourse', 'StudentCourseStudentCourseStatusId');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    CourseEndorsementStudentCompletionDateTime = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentCompletionDateTime');
    CourseEndorsementStudentExpirationDateTime = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentExpirationDateTime');
    CourseEndorsementStudentEndorsementPDFMediaId = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentEndorsementPDFMediaId');
    CourseEndorsementStudentCourseEndorsementStudentId = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentCourseEndorsementStudentId');
    CourseEndorsementStudentCourseEndorsementId = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentCourseEndorsementId');
    MediaImageHeight = new DwItemAttribute('dw_roMedia', 'MediaImageHeight');
    MediaImageWidth = new DwItemAttribute('dw_roMedia', 'MediaImageWidth');
    MediaMediaPath = new DwItemAttribute('dw_roMedia', 'MediaMediaPath');
}
export class ModuleListForStudentQueryMetaData implements DwItem2<ModuleListForStudentQueryEntity, ModuleListForStudentQueryMetaDataAttributes,CourseModuleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Module_List-For_Student';

    constructor() {
        this.ItemDetail.itemId=152;
        this.ItemDetail.itemName='CourseModule';
        this.ItemDetail.keyName='CourseModuleId';
    }

    CreateEntity(): ModuleListForStudentQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): ModuleListForStudentQueryBuilder {
        return new DwQueryBuilderGen<ModuleListForStudentQueryEntity, ModuleListForStudentQueryMetaDataAttributes,CourseModuleMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<ModuleListForStudentQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: ModuleListForStudentQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<ModuleListForStudentQueryEntity, ModuleListForStudentQueryMetaDataAttributes,CourseModuleMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new ModuleListForStudentQueryMetaDataAttributes();
     Relations = new CourseModuleMetaDataRelations();

}


export type ModuleListForStudentQueryBuilder = DwQueryBuilderGen<ModuleListForStudentQueryEntity, ModuleListForStudentQueryMetaDataAttributes,CourseModuleMetaDataRelations>


export class ModuleListForStudentQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseModule';
    // Attributes
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    ModuleName = new DwItemAttribute(this.i, 'ModuleName');
    ModuleDescription = new DwItemAttribute(this.i, 'ModuleDescription');
    ModuleImageMediaId = new DwItemAttribute(this.i, 'ModuleImageMediaId');
    ModuleOrder = new DwItemAttribute(this.i, 'ModuleOrder');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModuleStatusId = new DwItemAttribute(this.i, 'ModuleStatusId');
    CourseModuleProgressCourseModuleProgressId = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressCourseModuleProgressId');
    CourseModuleProgressPercentComplete = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressPercentComplete');
    CourseModuleProgressLessonsCompleted = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressLessonsCompleted');
    CourseModuleProgressLessonsCount = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressLessonsCount');
    CourseModuleProgressLastActivityDateTime = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressLastActivityDateTime');
    CourseModuleProgressTotalTimeSeconds = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressTotalTimeSeconds');
    CourseModuleProgressFirstActivityDateTime = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressFirstActivityDateTime');
    CourseModuleProgressCompletedDateTime = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressCompletedDateTime');
    StudentCourseIsActive = new DwItemAttribute('StudentCourse', 'StudentCourseIsActive');
    StudentStudentId = new DwItemAttribute('Student', 'StudentStudentId');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    CourseImageMediaPath = new DwItemAttribute('dw_roMedia', 'CourseImageMediaPath');
    MediaImageWidth = new DwItemAttribute('dw_roMedia', 'MediaImageWidth');
    MediaImageHeight = new DwItemAttribute('dw_roMedia', 'MediaImageHeight');
    MediaMediaPath = new DwItemAttribute('dw_roMedia', 'MediaMediaPath');
}
export class LessonListForStudentQueryMetaData implements DwItem2<LessonListForStudentQueryEntity, LessonListForStudentQueryMetaDataAttributes,CourseLessonMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Lesson_List-For_Student';

    constructor() {
        this.ItemDetail.itemId=153;
        this.ItemDetail.itemName='CourseLesson';
        this.ItemDetail.keyName='CourseLessonId';
    }

    CreateEntity(): LessonListForStudentQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): LessonListForStudentQueryBuilder {
        return new DwQueryBuilderGen<LessonListForStudentQueryEntity, LessonListForStudentQueryMetaDataAttributes,CourseLessonMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<LessonListForStudentQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: LessonListForStudentQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<LessonListForStudentQueryEntity, LessonListForStudentQueryMetaDataAttributes,CourseLessonMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new LessonListForStudentQueryMetaDataAttributes();
     Relations = new CourseLessonMetaDataRelations();

}


export type LessonListForStudentQueryBuilder = DwQueryBuilderGen<LessonListForStudentQueryEntity, LessonListForStudentQueryMetaDataAttributes,CourseLessonMetaDataRelations>


export class LessonListForStudentQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLesson';
    // Attributes
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    LessonName = new DwItemAttribute(this.i, 'LessonName');
    LessonDescription = new DwItemAttribute(this.i, 'LessonDescription');
    LessonTypeId = new DwItemAttribute(this.i, 'LessonTypeId');
    VideoId = new DwItemAttribute(this.i, 'VideoId');
    QuizId = new DwItemAttribute(this.i, 'QuizId');
    LessonOrder = new DwItemAttribute(this.i, 'LessonOrder');
    LessonImageMediaId = new DwItemAttribute(this.i, 'LessonImageMediaId');
    CourseLessonProgressLessonComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLessonComplete');
    CourseLessonProgressVideoFurthestTimeSeconds = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressVideoFurthestTimeSeconds');
    CourseLessonProgressBestQuizResultId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressBestQuizResultId');
    CourseLessonProgressLastActivityDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLastActivityDateTime');
    CourseLessonProgressPercentComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressPercentComplete');
    CourseLessonProgressTotalTimeSeconds = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressTotalTimeSeconds');
    CourseLessonProgressFirstActivityDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressFirstActivityDateTime');
    CourseLessonProgressCompletedDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCompletedDateTime');
    CourseModuleProgressCourseModuleId = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressCourseModuleId');
    StudentCourseCourseId = new DwItemAttribute('StudentCourse', 'StudentCourseCourseId');
    StudentCourseIsActive = new DwItemAttribute('StudentCourse', 'StudentCourseIsActive');
    StudentStudentId = new DwItemAttribute('Student', 'StudentStudentId');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    CourseImageMediaPath = new DwItemAttribute('dw_roMedia', 'CourseImageMediaPath');
    CourseModuleCourseId = new DwItemAttribute('CourseModule', 'CourseModuleCourseId');
    ModuleImageMediaPath = new DwItemAttribute('dw_roMedia', 'ModuleImageMediaPath');
    VideoThumbnailUrl = new DwItemAttribute('Video', 'VideoThumbnailUrl');
    VideoVideoName = new DwItemAttribute('Video', 'VideoVideoName');
    LessonImageDwroMediaMediaPath = new DwItemAttribute('dw_roMedia', 'LessonImageDwroMediaMediaPath');
}
export class StudentCourseProgressQueryMetaData implements DwItem2<StudentCourseProgressQueryEntity, StudentCourseProgressQueryMetaDataAttributes,StudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student-Course_Progress';

    constructor() {
        this.ItemDetail.itemId=144;
        this.ItemDetail.itemName='Student';
        this.ItemDetail.keyName='StudentId';
    }

    CreateEntity(): StudentCourseProgressQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentCourseProgressQueryBuilder {
        return new DwQueryBuilderGen<StudentCourseProgressQueryEntity, StudentCourseProgressQueryMetaDataAttributes,StudentMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentCourseProgressQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentCourseProgressQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentCourseProgressQueryEntity, StudentCourseProgressQueryMetaDataAttributes,StudentMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentCourseProgressQueryMetaDataAttributes();
     Relations = new StudentMetaDataRelations();

}


export type StudentCourseProgressQueryBuilder = DwQueryBuilderGen<StudentCourseProgressQueryEntity, StudentCourseProgressQueryMetaDataAttributes,StudentMetaDataRelations>


export class StudentCourseProgressQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Student';
    // Attributes
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    StudentCourseStudentCourseId = new DwItemAttribute('StudentCourse', 'StudentCourseStudentCourseId');
    StudentCourseIsActive = new DwItemAttribute('StudentCourse', 'StudentCourseIsActive');
    StudentCoursePercentComplete = new DwItemAttribute('StudentCourse', 'StudentCoursePercentComplete');
    StudentCourseModulesCompleted = new DwItemAttribute('StudentCourse', 'StudentCourseModulesCompleted');
    StudentCourseModulesCount = new DwItemAttribute('StudentCourse', 'StudentCourseModulesCount');
    StudentCourseLastActivityDateTime = new DwItemAttribute('StudentCourse', 'StudentCourseLastActivityDateTime');
    StudentCourseLessonsCompleted = new DwItemAttribute('StudentCourse', 'StudentCourseLessonsCompleted');
    StudentCourseLessonCount = new DwItemAttribute('StudentCourse', 'StudentCourseLessonCount');
    StudentCourseTotalTimeSeconds = new DwItemAttribute('StudentCourse', 'StudentCourseTotalTimeSeconds');
    StudentCourseStudentId = new DwItemAttribute('StudentCourse', 'StudentCourseStudentId');
    StudentCourseCourseId = new DwItemAttribute('StudentCourse', 'StudentCourseCourseId');
    StudentCourseFirstActivityDateTime = new DwItemAttribute('StudentCourse', 'StudentCourseFirstActivityDateTime');
    StudentCourseCompletedDateTime = new DwItemAttribute('StudentCourse', 'StudentCourseCompletedDateTime');
    StudentCourseIsEndorsed = new DwItemAttribute('StudentCourse', 'StudentCourseIsEndorsed');
    CourseCourseId = new DwItemAttribute('Course', 'CourseCourseId');
    CourseCourseName = new DwItemAttribute('Course', 'CourseCourseName');
    CourseCourseDescription = new DwItemAttribute('Course', 'CourseCourseDescription');
    CourseCourseStatusId = new DwItemAttribute('Course', 'CourseCourseStatusId');
    CourseImageDwroMediaMediaPath = new DwItemAttribute('dw_roMedia', 'CourseImageDwroMediaMediaPath');
    CourseEndorsementStudentCompletionDateTime = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentCompletionDateTime');
    CourseEndorsementStudentExpirationDateTime = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentExpirationDateTime');
    UserDetailFlightSchoolId = new DwItemAttribute('UserDetail', 'UserDetailFlightSchoolId');
    UserDetailUserDetailId = new DwItemAttribute('UserDetail', 'UserDetailUserDetailId');
}
export class StudentModuleProgressQueryMetaData implements DwItem2<StudentModuleProgressQueryEntity, StudentModuleProgressQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student-Module_Progress';

    constructor() {
        this.ItemDetail.itemId=134;
        this.ItemDetail.itemName='CourseModuleProgress';
        this.ItemDetail.keyName='CourseModuleProgressId';
    }

    CreateEntity(): StudentModuleProgressQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentModuleProgressQueryBuilder {
        return new DwQueryBuilderGen<StudentModuleProgressQueryEntity, StudentModuleProgressQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentModuleProgressQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentModuleProgressQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentModuleProgressQueryEntity, StudentModuleProgressQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentModuleProgressQueryMetaDataAttributes();
     Relations = new CourseModuleProgressMetaDataRelations();

}


export type StudentModuleProgressQueryBuilder = DwQueryBuilderGen<StudentModuleProgressQueryEntity, StudentModuleProgressQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations>


export class StudentModuleProgressQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseModuleProgress';
    // Attributes
    CourseModuleProgressId = new DwItemAttribute(this.i, 'CourseModuleProgressId');
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    LessonsCompleted = new DwItemAttribute(this.i, 'LessonsCompleted');
    LessonsCount = new DwItemAttribute(this.i, 'LessonsCount');
    StudentCourseId = new DwItemAttribute(this.i, 'StudentCourseId');
    LastActivityDateTime = new DwItemAttribute(this.i, 'LastActivityDateTime');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    FirstActivityDateTime = new DwItemAttribute(this.i, 'FirstActivityDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModuleProgressStatusId = new DwItemAttribute(this.i, 'ModuleProgressStatusId');
    CourseModuleModuleOrder = new DwItemAttribute('CourseModule', 'CourseModuleModuleOrder');
    CourseModuleModuleName = new DwItemAttribute('CourseModule', 'CourseModuleModuleName');
    ImageMediaPath = new DwItemAttribute('dw_roMedia', 'ImageMediaPath');
    CourseImageMediaPath = new DwItemAttribute('dw_roMedia', 'CourseImageMediaPath');
}
export class StudentLessonProgressQueryMetaData implements DwItem2<StudentLessonProgressQueryEntity, StudentLessonProgressQueryMetaDataAttributes,CourseLessonProgressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student-Lesson_Progress';

    constructor() {
        this.ItemDetail.itemId=135;
        this.ItemDetail.itemName='CourseLessonProgress';
        this.ItemDetail.keyName='CourseLessonProgressId';
    }

    CreateEntity(): StudentLessonProgressQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentLessonProgressQueryBuilder {
        return new DwQueryBuilderGen<StudentLessonProgressQueryEntity, StudentLessonProgressQueryMetaDataAttributes,CourseLessonProgressMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentLessonProgressQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentLessonProgressQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentLessonProgressQueryEntity, StudentLessonProgressQueryMetaDataAttributes,CourseLessonProgressMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentLessonProgressQueryMetaDataAttributes();
     Relations = new CourseLessonProgressMetaDataRelations();

}


export type StudentLessonProgressQueryBuilder = DwQueryBuilderGen<StudentLessonProgressQueryEntity, StudentLessonProgressQueryMetaDataAttributes,CourseLessonProgressMetaDataRelations>


export class StudentLessonProgressQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseLessonProgress';
    // Attributes
    CourseLessonProgressId = new DwItemAttribute(this.i, 'CourseLessonProgressId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    CourseModuleProgressId = new DwItemAttribute(this.i, 'CourseModuleProgressId');
    LessonComplete = new DwItemAttribute(this.i, 'LessonComplete');
    VideoFurthestTimeSeconds = new DwItemAttribute(this.i, 'VideoFurthestTimeSeconds');
    BestQuizResultId = new DwItemAttribute(this.i, 'BestQuizResultId');
    LastActivityDateTime = new DwItemAttribute(this.i, 'LastActivityDateTime');
    LessonNotes = new DwItemAttribute(this.i, 'LessonNotes');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    FirstActivityDateTime = new DwItemAttribute(this.i, 'FirstActivityDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    SessionCount = new DwItemAttribute(this.i, 'SessionCount');
    CourseLessonLessonName = new DwItemAttribute('CourseLesson', 'CourseLessonLessonName');
    CourseLessonLessonDescription = new DwItemAttribute('CourseLesson', 'CourseLessonLessonDescription');
    CourseLessonLessonTypeId = new DwItemAttribute('CourseLesson', 'CourseLessonLessonTypeId');
    CourseLessonLessonOrder = new DwItemAttribute('CourseLesson', 'CourseLessonLessonOrder');
    LessonImageDwroMediaMediaPath = new DwItemAttribute('dw_roMedia', 'LessonImageDwroMediaMediaPath');
    VideoThumbnailUrl = new DwItemAttribute('Video', 'VideoThumbnailUrl');
    BestQuizResultPercentageScore = new DwItemAttribute('QuizResult', 'BestQuizResultPercentageScore');
    BestQuizResultQuizResultStatusId = new DwItemAttribute('QuizResult', 'BestQuizResultQuizResultStatusId');
    CourseModuleProgressStudentCourseId = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressStudentCourseId');
    CourseModuleProgressCourseModuleId = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressCourseModuleId');
    StudentCourseCourseId = new DwItemAttribute('StudentCourse', 'StudentCourseCourseId');
    StudentCourseStudentId = new DwItemAttribute('StudentCourse', 'StudentCourseStudentId');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    CourseImageMediaPath = new DwItemAttribute('dw_roMedia', 'CourseImageMediaPath');
    ModuleImageMediaPath = new DwItemAttribute('dw_roMedia', 'ModuleImageMediaPath');
}
export class StudentCourseProgressByStudentCourseQueryMetaData implements DwItem2<StudentCourseProgressByStudentCourseQueryEntity, StudentCourseProgressByStudentCourseQueryMetaDataAttributes,StudentCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student-Course_Progress-by-student-course';

    constructor() {
        this.ItemDetail.itemId=165;
        this.ItemDetail.itemName='StudentCourse';
        this.ItemDetail.keyName='StudentCourseId';
    }

    CreateEntity(): StudentCourseProgressByStudentCourseQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentCourseProgressByStudentCourseQueryBuilder {
        return new DwQueryBuilderGen<StudentCourseProgressByStudentCourseQueryEntity, StudentCourseProgressByStudentCourseQueryMetaDataAttributes,StudentCourseMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentCourseProgressByStudentCourseQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentCourseProgressByStudentCourseQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentCourseProgressByStudentCourseQueryEntity, StudentCourseProgressByStudentCourseQueryMetaDataAttributes,StudentCourseMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentCourseProgressByStudentCourseQueryMetaDataAttributes();
     Relations = new StudentCourseMetaDataRelations();

}


export type StudentCourseProgressByStudentCourseQueryBuilder = DwQueryBuilderGen<StudentCourseProgressByStudentCourseQueryEntity, StudentCourseProgressByStudentCourseQueryMetaDataAttributes,StudentCourseMetaDataRelations>


export class StudentCourseProgressByStudentCourseQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'StudentCourse';
    // Attributes
    StudentCourseId = new DwItemAttribute(this.i, 'StudentCourseId');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    ModulesCompleted = new DwItemAttribute(this.i, 'ModulesCompleted');
    ModulesCount = new DwItemAttribute(this.i, 'ModulesCount');
    LastActivityDateTime = new DwItemAttribute(this.i, 'LastActivityDateTime');
    LessonsCompleted = new DwItemAttribute(this.i, 'LessonsCompleted');
    LessonCount = new DwItemAttribute(this.i, 'LessonCount');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    FirstActivityDateTime = new DwItemAttribute(this.i, 'FirstActivityDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    StudentStudentId = new DwItemAttribute('Student', 'StudentStudentId');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    StudentCreatedBy = new DwItemAttribute('Student', 'StudentCreatedBy');
    StudentCreatedDateTime = new DwItemAttribute('Student', 'StudentCreatedDateTime');
    StudentModifiedBy = new DwItemAttribute('Student', 'StudentModifiedBy');
    StudentModifiedDateTime = new DwItemAttribute('Student', 'StudentModifiedDateTime');
    UserDetailFlightSchoolId = new DwItemAttribute('UserDetail', 'UserDetailFlightSchoolId');
}
export class StudentModuleLessonActivityQueryMetaData implements DwItem2<StudentModuleLessonActivityQueryEntity, StudentModuleLessonActivityQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student-Module/Lesson_Activity';

    constructor() {
        this.ItemDetail.itemId=134;
        this.ItemDetail.itemName='CourseModuleProgress';
        this.ItemDetail.keyName='CourseModuleProgressId';
    }

    CreateEntity(): StudentModuleLessonActivityQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentModuleLessonActivityQueryBuilder {
        return new DwQueryBuilderGen<StudentModuleLessonActivityQueryEntity, StudentModuleLessonActivityQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentModuleLessonActivityQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentModuleLessonActivityQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentModuleLessonActivityQueryEntity, StudentModuleLessonActivityQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentModuleLessonActivityQueryMetaDataAttributes();
     Relations = new CourseModuleProgressMetaDataRelations();

}


export type StudentModuleLessonActivityQueryBuilder = DwQueryBuilderGen<StudentModuleLessonActivityQueryEntity, StudentModuleLessonActivityQueryMetaDataAttributes,CourseModuleProgressMetaDataRelations>


export class StudentModuleLessonActivityQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseModuleProgress';
    // Attributes
    CourseModuleId = new DwItemAttribute(this.i, 'CourseModuleId');
    CourseLessonProgressCourseLessonId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCourseLessonId');
    CourseLessonProgressLessonComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLessonComplete');
    CourseLessonProgressVideoFurthestTimeSeconds = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressVideoFurthestTimeSeconds');
    CourseLessonProgressBestQuizResultId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressBestQuizResultId');
    CourseLessonProgressLastActivityDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLastActivityDateTime');
    CourseLessonProgressPercentComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressPercentComplete');
    CourseLessonProgressTotalTimeSeconds = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressTotalTimeSeconds');
    CourseLessonProgressFirstActivityDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressFirstActivityDateTime');
    CourseLessonProgressCompletedDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCompletedDateTime');
    CourseLessonSessionCourseLessonSessionId = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionCourseLessonSessionId');
    CourseLessonSessionCourseLessonProgressId = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionCourseLessonProgressId');
    CourseLessonSessionLessonCompleted = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionLessonCompleted');
    CourseLessonSessionVideoId = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionVideoId');
    CourseLessonSessionVideoFurthestTimeSeconds = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionVideoFurthestTimeSeconds');
    CourseLessonSessionQuizResultId = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionQuizResultId');
    CourseLessonSessionSessionStartDateTime = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionSessionStartDateTime');
    CourseLessonSessionSessionEndDateTime = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionSessionEndDateTime');
    CourseLessonSessionTotalTimeSeconds = new DwItemAttribute('CourseLessonSession', 'CourseLessonSessionTotalTimeSeconds');
    QuizResultCompletedQuestions = new DwItemAttribute('QuizResult', 'QuizResultCompletedQuestions');
    QuizResultTotalQuestions = new DwItemAttribute('QuizResult', 'QuizResultTotalQuestions');
    QuizResultQuizScore = new DwItemAttribute('QuizResult', 'QuizResultQuizScore');
    QuizResultPerfectScore = new DwItemAttribute('QuizResult', 'QuizResultPerfectScore');
    QuizResultPercentageScore = new DwItemAttribute('QuizResult', 'QuizResultPercentageScore');
    QuizResultQuizResultStatusId = new DwItemAttribute('QuizResult', 'QuizResultQuizResultStatusId');
    QuizResultQuizId = new DwItemAttribute('QuizResult', 'QuizResultQuizId');
    QuizResultQuizResultId = new DwItemAttribute('QuizResult', 'QuizResultQuizResultId');
}
export class FlightSchoolGroupWithStudentCourseDetailQueryMetaData implements DwItem2<FlightSchoolGroupWithStudentCourseDetailQueryEntity, FlightSchoolGroupWithStudentCourseDetailQueryMetaDataAttributes,FlightSchoolGroupMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Flight_School_Group_with_StudentCourse_Detail';

    constructor() {
        this.ItemDetail.itemId=192;
        this.ItemDetail.itemName='FlightSchoolGroup';
        this.ItemDetail.keyName='FlightSchoolGroupId';
    }

    CreateEntity(): FlightSchoolGroupWithStudentCourseDetailQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): FlightSchoolGroupWithStudentCourseDetailQueryBuilder {
        return new DwQueryBuilderGen<FlightSchoolGroupWithStudentCourseDetailQueryEntity, FlightSchoolGroupWithStudentCourseDetailQueryMetaDataAttributes,FlightSchoolGroupMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<FlightSchoolGroupWithStudentCourseDetailQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: FlightSchoolGroupWithStudentCourseDetailQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<FlightSchoolGroupWithStudentCourseDetailQueryEntity, FlightSchoolGroupWithStudentCourseDetailQueryMetaDataAttributes,FlightSchoolGroupMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new FlightSchoolGroupWithStudentCourseDetailQueryMetaDataAttributes();
     Relations = new FlightSchoolGroupMetaDataRelations();

}


export type FlightSchoolGroupWithStudentCourseDetailQueryBuilder = DwQueryBuilderGen<FlightSchoolGroupWithStudentCourseDetailQueryEntity, FlightSchoolGroupWithStudentCourseDetailQueryMetaDataAttributes,FlightSchoolGroupMetaDataRelations>


export class FlightSchoolGroupWithStudentCourseDetailQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchoolGroup';
    // Attributes
    FlightSchoolGroupId = new DwItemAttribute(this.i, 'FlightSchoolGroupId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    GroupName = new DwItemAttribute(this.i, 'GroupName');
    FlightSchoolGroupStudentStudentId = new DwItemAttribute('FlightSchoolGroupStudent', 'FlightSchoolGroupStudentStudentId');
    FlightSchoolGroupStudentFlightSchoolGroupStudentId = new DwItemAttribute('FlightSchoolGroupStudent', 'FlightSchoolGroupStudentFlightSchoolGroupStudentId');
    StudentStudentId = new DwItemAttribute('Student', 'StudentStudentId');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    StudentCourseStudentCourseId = new DwItemAttribute('StudentCourse', 'StudentCourseStudentCourseId');
    StudentCourseCourseId = new DwItemAttribute('StudentCourse', 'StudentCourseCourseId');
    StudentCourseIsActive = new DwItemAttribute('StudentCourse', 'StudentCourseIsActive');
    UserDetailUserDetailId = new DwItemAttribute('UserDetail', 'UserDetailUserDetailId');
    UserDetailFlightSchoolId = new DwItemAttribute('UserDetail', 'UserDetailFlightSchoolId');
}
export class AssignmentStudentLessonSessonGridQueryMetaData implements DwItem2<AssignmentStudentLessonSessonGridQueryEntity, AssignmentStudentLessonSessonGridQueryMetaDataAttributes,AssignmentStudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Assignment_Student-Lesson_Sesson_Grid';

    constructor() {
        this.ItemDetail.itemId=191;
        this.ItemDetail.itemName='AssignmentStudent';
        this.ItemDetail.keyName='AssignmentStudentId';
    }

    CreateEntity(): AssignmentStudentLessonSessonGridQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AssignmentStudentLessonSessonGridQueryBuilder {
        return new DwQueryBuilderGen<AssignmentStudentLessonSessonGridQueryEntity, AssignmentStudentLessonSessonGridQueryMetaDataAttributes,AssignmentStudentMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AssignmentStudentLessonSessonGridQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AssignmentStudentLessonSessonGridQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AssignmentStudentLessonSessonGridQueryEntity, AssignmentStudentLessonSessonGridQueryMetaDataAttributes,AssignmentStudentMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AssignmentStudentLessonSessonGridQueryMetaDataAttributes();
     Relations = new AssignmentStudentMetaDataRelations();

}


export type AssignmentStudentLessonSessonGridQueryBuilder = DwQueryBuilderGen<AssignmentStudentLessonSessonGridQueryEntity, AssignmentStudentLessonSessonGridQueryMetaDataAttributes,AssignmentStudentMetaDataRelations>


export class AssignmentStudentLessonSessonGridQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AssignmentStudent';
    // Attributes
    AssignmentStudentId = new DwItemAttribute(this.i, 'AssignmentStudentId');
    AssignmentId = new DwItemAttribute(this.i, 'AssignmentId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    RoAssignmentStudentCourseLessonProgressStudentId = new DwItemAttribute('roAssignmentStudentCourseLessonProgress', 'RoAssignmentStudentCourseLessonProgressStudentId');
    CourseLessonProgressBestQuizResultId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressBestQuizResultId');
    CourseLessonProgressCompletedDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCompletedDateTime');
    CourseLessonProgressCourseLessonId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCourseLessonId');
    CourseLessonProgressCourseLessonProgressId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCourseLessonProgressId');
    CourseLessonProgressCourseModuleProgressId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCourseModuleProgressId');
    CourseLessonProgressCreatedBy = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCreatedBy');
    CourseLessonProgressCreatedDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCreatedDateTime');
    CourseLessonProgressFirstActivityDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressFirstActivityDateTime');
    CourseLessonProgressLastActivityDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLastActivityDateTime');
    CourseLessonProgressLessonComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLessonComplete');
    CourseLessonProgressLessonNotes = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLessonNotes');
    CourseLessonProgressModifiedBy = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressModifiedBy');
    CourseLessonProgressModifiedDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressModifiedDateTime');
    CourseLessonProgressPercentComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressPercentComplete');
    CourseLessonProgressTotalTimeSeconds = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressTotalTimeSeconds');
    CourseLessonProgressVideoFurthestTimeSeconds = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressVideoFurthestTimeSeconds');
    SessionCourseLessonSessionId = new DwItemAttribute('CourseLessonSession', 'SessionCourseLessonSessionId');
    SessionCourseLessonProgressId = new DwItemAttribute('CourseLessonSession', 'SessionCourseLessonProgressId');
    SessionLessonCompleted = new DwItemAttribute('CourseLessonSession', 'SessionLessonCompleted');
    SessionVideoId = new DwItemAttribute('CourseLessonSession', 'SessionVideoId');
    SessionVideoFurthestTimeSeconds = new DwItemAttribute('CourseLessonSession', 'SessionVideoFurthestTimeSeconds');
    SessionQuizResultId = new DwItemAttribute('CourseLessonSession', 'SessionQuizResultId');
    SessionSessionStartDateTime = new DwItemAttribute('CourseLessonSession', 'SessionSessionStartDateTime');
    SessionSessionEndDateTime = new DwItemAttribute('CourseLessonSession', 'SessionSessionEndDateTime');
    SessionTotalTimeSeconds = new DwItemAttribute('CourseLessonSession', 'SessionTotalTimeSeconds');
    SessionCreatedBy = new DwItemAttribute('CourseLessonSession', 'SessionCreatedBy');
    SessionCreatedDateTime = new DwItemAttribute('CourseLessonSession', 'SessionCreatedDateTime');
    SessionModifiedBy = new DwItemAttribute('CourseLessonSession', 'SessionModifiedBy');
    SessionModifiedDateTime = new DwItemAttribute('CourseLessonSession', 'SessionModifiedDateTime');
    QuizResultQuizResultId = new DwItemAttribute('QuizResult', 'QuizResultQuizResultId');
    QuizResultQuizId = new DwItemAttribute('QuizResult', 'QuizResultQuizId');
    QuizResultQuizScore = new DwItemAttribute('QuizResult', 'QuizResultQuizScore');
    QuizResultPercentageScore = new DwItemAttribute('QuizResult', 'QuizResultPercentageScore');
    QuizResultQuizResultStatusId = new DwItemAttribute('QuizResult', 'QuizResultQuizResultStatusId');
    CourseLessonLessonName = new DwItemAttribute('CourseLesson', 'CourseLessonLessonName');
    CourseLessonCourseModuleId = new DwItemAttribute('CourseLesson', 'CourseLessonCourseModuleId');
}
export class AssignmentLessonWithProgressForStudentQueryMetaData implements DwItem2<AssignmentLessonWithProgressForStudentQueryEntity, AssignmentLessonWithProgressForStudentQueryMetaDataAttributes,AssignmentCourseLessonMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Assignment_Lesson_with_Progress-For_Student';

    constructor() {
        this.ItemDetail.itemId=195;
        this.ItemDetail.itemName='AssignmentCourseLesson';
        this.ItemDetail.keyName='AssignmentCourseLessonId';
    }

    CreateEntity(): AssignmentLessonWithProgressForStudentQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AssignmentLessonWithProgressForStudentQueryBuilder {
        return new DwQueryBuilderGen<AssignmentLessonWithProgressForStudentQueryEntity, AssignmentLessonWithProgressForStudentQueryMetaDataAttributes,AssignmentCourseLessonMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AssignmentLessonWithProgressForStudentQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AssignmentLessonWithProgressForStudentQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AssignmentLessonWithProgressForStudentQueryEntity, AssignmentLessonWithProgressForStudentQueryMetaDataAttributes,AssignmentCourseLessonMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AssignmentLessonWithProgressForStudentQueryMetaDataAttributes();
     Relations = new AssignmentCourseLessonMetaDataRelations();

}


export type AssignmentLessonWithProgressForStudentQueryBuilder = DwQueryBuilderGen<AssignmentLessonWithProgressForStudentQueryEntity, AssignmentLessonWithProgressForStudentQueryMetaDataAttributes,AssignmentCourseLessonMetaDataRelations>


export class AssignmentLessonWithProgressForStudentQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AssignmentCourseLesson';
    // Attributes
    AssignmentCourseLessonId = new DwItemAttribute(this.i, 'AssignmentCourseLessonId');
    AssignmentId = new DwItemAttribute(this.i, 'AssignmentId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    AssignmentAssignmentDescription = new DwItemAttribute('Assignment', 'AssignmentAssignmentDescription');
    AssignmentAssignmentStatusId = new DwItemAttribute('Assignment', 'AssignmentAssignmentStatusId');
    AssignmentAssignedDateTime = new DwItemAttribute('Assignment', 'AssignmentAssignedDateTime');
    AssignmentDueDateTime = new DwItemAttribute('Assignment', 'AssignmentDueDateTime');
    AssignmentCourseId = new DwItemAttribute('Assignment', 'AssignmentCourseId');
    AssignmentStudentAssignmentStudentId = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentAssignmentStudentId');
    AssignmentStudentStudentId = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentStudentId');
    AssignmentStudentPercentComplete = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentPercentComplete');
    AssignmentStudentCompletedDateTime = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentCompletedDateTime');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    CourseLessonLessonName = new DwItemAttribute('CourseLesson', 'CourseLessonLessonName');
    CourseLessonCourseModuleId = new DwItemAttribute('CourseLesson', 'CourseLessonCourseModuleId');
    CourseLessonLessonTypeId = new DwItemAttribute('CourseLesson', 'CourseLessonLessonTypeId');
    CourseLessonLessonOrder = new DwItemAttribute('CourseLesson', 'CourseLessonLessonOrder');
    CourseLessonProgressCourseLessonId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressCourseLessonId');
    CourseLessonProgressLessonComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLessonComplete');
    CourseLessonProgressVideoFurthestTimeSeconds = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressVideoFurthestTimeSeconds');
    CourseLessonProgressBestQuizResultId = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressBestQuizResultId');
    CourseLessonProgressPercentComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressPercentComplete');
    CourseLessonProgressLastActivityDateTime = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLastActivityDateTime');
    CourseModuleProgressCourseModuleProgressId = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressCourseModuleProgressId');
    CourseModuleProgressCourseModuleId = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressCourseModuleId');
    CourseModuleProgressStudentCourseId = new DwItemAttribute('CourseModuleProgress', 'CourseModuleProgressStudentCourseId');
    StudentCourseStudentId = new DwItemAttribute('StudentCourse', 'StudentCourseStudentId');
    StudentFromProgressUserId = new DwItemAttribute('Student', 'StudentFromProgressUserId');
    CourseModuleModuleName = new DwItemAttribute('CourseModule', 'CourseModuleModuleName');
    CourseModuleModuleOrder = new DwItemAttribute('CourseModule', 'CourseModuleModuleOrder');
    CourseModuleCourseModuleId = new DwItemAttribute('CourseModule', 'CourseModuleCourseModuleId');
    CourseModuleCourseId = new DwItemAttribute('CourseModule', 'CourseModuleCourseId');
}
export class AssignmentByStudentQueryMetaData implements DwItem2<AssignmentByStudentQueryEntity, AssignmentByStudentQueryMetaDataAttributes,AssignmentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Assignment-By_Student';

    constructor() {
        this.ItemDetail.itemId=188;
        this.ItemDetail.itemName='Assignment';
        this.ItemDetail.keyName='AssignmentId';
    }

    CreateEntity(): AssignmentByStudentQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AssignmentByStudentQueryBuilder {
        return new DwQueryBuilderGen<AssignmentByStudentQueryEntity, AssignmentByStudentQueryMetaDataAttributes,AssignmentMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AssignmentByStudentQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AssignmentByStudentQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AssignmentByStudentQueryEntity, AssignmentByStudentQueryMetaDataAttributes,AssignmentMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AssignmentByStudentQueryMetaDataAttributes();
     Relations = new AssignmentMetaDataRelations();

}


export type AssignmentByStudentQueryBuilder = DwQueryBuilderGen<AssignmentByStudentQueryEntity, AssignmentByStudentQueryMetaDataAttributes,AssignmentMetaDataRelations>


export class AssignmentByStudentQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Assignment';
    // Attributes
    AssignmentId = new DwItemAttribute(this.i, 'AssignmentId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    AssignmentDescription = new DwItemAttribute(this.i, 'AssignmentDescription');
    AssignmentTypeId = new DwItemAttribute(this.i, 'AssignmentTypeId');
    AssignmentStatusId = new DwItemAttribute(this.i, 'AssignmentStatusId');
    AssignmentLinkUrl = new DwItemAttribute(this.i, 'AssignmentLinkUrl');
    MediaId = new DwItemAttribute(this.i, 'MediaId');
    DueDateTime = new DwItemAttribute(this.i, 'DueDateTime');
    FlightSchoolGroupId = new DwItemAttribute(this.i, 'FlightSchoolGroupId');
    AssignedDateTime = new DwItemAttribute(this.i, 'AssignedDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    ResetLessonProgress = new DwItemAttribute(this.i, 'ResetLessonProgress');
    UseGroupAssignment = new DwItemAttribute(this.i, 'UseGroupAssignment');
    InstructorId = new DwItemAttribute(this.i, 'InstructorId');
    AssignmentStudentAssignmentStudentId = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentAssignmentStudentId');
    AssignmentStudentPercentComplete = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentPercentComplete');
    AssignmentStudentCompletedDateTime = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentCompletedDateTime');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    StudentStudentId = new DwItemAttribute('Student', 'StudentStudentId');
}
export class AssignmentStudentLessonProgressListQueryMetaData implements DwItem2<AssignmentStudentLessonProgressListQueryEntity, AssignmentStudentLessonProgressListQueryMetaDataAttributes,RoAssignmentStudentCourseLessonProgressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Assignment_Student-Lesson_Progress-_List';

    constructor() {
        this.ItemDetail.itemId=196;
        this.ItemDetail.itemName='roAssignmentStudentCourseLessonProgress';
        this.ItemDetail.keyName='CourseLessonProgressId';
    }

    CreateEntity(): AssignmentStudentLessonProgressListQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AssignmentStudentLessonProgressListQueryBuilder {
        return new DwQueryBuilderGen<AssignmentStudentLessonProgressListQueryEntity, AssignmentStudentLessonProgressListQueryMetaDataAttributes,RoAssignmentStudentCourseLessonProgressMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AssignmentStudentLessonProgressListQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AssignmentStudentLessonProgressListQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AssignmentStudentLessonProgressListQueryEntity, AssignmentStudentLessonProgressListQueryMetaDataAttributes,RoAssignmentStudentCourseLessonProgressMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AssignmentStudentLessonProgressListQueryMetaDataAttributes();
     Relations = new RoAssignmentStudentCourseLessonProgressMetaDataRelations();

}


export type AssignmentStudentLessonProgressListQueryBuilder = DwQueryBuilderGen<AssignmentStudentLessonProgressListQueryEntity, AssignmentStudentLessonProgressListQueryMetaDataAttributes,RoAssignmentStudentCourseLessonProgressMetaDataRelations>


export class AssignmentStudentLessonProgressListQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roAssignmentStudentCourseLessonProgress';
    // Attributes
    AssignmentStudentId = new DwItemAttribute(this.i, 'AssignmentStudentId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    CourseLessonId = new DwItemAttribute(this.i, 'CourseLessonId');
    CourseLessonProgressId = new DwItemAttribute(this.i, 'CourseLessonProgressId');
    AssignmentStudentAssignmentStudentId = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentAssignmentStudentId');
    AssignmentStudentAssignmentId = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentAssignmentId');
    AssignmentStudentStudentId = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentStudentId');
    AssignmentStudentPercentComplete = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentPercentComplete');
    AssignmentStudentCompletedDateTime = new DwItemAttribute('AssignmentStudent', 'AssignmentStudentCompletedDateTime');
    CourseLessonProgressLessonComplete = new DwItemAttribute('CourseLessonProgress', 'CourseLessonProgressLessonComplete');
    CourseLessonCourseLessonId = new DwItemAttribute('CourseLesson', 'CourseLessonCourseLessonId');
    CourseLessonCourseModuleId = new DwItemAttribute('CourseLesson', 'CourseLessonCourseModuleId');
    CourseLessonLessonName = new DwItemAttribute('CourseLesson', 'CourseLessonLessonName');
    CourseLessonLessonDescription = new DwItemAttribute('CourseLesson', 'CourseLessonLessonDescription');
    CourseLessonLessonTypeId = new DwItemAttribute('CourseLesson', 'CourseLessonLessonTypeId');
    CourseLessonVideoId = new DwItemAttribute('CourseLesson', 'CourseLessonVideoId');
    CourseLessonQuizId = new DwItemAttribute('CourseLesson', 'CourseLessonQuizId');
    CourseLessonLessonOrder = new DwItemAttribute('CourseLesson', 'CourseLessonLessonOrder');
    LessonImageDwroMediaMediaPath = new DwItemAttribute('dw_roMedia', 'LessonImageDwroMediaMediaPath');
}
export class StudentByInstructorQueryMetaData implements DwItem2<StudentByInstructorQueryEntity, StudentByInstructorQueryMetaDataAttributes,StudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student_By_Instructor';

    constructor() {
        this.ItemDetail.itemId=144;
        this.ItemDetail.itemName='Student';
        this.ItemDetail.keyName='StudentId';
    }

    CreateEntity(): StudentByInstructorQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentByInstructorQueryBuilder {
        return new DwQueryBuilderGen<StudentByInstructorQueryEntity, StudentByInstructorQueryMetaDataAttributes,StudentMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentByInstructorQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentByInstructorQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentByInstructorQueryEntity, StudentByInstructorQueryMetaDataAttributes,StudentMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentByInstructorQueryMetaDataAttributes();
     Relations = new StudentMetaDataRelations();

}


export type StudentByInstructorQueryBuilder = DwQueryBuilderGen<StudentByInstructorQueryEntity, StudentByInstructorQueryMetaDataAttributes,StudentMetaDataRelations>


export class StudentByInstructorQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Student';
    // Attributes
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    DwUserFirstName = new DwItemAttribute('dw_User', 'DwUserFirstName');
    DwUserLastName = new DwItemAttribute('dw_User', 'DwUserLastName');
    DwUserEmail = new DwItemAttribute('dw_User', 'DwUserEmail');
    DwroMediaMediaPath = new DwItemAttribute('dw_roMedia', 'DwroMediaMediaPath');
    UserDetailUserDetailId = new DwItemAttribute('UserDetail', 'UserDetailUserDetailId');
    UserDetailFlightSchoolId = new DwItemAttribute('UserDetail', 'UserDetailFlightSchoolId');
    InstructorStudentInstructorId = new DwItemAttribute('InstructorStudent', 'InstructorStudentInstructorId');
    InstructorStudentInstructorStudentId = new DwItemAttribute('InstructorStudent', 'InstructorStudentInstructorStudentId');
    InstructorInstructorId = new DwItemAttribute('Instructor', 'InstructorInstructorId');
    InstructorUserId = new DwItemAttribute('Instructor', 'InstructorUserId');
}
export class StudentBySchoolQueryMetaData implements DwItem2<StudentBySchoolQueryEntity, StudentBySchoolQueryMetaDataAttributes,StudentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student-By_School';

    constructor() {
        this.ItemDetail.itemId=144;
        this.ItemDetail.itemName='Student';
        this.ItemDetail.keyName='StudentId';
    }

    CreateEntity(): StudentBySchoolQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentBySchoolQueryBuilder {
        return new DwQueryBuilderGen<StudentBySchoolQueryEntity, StudentBySchoolQueryMetaDataAttributes,StudentMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentBySchoolQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentBySchoolQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentBySchoolQueryEntity, StudentBySchoolQueryMetaDataAttributes,StudentMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentBySchoolQueryMetaDataAttributes();
     Relations = new StudentMetaDataRelations();

}


export type StudentBySchoolQueryBuilder = DwQueryBuilderGen<StudentBySchoolQueryEntity, StudentBySchoolQueryMetaDataAttributes,StudentMetaDataRelations>


export class StudentBySchoolQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Student';
    // Attributes
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    DwUserFirstName = new DwItemAttribute('dw_User', 'DwUserFirstName');
    DwUserLastName = new DwItemAttribute('dw_User', 'DwUserLastName');
    DwroMediaMediaPath = new DwItemAttribute('dw_roMedia', 'DwroMediaMediaPath');
    UserDetailUserDetailId = new DwItemAttribute('UserDetail', 'UserDetailUserDetailId');
    UserDetailFlightSchoolId = new DwItemAttribute('UserDetail', 'UserDetailFlightSchoolId');
}
export class FlightSchoolForMySchoolContextQueryMetaData implements DwItem2<FlightSchoolForMySchoolContextQueryEntity, FlightSchoolForMySchoolContextQueryMetaDataAttributes,FlightSchoolMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Flight_School-for_My_School_Context';

    constructor() {
        this.ItemDetail.itemId=176;
        this.ItemDetail.itemName='FlightSchool';
        this.ItemDetail.keyName='FlightSchoolId';
    }

    CreateEntity(): FlightSchoolForMySchoolContextQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): FlightSchoolForMySchoolContextQueryBuilder {
        return new DwQueryBuilderGen<FlightSchoolForMySchoolContextQueryEntity, FlightSchoolForMySchoolContextQueryMetaDataAttributes,FlightSchoolMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<FlightSchoolForMySchoolContextQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: FlightSchoolForMySchoolContextQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<FlightSchoolForMySchoolContextQueryEntity, FlightSchoolForMySchoolContextQueryMetaDataAttributes,FlightSchoolMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new FlightSchoolForMySchoolContextQueryMetaDataAttributes();
     Relations = new FlightSchoolMetaDataRelations();

}


export type FlightSchoolForMySchoolContextQueryBuilder = DwQueryBuilderGen<FlightSchoolForMySchoolContextQueryEntity, FlightSchoolForMySchoolContextQueryMetaDataAttributes,FlightSchoolMetaDataRelations>


export class FlightSchoolForMySchoolContextQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchool';
    // Attributes
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    FlightSchoolName = new DwItemAttribute(this.i, 'FlightSchoolName');
    FlightSchoolStatusId = new DwItemAttribute(this.i, 'FlightSchoolStatusId');
    NumberOfAircraft = new DwItemAttribute(this.i, 'NumberOfAircraft');
    Location = new DwItemAttribute(this.i, 'Location');
    NumberOfStudents = new DwItemAttribute(this.i, 'NumberOfStudents');
    PercentDiscount = new DwItemAttribute(this.i, 'PercentDiscount');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    IsDefaultFlightSchool = new DwItemAttribute(this.i, 'IsDefaultFlightSchool');
    FlightSchoolBrandingId = new DwItemAttribute(this.i, 'FlightSchoolBrandingId');
}
export class StudentCourseForContextQueryMetaData implements DwItem2<StudentCourseForContextQueryEntity, StudentCourseForContextQueryMetaDataAttributes,StudentCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student_Course-For_Context';

    constructor() {
        this.ItemDetail.itemId=165;
        this.ItemDetail.itemName='StudentCourse';
        this.ItemDetail.keyName='StudentCourseId';
    }

    CreateEntity(): StudentCourseForContextQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentCourseForContextQueryBuilder {
        return new DwQueryBuilderGen<StudentCourseForContextQueryEntity, StudentCourseForContextQueryMetaDataAttributes,StudentCourseMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentCourseForContextQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentCourseForContextQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentCourseForContextQueryEntity, StudentCourseForContextQueryMetaDataAttributes,StudentCourseMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentCourseForContextQueryMetaDataAttributes();
     Relations = new StudentCourseMetaDataRelations();

}


export type StudentCourseForContextQueryBuilder = DwQueryBuilderGen<StudentCourseForContextQueryEntity, StudentCourseForContextQueryMetaDataAttributes,StudentCourseMetaDataRelations>


export class StudentCourseForContextQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'StudentCourse';
    // Attributes
    StudentCourseId = new DwItemAttribute(this.i, 'StudentCourseId');
    StudentId = new DwItemAttribute(this.i, 'StudentId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    PercentComplete = new DwItemAttribute(this.i, 'PercentComplete');
    ModulesCompleted = new DwItemAttribute(this.i, 'ModulesCompleted');
    ModulesCount = new DwItemAttribute(this.i, 'ModulesCount');
    LastActivityDateTime = new DwItemAttribute(this.i, 'LastActivityDateTime');
    LessonsCompleted = new DwItemAttribute(this.i, 'LessonsCompleted');
    LessonCount = new DwItemAttribute(this.i, 'LessonCount');
    TotalTimeSeconds = new DwItemAttribute(this.i, 'TotalTimeSeconds');
    FirstActivityDateTime = new DwItemAttribute(this.i, 'FirstActivityDateTime');
    CompletedDateTime = new DwItemAttribute(this.i, 'CompletedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    StudentCourseStatusId = new DwItemAttribute(this.i, 'StudentCourseStatusId');
    IsEndorsed = new DwItemAttribute(this.i, 'IsEndorsed');
    CourseEndorsementStudentCourseEndorsementStudentId = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentCourseEndorsementStudentId');
    CourseEndorsementStudentCourseEndorsementId = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentCourseEndorsementId');
    CourseEndorsementStudentStudentCourseId = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentStudentCourseId');
    CourseEndorsementStudentCompletionDateTime = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentCompletionDateTime');
    CourseEndorsementStudentExpirationDateTime = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentExpirationDateTime');
    CourseEndorsementStudentEndorsementPDFMediaId = new DwItemAttribute('CourseEndorsementStudent', 'CourseEndorsementStudentEndorsementPDFMediaId');
    StudentUserId = new DwItemAttribute('Student', 'StudentUserId');
    StudentStudentId = new DwItemAttribute('Student', 'StudentStudentId');
}
export class JoinRequestDetailsGridQueryMetaData implements DwItem2<JoinRequestDetailsGridQueryEntity, JoinRequestDetailsGridQueryMetaDataAttributes,JoinRequestMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Join_Request-Details-Grid';

    constructor() {
        this.ItemDetail.itemId=198;
        this.ItemDetail.itemName='JoinRequest';
        this.ItemDetail.keyName='JoinRequestId';
    }

    CreateEntity(): JoinRequestDetailsGridQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): JoinRequestDetailsGridQueryBuilder {
        return new DwQueryBuilderGen<JoinRequestDetailsGridQueryEntity, JoinRequestDetailsGridQueryMetaDataAttributes,JoinRequestMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<JoinRequestDetailsGridQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: JoinRequestDetailsGridQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<JoinRequestDetailsGridQueryEntity, JoinRequestDetailsGridQueryMetaDataAttributes,JoinRequestMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new JoinRequestDetailsGridQueryMetaDataAttributes();
     Relations = new JoinRequestMetaDataRelations();

}


export type JoinRequestDetailsGridQueryBuilder = DwQueryBuilderGen<JoinRequestDetailsGridQueryEntity, JoinRequestDetailsGridQueryMetaDataAttributes,JoinRequestMetaDataRelations>


export class JoinRequestDetailsGridQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'JoinRequest';
    // Attributes
    JoinRequestId = new DwItemAttribute(this.i, 'JoinRequestId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    JoinRequestTypeId = new DwItemAttribute(this.i, 'JoinRequestTypeId');
    MessageHtml = new DwItemAttribute(this.i, 'MessageHtml');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    UsePackage = new DwItemAttribute(this.i, 'UsePackage');
    CoursePackageId = new DwItemAttribute(this.i, 'CoursePackageId');
    JoinRequestStatusId = new DwItemAttribute(this.i, 'JoinRequestStatusId');
    AssignToGroup = new DwItemAttribute(this.i, 'AssignToGroup');
    FlightSchoolGroupId = new DwItemAttribute(this.i, 'FlightSchoolGroupId');
    JoinRequestEmailEmailAddress = new DwItemAttribute('JoinRequestEmail', 'JoinRequestEmailEmailAddress');
    JoinRequestEmailAccepted = new DwItemAttribute('JoinRequestEmail', 'JoinRequestEmailAccepted');
    JoinRequestEmailUserId = new DwItemAttribute('JoinRequestEmail', 'JoinRequestEmailUserId');
    JoinRequestEmailAcceptedDateTime = new DwItemAttribute('JoinRequestEmail', 'JoinRequestEmailAcceptedDateTime');
    JoinRequestEmailLastResendRequestDateTime = new DwItemAttribute('JoinRequestEmail', 'JoinRequestEmailLastResendRequestDateTime');
    JoinRequestEmailJoinRequestEmailId = new DwItemAttribute('JoinRequestEmail', 'JoinRequestEmailJoinRequestEmailId');
    JoinRequestEmailJoinRequestId = new DwItemAttribute('JoinRequestEmail', 'JoinRequestEmailJoinRequestId');
}


// Generated Query Classes for MetaData Service

class MetaDataQueries {
    CourseListForStudent : CourseListForStudentQueryMetaData = new CourseListForStudentQueryMetaData();
    ModuleListForStudent : ModuleListForStudentQueryMetaData = new ModuleListForStudentQueryMetaData();
    LessonListForStudent : LessonListForStudentQueryMetaData = new LessonListForStudentQueryMetaData();
    StudentCourseProgress : StudentCourseProgressQueryMetaData = new StudentCourseProgressQueryMetaData();
    StudentModuleProgress : StudentModuleProgressQueryMetaData = new StudentModuleProgressQueryMetaData();
    StudentLessonProgress : StudentLessonProgressQueryMetaData = new StudentLessonProgressQueryMetaData();
    StudentCourseProgressByStudentCourse : StudentCourseProgressByStudentCourseQueryMetaData = new StudentCourseProgressByStudentCourseQueryMetaData();
    StudentModuleLessonActivity : StudentModuleLessonActivityQueryMetaData = new StudentModuleLessonActivityQueryMetaData();
    FlightSchoolGroupWithStudentCourseDetail : FlightSchoolGroupWithStudentCourseDetailQueryMetaData = new FlightSchoolGroupWithStudentCourseDetailQueryMetaData();
    AssignmentStudentLessonSessonGrid : AssignmentStudentLessonSessonGridQueryMetaData = new AssignmentStudentLessonSessonGridQueryMetaData();
    AssignmentLessonWithProgressForStudent : AssignmentLessonWithProgressForStudentQueryMetaData = new AssignmentLessonWithProgressForStudentQueryMetaData();
    AssignmentByStudent : AssignmentByStudentQueryMetaData = new AssignmentByStudentQueryMetaData();
    AssignmentStudentLessonProgressList : AssignmentStudentLessonProgressListQueryMetaData = new AssignmentStudentLessonProgressListQueryMetaData();
    StudentByInstructor : StudentByInstructorQueryMetaData = new StudentByInstructorQueryMetaData();
    StudentBySchool : StudentBySchoolQueryMetaData = new StudentBySchoolQueryMetaData();
    FlightSchoolForMySchoolContext : FlightSchoolForMySchoolContextQueryMetaData = new FlightSchoolForMySchoolContextQueryMetaData();
    StudentCourseForContext : StudentCourseForContextQueryMetaData = new StudentCourseForContextQueryMetaData();
    JoinRequestDetailsGrid : JoinRequestDetailsGridQueryMetaData = new JoinRequestDetailsGridQueryMetaData();
}

export class AppMetaData {

    static AircraftType : AircraftTypeMetaData = new AircraftTypeMetaData();
    static Assignment : AssignmentMetaData = new AssignmentMetaData();
    static AssignmentCourseLesson : AssignmentCourseLessonMetaData = new AssignmentCourseLessonMetaData();
    static AssignmentStatus : AssignmentStatusMetaData = new AssignmentStatusMetaData();
    static AssignmentStudent : AssignmentStudentMetaData = new AssignmentStudentMetaData();
    static AssignmentType : AssignmentTypeMetaData = new AssignmentTypeMetaData();
    static Course : CourseMetaData = new CourseMetaData();
    static CourseEndorsement : CourseEndorsementMetaData = new CourseEndorsementMetaData();
    static CourseEndorsementLesson : CourseEndorsementLessonMetaData = new CourseEndorsementLessonMetaData();
    static CourseEndorsementStudent : CourseEndorsementStudentMetaData = new CourseEndorsementStudentMetaData();
    static CourseEndorsementTemplate : CourseEndorsementTemplateMetaData = new CourseEndorsementTemplateMetaData();
    static CourseEndorsementTemplateField : CourseEndorsementTemplateFieldMetaData = new CourseEndorsementTemplateFieldMetaData();
    static CourseLesson : CourseLessonMetaData = new CourseLessonMetaData();
    static CourseLessonFlag : CourseLessonFlagMetaData = new CourseLessonFlagMetaData();
    static CourseLessonFlagStatus : CourseLessonFlagStatusMetaData = new CourseLessonFlagStatusMetaData();
    static CourseLessonFlagType : CourseLessonFlagTypeMetaData = new CourseLessonFlagTypeMetaData();
    static CourseLessonProgress : CourseLessonProgressMetaData = new CourseLessonProgressMetaData();
    static CourseLessonSession : CourseLessonSessionMetaData = new CourseLessonSessionMetaData();
    static CourseModificationHistory : CourseModificationHistoryMetaData = new CourseModificationHistoryMetaData();
    static CourseModule : CourseModuleMetaData = new CourseModuleMetaData();
    static CourseModuleProgress : CourseModuleProgressMetaData = new CourseModuleProgressMetaData();
    static CourseType : CourseTypeMetaData = new CourseTypeMetaData();
    static FlightSchool : FlightSchoolMetaData = new FlightSchoolMetaData();
    static FlightSchoolBranding : FlightSchoolBrandingMetaData = new FlightSchoolBrandingMetaData();
    static FlightSchoolCourse : FlightSchoolCourseMetaData = new FlightSchoolCourseMetaData();
    static FlightSchoolGroup : FlightSchoolGroupMetaData = new FlightSchoolGroupMetaData();
    static FlightSchoolGroupStudent : FlightSchoolGroupStudentMetaData = new FlightSchoolGroupStudentMetaData();
    static FlightSchoolInstructor : FlightSchoolInstructorMetaData = new FlightSchoolInstructorMetaData();
    static FlightSchoolPackage : FlightSchoolPackageMetaData = new FlightSchoolPackageMetaData();
    static FlightSchoolStatus : FlightSchoolStatusMetaData = new FlightSchoolStatusMetaData();
    static FlightSchoolStudentOld : FlightSchoolStudentOldMetaData = new FlightSchoolStudentOldMetaData();
    static Instructor : InstructorMetaData = new InstructorMetaData();
    static InstructorStatus : InstructorStatusMetaData = new InstructorStatusMetaData();
    static InstructorStudent : InstructorStudentMetaData = new InstructorStudentMetaData();
    static JoinRequest : JoinRequestMetaData = new JoinRequestMetaData();
    static JoinRequestCourse : JoinRequestCourseMetaData = new JoinRequestCourseMetaData();
    static JoinRequestEmail : JoinRequestEmailMetaData = new JoinRequestEmailMetaData();
    static JoinRequestStatus : JoinRequestStatusMetaData = new JoinRequestStatusMetaData();
    static JoinRequestType : JoinRequestTypeMetaData = new JoinRequestTypeMetaData();
    static JoinRequestTypeRole : JoinRequestTypeRoleMetaData = new JoinRequestTypeRoleMetaData();
    static LessonType : LessonTypeMetaData = new LessonTypeMetaData();
    static DwMedia : DwMediaMetaData = new DwMediaMetaData();
    static DwMediaGroup : DwMediaGroupMetaData = new DwMediaGroupMetaData();
    static Package : PackageMetaData = new PackageMetaData();
    static PackageCourse : PackageCourseMetaData = new PackageCourseMetaData();
    static PaymentMethod : PaymentMethodMetaData = new PaymentMethodMetaData();
    static ProcessorConfig : ProcessorConfigMetaData = new ProcessorConfigMetaData();
    static ProgramType : ProgramTypeMetaData = new ProgramTypeMetaData();
    static Question : QuestionMetaData = new QuestionMetaData();
    static QuestionNotUsed : QuestionNotUsedMetaData = new QuestionNotUsedMetaData();
    static QuestionAnswer : QuestionAnswerMetaData = new QuestionAnswerMetaData();
    static QuestionImportCharacterConversion : QuestionImportCharacterConversionMetaData = new QuestionImportCharacterConversionMetaData();
    static QuestionStatus : QuestionStatusMetaData = new QuestionStatusMetaData();
    static QuestionTag : QuestionTagMetaData = new QuestionTagMetaData();
    static QuestionTagLink : QuestionTagLinkMetaData = new QuestionTagLinkMetaData();
    static QuestionType : QuestionTypeMetaData = new QuestionTypeMetaData();
    static Quiz : QuizMetaData = new QuizMetaData();
    static QuizOutcome : QuizOutcomeMetaData = new QuizOutcomeMetaData();
    static QuizQuestionAnswerNotUsed : QuizQuestionAnswerNotUsedMetaData = new QuizQuestionAnswerNotUsedMetaData();
    static QuizQuestionLink : QuizQuestionLinkMetaData = new QuizQuestionLinkMetaData();
    static QuizReferenceQuizLink : QuizReferenceQuizLinkMetaData = new QuizReferenceQuizLinkMetaData();
    static QuizResult : QuizResultMetaData = new QuizResultMetaData();
    static QuizResultQuestion : QuizResultQuestionMetaData = new QuizResultQuestionMetaData();
    static QuizResultStatus : QuizResultStatusMetaData = new QuizResultStatusMetaData();
    static QuizType : QuizTypeMetaData = new QuizTypeMetaData();
    static ReferenceImage : ReferenceImageMetaData = new ReferenceImageMetaData();
    static ReferenceStatus : ReferenceStatusMetaData = new ReferenceStatusMetaData();
    static RoAssignmentStudentCompletedDetails : RoAssignmentStudentCompletedDetailsMetaData = new RoAssignmentStudentCompletedDetailsMetaData();
    static RoAssignmentStudentCourseLessonProgress : RoAssignmentStudentCourseLessonProgressMetaData = new RoAssignmentStudentCourseLessonProgressMetaData();
    static RoFlightSchoolCourseUnique : RoFlightSchoolCourseUniqueMetaData = new RoFlightSchoolCourseUniqueMetaData();
    static DwRoMedia : DwRoMediaMetaData = new DwRoMediaMetaData();
    static RoModuleLessonMaxOrder : RoModuleLessonMaxOrderMetaData = new RoModuleLessonMaxOrderMetaData();
    static RoUserRoleDetail : RoUserRoleDetailMetaData = new RoUserRoleDetailMetaData();
    static StandardColorSet : StandardColorSetMetaData = new StandardColorSetMetaData();
    static Student : StudentMetaData = new StudentMetaData();
    static StudentCourse : StudentCourseMetaData = new StudentCourseMetaData();
    static SubdomainSetupStatus : SubdomainSetupStatusMetaData = new SubdomainSetupStatusMetaData();
    static SubdomainType : SubdomainTypeMetaData = new SubdomainTypeMetaData();
    static Subscription : SubscriptionMetaData = new SubscriptionMetaData();
    static SubscriptionRole : SubscriptionRoleMetaData = new SubscriptionRoleMetaData();
    static SubscriptionRoleTracking : SubscriptionRoleTrackingMetaData = new SubscriptionRoleTrackingMetaData();
    static SubscriptionStatus : SubscriptionStatusMetaData = new SubscriptionStatusMetaData();
    static SubscriptionTier : SubscriptionTierMetaData = new SubscriptionTierMetaData();
    static SubscriptionTierRole : SubscriptionTierRoleMetaData = new SubscriptionTierRoleMetaData();
    static SubscriptionTierStatus : SubscriptionTierStatusMetaData = new SubscriptionTierStatusMetaData();
    static TemplateFieldAlignment : TemplateFieldAlignmentMetaData = new TemplateFieldAlignmentMetaData();
    static TemplateFieldType : TemplateFieldTypeMetaData = new TemplateFieldTypeMetaData();
    static TemplateFontStyle : TemplateFontStyleMetaData = new TemplateFontStyleMetaData();
    static DwTheme : DwThemeMetaData = new DwThemeMetaData();
    static DwThemeProperty : DwThemePropertyMetaData = new DwThemePropertyMetaData();
    static DwThemePropertyDefinition : DwThemePropertyDefinitionMetaData = new DwThemePropertyDefinitionMetaData();
    static DwThemePropertyType : DwThemePropertyTypeMetaData = new DwThemePropertyTypeMetaData();
    static DwUser : DwUserMetaData = new DwUserMetaData();
    static UserDetail : UserDetailMetaData = new UserDetailMetaData();
    static DwUserRole : DwUserRoleMetaData = new DwUserRoleMetaData();
    static UserSubscriptionRole : UserSubscriptionRoleMetaData = new UserSubscriptionRoleMetaData();
    static DwUserType : DwUserTypeMetaData = new DwUserTypeMetaData();
    static Video : VideoMetaData = new VideoMetaData();
    static VideoHistory : VideoHistoryMetaData = new VideoHistoryMetaData();
    static VideoLibrary : VideoLibraryMetaData = new VideoLibraryMetaData();
    static VideoStatus : VideoStatusMetaData = new VideoStatusMetaData();
    static VideoStreamingProvider : VideoStreamingProviderMetaData = new VideoStreamingProviderMetaData();
    static VideoTagLink : VideoTagLinkMetaData = new VideoTagLinkMetaData();
    static Queries = new MetaDataQueries();
}
// Generated Entity Interfaces
export interface AircraftTypeEntity extends DwOrmData {
    // Attributes
    AircraftTypeId?: number;
    AircraftType?: string;
    AircraftTypeDescription?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Course? : CourseEntity[];
}

export interface AssignmentEntity extends DwOrmData {
    // Attributes
    AssignmentId?: number;
    CourseId?: number;
    AssignmentDescription?: string;
    AssignmentTypeId?: number;
    AssignmentStatusId?: number;
    AssignmentLinkUrl?: string;
    MediaId?: number;
    DueDateTime?: string;
    FlightSchoolGroupId?: number;
    AssignedDateTime?: string;
    CompletedDateTime?: string;
    FlightSchoolId?: number;
    ResetLessonProgress?: boolean;
    UseGroupAssignment?: boolean;
    InstructorId?: number;

    // Display Attributes
    CourseIdDisplay?: string;
    AssignmentTypeIdDisplay?: string;
    AssignmentStatusIdDisplay?: string;
    FlightSchoolGroupIdDisplay?: string;
    FlightSchoolIdDisplay?: string;
    InstructorIdDisplay?: string;

    // Relations
    AssignmentStatus? : AssignmentStatusEntity;
    AssignmentType? : AssignmentTypeEntity;
    Course? : CourseEntity;
    FlightSchool? : FlightSchoolEntity;
    FlightSchoolGroup? : FlightSchoolGroupEntity;
    Instructor? : InstructorEntity;
    DwMedia? : DwMediaEntity;
    AssignmentCourseLesson? : AssignmentCourseLessonEntity[];
    AssignmentStudent? : AssignmentStudentEntity[];
}

export interface AssignmentCourseLessonEntity extends DwOrmData {
    // Attributes
    AssignmentCourseLessonId?: number;
    AssignmentId?: number;
    CourseLessonId?: number;

    // Display Attributes
    AssignmentIdDisplay?: string;
    CourseLessonIdDisplay?: string;

    // Relations
    Assignment? : AssignmentEntity;
    CourseLesson? : CourseLessonEntity;
}

export interface AssignmentStatusEntity extends DwOrmData {
    // Attributes
    AssignmentStatusId?: number;
    AssignmentStatus?: string;


    // Relations
    Assignment? : AssignmentEntity[];
}

export interface AssignmentStudentEntity extends DwOrmData {
    // Attributes
    AssignmentStudentId?: number;
    AssignmentId?: number;
    StudentId?: number;
    PercentComplete?: number;
    CompletedDateTime?: string;

    // Display Attributes
    AssignmentIdDisplay?: string;
    StudentIdDisplay?: string;

    // Relations
    Assignment? : AssignmentEntity;
    Student? : StudentEntity;
    RoAssignmentStudentCompletedDetails? : RoAssignmentStudentCompletedDetailsEntity;
    RoAssignmentStudentCourseLessonProgress? : RoAssignmentStudentCourseLessonProgressEntity[];
}

export interface AssignmentTypeEntity extends DwOrmData {
    // Attributes
    AssignmentTypeId?: number;
    AssignmentType?: string;


    // Relations
    Assignment? : AssignmentEntity[];
}

export interface CourseEntity extends DwOrmData {
    // Attributes
    CourseId?: number;
    CourseName?: string;
    CourseDescription?: string;
    CourseTypeId?: number;
    CourseImageMediaId?: number;
    CourseNotes?: string;
    AircraftTypeId?: number;
    AvionicsType?: string;
    ProgramTypeId?: number;
    RetailPrice?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    CourseStatusId?: number;

    // Display Attributes
    CourseTypeIdDisplay?: string;
    CourseImageMediaIdDisplay?: string;
    AircraftTypeIdDisplay?: string;
    ProgramTypeIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    CourseStatusIdDisplay?: string;

    // Relations
    AircraftType? : AircraftTypeEntity;
    CourseType? : CourseTypeEntity;
    CourseImageDwMedia? : DwMediaEntity;
    CourseImageDwroMedia? : DwRoMediaEntity;
    ProgramType? : ProgramTypeEntity;
    CourseStatusIdReferenceStatus? : ReferenceStatusEntity;
    Assignment? : AssignmentEntity[];
    CourseEndorsement? : CourseEndorsementEntity;
    CourseLessonFlag? : CourseLessonFlagEntity[];
    CourseModificationHistory? : CourseModificationHistoryEntity[];
    CourseModule? : CourseModuleEntity[];
    FlightSchoolCourse? : FlightSchoolCourseEntity[];
    JoinRequestCourse? : JoinRequestCourseEntity[];
    PackageCourse? : PackageCourseEntity[];
    Question? : QuestionEntity[];
    RoFlightSchoolCourseUnique? : RoFlightSchoolCourseUniqueEntity[];
    StudentCourse? : StudentCourseEntity[];
}

export interface CourseEndorsementEntity extends DwOrmData {
    // Attributes
    CourseEndorsementId?: number;
    CourseId?: number;
    EndorsementName?: string;
    ExpirationPeriodDays?: number;
    EndorsementPDFMediaId?: number;
    EndorsementDescription?: string;
    CourseEndorsementTemplateId?: number;
    RequireAllLessonsComplete?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    IsEndorsementEnabled?: boolean;

    // Display Attributes
    CourseIdDisplay?: string;
    EndorsementPDFMediaIdDisplay?: string;
    CourseEndorsementTemplateIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    CourseEndorsementTemplate? : CourseEndorsementTemplateEntity;
    EndorsementPDFDwMedia? : DwMediaEntity;
    CourseEndorsementLesson? : CourseEndorsementLessonEntity[];
    CourseEndorsementStudent? : CourseEndorsementStudentEntity[];
}

export interface CourseEndorsementLessonEntity extends DwOrmData {
    // Attributes
    CourseEndorsementLessonId?: number;
    CourseEndorsementId?: number;
    CourseLessonId?: number;

    // Display Attributes
    CourseEndorsementIdDisplay?: string;
    CourseLessonIdDisplay?: string;

    // Relations
    CourseEndorsement? : CourseEndorsementEntity;
    CourseLesson? : CourseLessonEntity;
}

export interface CourseEndorsementStudentEntity extends DwOrmData {
    // Attributes
    CourseEndorsementStudentId?: number;
    CourseEndorsementId?: number;
    StudentCourseId?: number;
    CompletionDateTime?: string;
    ExpirationDateTime?: string;
    EndorsementPDFMediaId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CourseEndorsementIdDisplay?: string;
    StudentCourseIdDisplay?: string;
    EndorsementPDFMediaIdDisplay?: string;

    // Relations
    CourseEndorsement? : CourseEndorsementEntity;
    EndorsementPDFDwMedia? : DwMediaEntity;
    StudentCourse? : StudentCourseEntity;
}

export interface CourseEndorsementTemplateEntity extends DwOrmData {
    // Attributes
    CourseEndorsementTemplateId?: number;
    TemplateName?: string;
    TemplatePDFMediaId?: number;
    OverlayPDFMediaId?: number;


    // Relations
    CourseEndorsement? : CourseEndorsementEntity[];
    CourseEndorsementTemplateField? : CourseEndorsementTemplateFieldEntity[];
}

export interface CourseEndorsementTemplateFieldEntity extends DwOrmData {
    // Attributes
    CourseEndorsementTemplateFieldId?: number;
    CourseEndorsementTemplateId?: number;
    TemplateFieldTypeId?: number;
    TemplateFieldDefaultValue?: string;
    MetaDataAttributeId?: number;
    TopPosition?: number;
    LeftPosition?: number;
    RightPosition?: number;
    Height?: number;
    TemplateFontStyleId?: number;
    TemplateFieldAlignmentId?: number;
    ReferenceImageId?: number;

    // Display Attributes
    CourseEndorsementTemplateIdDisplay?: string;
    TemplateFieldTypeIdDisplay?: string;
    MetaDataAttributeIdDisplay?: string;
    TemplateFontStyleIdDisplay?: string;
    TemplateFieldAlignmentIdDisplay?: string;
    ReferenceImageIdDisplay?: string;

    // Relations
    CourseEndorsementTemplate? : CourseEndorsementTemplateEntity;
    ReferenceImage? : ReferenceImageEntity;
    TemplateFieldAlignment? : TemplateFieldAlignmentEntity;
    TemplateFieldType? : TemplateFieldTypeEntity;
    TemplateFontStyle? : TemplateFontStyleEntity;
}

export interface CourseLessonEntity extends DwOrmData {
    // Attributes
    CourseLessonId?: number;
    CourseModuleId?: number;
    LessonName?: string;
    LessonDescription?: string;
    LessonTypeId?: number;
    VideoId?: number;
    QuizId?: number;
    LessonOrder?: number;
    LessonImageMediaId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    LessonReferebcePdfMediaId?: number;
    LessonReferenceNotes?: string;
    LessonStatusId?: number;

    // Display Attributes
    CourseModuleIdDisplay?: string;
    LessonTypeIdDisplay?: string;
    VideoIdDisplay?: string;
    QuizIdDisplay?: string;
    LessonImageMediaIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    LessonStatusIdDisplay?: string;

    // Relations
    CourseModule? : CourseModuleEntity;
    LessonType? : LessonTypeEntity;
    LessonImageDwroMedia? : DwRoMediaEntity;
    Quiz? : QuizEntity;
    LessonStatusIdReferenceStatus? : ReferenceStatusEntity;
    Video? : VideoEntity;
    AssignmentCourseLesson? : AssignmentCourseLessonEntity[];
    CourseEndorsementLesson? : CourseEndorsementLessonEntity[];
    CourseLessonFlag? : CourseLessonFlagEntity[];
    CourseLessonProgress? : CourseLessonProgressEntity[];
    RoAssignmentStudentCourseLessonProgress? : RoAssignmentStudentCourseLessonProgressEntity[];
}

export interface CourseLessonFlagEntity extends DwOrmData {
    // Attributes
    CourseLessonFlagId?: number;
    CourseLessonId?: number;
    QuizId?: number;
    QuestionId?: number;
    FlagComment?: string;
    CourseLessonFlagStatusId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    CourseLessonFlagTypeId?: number;
    CourseId?: number;

    // Display Attributes
    CourseLessonIdDisplay?: string;
    QuizIdDisplay?: string;
    QuestionIdDisplay?: string;
    CourseLessonFlagStatusIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    CourseLessonFlagTypeIdDisplay?: string;
    CourseIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    CourseLesson? : CourseLessonEntity;
    CourseLessonFlagStatus? : CourseLessonFlagStatusEntity;
    CourseLessonFlagType? : CourseLessonFlagTypeEntity;
    Question? : QuestionEntity;
    Quiz? : QuizEntity;
    ModifiedByDwUser? : DwUserEntity;
    CreatedByDwUser? : DwUserEntity;
}

export interface CourseLessonFlagStatusEntity extends DwOrmData {
    // Attributes
    CourseLessonFlagStatusId?: number;
    FlagStatus?: string;


    // Relations
    CourseLessonFlag? : CourseLessonFlagEntity[];
}

export interface CourseLessonFlagTypeEntity extends DwOrmData {
    // Attributes
    CourseLessonFlagTypeId?: number;
    FlagType?: string;


    // Relations
    CourseLessonFlag? : CourseLessonFlagEntity[];
}

export interface CourseLessonProgressEntity extends DwOrmData {
    // Attributes
    CourseLessonProgressId?: number;
    CourseLessonId?: number;
    CourseModuleProgressId?: number;
    LessonComplete?: boolean;
    VideoFurthestTimeSeconds?: number;
    BestQuizResultId?: number;
    LastActivityDateTime?: string;
    LessonNotes?: string;
    PercentComplete?: number;
    TotalTimeSeconds?: number;
    FirstActivityDateTime?: string;
    CompletedDateTime?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    LessonProgressStatusId?: number;

    // Display Attributes
    CourseLessonIdDisplay?: string;
    CourseModuleProgressIdDisplay?: string;
    BestQuizResultIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    LessonProgressStatusIdDisplay?: string;

    // Relations
    CourseLesson? : CourseLessonEntity;
    CourseModuleProgress? : CourseModuleProgressEntity;
    BestQuizResult? : QuizResultEntity;
    LessonProgressStatusIdReferenceStatus? : ReferenceStatusEntity;
    CourseLessonSession? : CourseLessonSessionEntity[];
    QuizResult? : QuizResultEntity[];
    RoAssignmentStudentCourseLessonProgress? : RoAssignmentStudentCourseLessonProgressEntity[];
}

export interface CourseLessonSessionEntity extends DwOrmData {
    // Attributes
    CourseLessonSessionId?: number;
    CourseLessonProgressId?: number;
    LessonCompleted?: boolean;
    VideoId?: number;
    VideoFurthestTimeSeconds?: number;
    QuizResultId?: number;
    SessionStartDateTime?: string;
    SessionEndDateTime?: string;
    TotalTimeSeconds?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    LessonSessionStatusId?: number;

    // Display Attributes
    CourseLessonProgressIdDisplay?: string;
    QuizResultIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    LessonSessionStatusIdDisplay?: string;

    // Relations
    CourseLessonProgress? : CourseLessonProgressEntity;
    QuizResult? : QuizResultEntity;
    LessonSessionStatusIdReferenceStatus? : ReferenceStatusEntity;
}

export interface CourseModificationHistoryEntity extends DwOrmData {
    // Attributes
    CourseModicationHistoryId?: number;
    CourseId?: number;
    CourseChangeDateTime?: string;
    CourseChangeDetail?: string;
    Processed?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CourseIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
}

export interface CourseModuleEntity extends DwOrmData {
    // Attributes
    CourseModuleId?: number;
    CourseId?: number;
    ModuleName?: string;
    ModuleDescription?: string;
    ModuleImageMediaId?: number;
    ModuleOrder?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    ModuleStatusId?: number;

    // Display Attributes
    CourseIdDisplay?: string;
    ModuleImageMediaIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    ModuleStatusIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    ModuleImageDwMedia? : DwMediaEntity;
    ModuleImageDwroMedia? : DwRoMediaEntity;
    ModuleStatusIdReferenceStatus? : ReferenceStatusEntity;
    CourseLesson? : CourseLessonEntity[];
    CourseModuleProgress? : CourseModuleProgressEntity[];
    Question? : QuestionEntity[];
}

export interface CourseModuleProgressEntity extends DwOrmData {
    // Attributes
    CourseModuleProgressId?: number;
    CourseModuleId?: number;
    PercentComplete?: number;
    LessonsCompleted?: number;
    LessonsCount?: number;
    StudentCourseId?: number;
    LastActivityDateTime?: string;
    TotalTimeSeconds?: number;
    FirstActivityDateTime?: string;
    CompletedDateTime?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    ModuleProgressStatusId?: number;

    // Display Attributes
    CourseModuleIdDisplay?: string;
    StudentCourseIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    ModuleProgressStatusIdDisplay?: string;

    // Relations
    CourseModule? : CourseModuleEntity;
    ModuleProgressStatusIdReferenceStatus? : ReferenceStatusEntity;
    StudentCourse? : StudentCourseEntity;
    CourseLessonProgress? : CourseLessonProgressEntity[];
}

export interface CourseTypeEntity extends DwOrmData {
    // Attributes
    CourseTypeId?: number;
    CourseType?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Course? : CourseEntity[];
}

export interface FlightSchoolEntity extends DwOrmData {
    // Attributes
    FlightSchoolId?: number;
    FlightSchoolName?: string;
    FlightSchoolStatusId?: number;
    NumberOfAircraft?: number;
    Location?: string;
    NumberOfStudents?: number;
    PercentDiscount?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    IsDefaultFlightSchool?: boolean;
    FlightSchoolBrandingId?: number;

    // Display Attributes
    FlightSchoolStatusIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    FlightSchoolBrandingIdDisplay?: string;

    // Relations
    FlightSchoolBranding? : FlightSchoolBrandingEntity;
    FlightSchoolStatus? : FlightSchoolStatusEntity;
    Assignment? : AssignmentEntity[];
    FlightSchoolCourse? : FlightSchoolCourseEntity[];
    FlightSchoolGroup? : FlightSchoolGroupEntity[];
    FlightSchoolInstructor? : FlightSchoolInstructorEntity[];
    FlightSchoolPackage? : FlightSchoolPackageEntity[];
    FlightSchoolStudentOld? : FlightSchoolStudentOldEntity[];
    Instructor? : InstructorEntity[];
    JoinRequest? : JoinRequestEntity[];
    ProcessorConfig? : ProcessorConfigEntity[];
    RoFlightSchoolCourseUnique? : RoFlightSchoolCourseUniqueEntity[];
    Subscription? : SubscriptionEntity[];
    UserDetail? : UserDetailEntity[];
}

export interface FlightSchoolBrandingEntity extends DwOrmData {
    // Attributes
    FlightSchoolBrandingId?: number;
    SubdomainSetupStatusId?: number;
    SubdomainTypeId?: number;
    DomainName?: string;
    ThemeId?: number;
    NavigationColorSetId?: number;
    SidebarColorSetId?: number;
    UseCustomColors?: boolean;
    LoginColorSetId?: number;

    // Display Attributes
    SubdomainSetupStatusIdDisplay?: string;
    SubdomainTypeIdDisplay?: string;
    ThemeIdDisplay?: string;
    NavigationColorSetIdDisplay?: string;
    SidebarColorSetIdDisplay?: string;

    // Relations
    NavigationColorSetIdStandardColorSet? : StandardColorSetEntity;
    SidebarColorSetIdStandardColorSet? : StandardColorSetEntity;
    SubdomainSetupStatus? : SubdomainSetupStatusEntity;
    SubdomainType? : SubdomainTypeEntity;
    DwTheme? : DwThemeEntity;
    FlightSchool? : FlightSchoolEntity[];
}

export interface FlightSchoolCourseEntity extends DwOrmData {
    // Attributes
    FlightSchoolCourseId?: number;
    FlightSchoolId?: number;
    CourseId?: number;
    SchoolCoursePrice?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    FlightSchoolIdDisplay?: string;
    CourseIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Course? : CourseEntity;
    FlightSchool? : FlightSchoolEntity;
}

export interface FlightSchoolGroupEntity extends DwOrmData {
    // Attributes
    FlightSchoolGroupId?: number;
    FlightSchoolId?: number;
    GroupName?: string;

    // Display Attributes
    FlightSchoolIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    Assignment? : AssignmentEntity[];
    FlightSchoolGroupStudent? : FlightSchoolGroupStudentEntity[];
    JoinRequest? : JoinRequestEntity[];
}

export interface FlightSchoolGroupStudentEntity extends DwOrmData {
    // Attributes
    FlightSchoolGroupStudentId?: number;
    FlightSchoolGroupId?: number;
    StudentId?: number;

    // Display Attributes
    FlightSchoolGroupIdDisplay?: string;
    StudentIdDisplay?: string;

    // Relations
    FlightSchoolGroup? : FlightSchoolGroupEntity;
    Student? : StudentEntity;
}

export interface FlightSchoolInstructorEntity extends DwOrmData {
    // Attributes
    FlightSchoolInstructorId?: number;
    FlightSchoolId?: number;
    InstructorId?: number;

    // Display Attributes
    FlightSchoolIdDisplay?: string;
    InstructorIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    Instructor? : InstructorEntity;
}

export interface FlightSchoolPackageEntity extends DwOrmData {
    // Attributes
    FlightSchoolPackageId?: number;
    FlightSchoolId?: number;
    PackageId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    FlightSchoolIdDisplay?: string;
    PackageIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    Package? : PackageEntity;
}

export interface FlightSchoolStatusEntity extends DwOrmData {
    // Attributes
    FlightSchoolStatusId?: number;
    FlightSchoolStatus?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity[];
}

export interface FlightSchoolStudentOldEntity extends DwOrmData {
    // Attributes
    FlightSchoolStudentId?: number;
    FlightSchoolId?: number;
    StudentId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    FlightSchoolIdDisplay?: string;
    StudentIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    Student? : StudentEntity;
}

export interface InstructorEntity extends DwOrmData {
    // Attributes
    InstructorId?: number;
    UserId?: number;
    FlightSchoolId?: number;
    InstructorStatusId?: number;

    // Display Attributes
    UserIdDisplay?: string;
    FlightSchoolIdDisplay?: string;
    InstructorStatusIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    InstructorStatus? : InstructorStatusEntity;
    DwUser? : DwUserEntity;
    Assignment? : AssignmentEntity[];
    FlightSchoolInstructor? : FlightSchoolInstructorEntity[];
    InstructorStudent? : InstructorStudentEntity[];
}

export interface InstructorStatusEntity extends DwOrmData {
    // Attributes
    InstructorStatusId?: number;
    InstructorStatus?: string;


    // Relations
    Instructor? : InstructorEntity[];
}

export interface InstructorStudentEntity extends DwOrmData {
    // Attributes
    InstructorStudentId?: number;
    InstructorId?: number;
    StudentId?: number;

    // Display Attributes
    InstructorIdDisplay?: string;
    StudentIdDisplay?: string;

    // Relations
    Instructor? : InstructorEntity;
    Student? : StudentEntity;
}

export interface JoinRequestEntity extends DwOrmData {
    // Attributes
    JoinRequestId?: number;
    FlightSchoolId?: number;
    JoinRequestTypeId?: number;
    MessageHtml?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    UsePackage?: boolean;
    CoursePackageId?: number;
    JoinRequestStatusId?: number;
    AssignToGroup?: boolean;
    FlightSchoolGroupId?: number;

    // Display Attributes
    FlightSchoolIdDisplay?: string;
    JoinRequestTypeIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    CoursePackageIdDisplay?: string;
    JoinRequestStatusIdDisplay?: string;
    FlightSchoolGroupIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    FlightSchoolGroup? : FlightSchoolGroupEntity;
    JoinRequestStatus? : JoinRequestStatusEntity;
    JoinRequestType? : JoinRequestTypeEntity;
    CoursePackage? : PackageEntity;
    JoinRequestCourse? : JoinRequestCourseEntity[];
    JoinRequestEmail? : JoinRequestEmailEntity[];
}

export interface JoinRequestCourseEntity extends DwOrmData {
    // Attributes
    JoinRequestCourseId?: number;
    JoinRequestId?: number;
    CourseId?: number;

    // Display Attributes
    JoinRequestIdDisplay?: string;
    CourseIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    JoinRequest? : JoinRequestEntity;
}

export interface JoinRequestEmailEntity extends DwOrmData {
    // Attributes
    JoinRequestEmailId?: number;
    JoinRequestId?: number;
    EmailAddress?: string;
    Accepted?: boolean;
    UserId?: number;
    AcceptedDateTime?: string;
    LastResendRequestDateTime?: string;

    // Display Attributes
    JoinRequestIdDisplay?: string;
    UserIdDisplay?: string;

    // Relations
    JoinRequest? : JoinRequestEntity;
    DwUser? : DwUserEntity;
}

export interface JoinRequestStatusEntity extends DwOrmData {
    // Attributes
    JoinRequestStatusId?: number;
    JoinRequestStatus?: string;


    // Relations
    JoinRequest? : JoinRequestEntity[];
}

export interface JoinRequestTypeEntity extends DwOrmData {
    // Attributes
    JoinRequestTypeId?: number;
    JoinRequestType?: string;
    UserTypeId?: number;
    IncludeCourses?: boolean;

    // Display Attributes
    UserTypeIdDisplay?: string;

    // Relations
    DwUserType? : DwUserTypeEntity;
    JoinRequest? : JoinRequestEntity[];
    JoinRequestTypeRole? : JoinRequestTypeRoleEntity[];
}

export interface JoinRequestTypeRoleEntity extends DwOrmData {
    // Attributes
    JoinRequestTypeRoleId?: number;
    JoinRequestTypeId?: number;
    RoleId?: number;

    // Display Attributes
    JoinRequestTypeIdDisplay?: string;
    RoleIdDisplay?: string;

    // Relations
    JoinRequestType? : JoinRequestTypeEntity;
}

export interface LessonTypeEntity extends DwOrmData {
    // Attributes
    LessonTypeId?: number;
    LessonType?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    CourseLesson? : CourseLessonEntity[];
}

export interface DwMediaEntity extends DwOrmData {
    // Attributes
    ContainerName?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    IsDeleted?: boolean;
    IsSecure?: boolean;
    MediaFolderId?: number;
    MediaGroupId?: number;
    MediaId?: number;
    MediaName?: string;
    MediaStorageTypeId?: number;
    MediaTypeId?: number;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    MediaFolderIdDisplay?: string;
    MediaGroupIdDisplay?: string;
    MediaStorageTypeIdDisplay?: string;
    MediaTypeIdDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    DwMediaGroup? : DwMediaGroupEntity;
    Assignment? : AssignmentEntity[];
    CourseImageCourse? : CourseEntity[];
    EndorsementPDFCourseEndorsement? : CourseEndorsementEntity[];
    EndorsementPDFCourseEndorsementStudent? : CourseEndorsementStudentEntity[];
    ModuleImageCourseModule? : CourseModuleEntity[];
    DwroMedia? : DwRoMediaEntity;
    AlternateLogoDwTheme? : DwThemeEntity[];
    LogoDwTheme? : DwThemeEntity[];
    LogoLoginDwTheme? : DwThemeEntity[];
    UserPictureDwUser? : DwUserEntity[];
    UserBackgroundDwUser? : DwUserEntity[];
}

export interface DwMediaGroupEntity extends DwOrmData {
    // Attributes
    CreatedBy?: number;
    CreatedDateTime?: string;
    GroupName?: string;
    IsGlobalGroup?: boolean;
    IsSecure?: boolean;
    MediaGroupId?: number;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    PartyReferenceId?: number;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    PartyReferenceIdDisplay?: string;

    // Relations
    DwMedia? : DwMediaEntity[];
    DwroMedia? : DwRoMediaEntity[];
}

export interface PackageEntity extends DwOrmData {
    // Attributes
    PackageId?: number;
    PackageName?: string;
    PackageDescription?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    FlightSchoolPackage? : FlightSchoolPackageEntity[];
    CourseJoinRequest? : JoinRequestEntity[];
    PackageCourse? : PackageCourseEntity[];
}

export interface PackageCourseEntity extends DwOrmData {
    // Attributes
    PackageCourseId?: number;
    PackageId?: number;
    CourseId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    PackageIdDisplay?: string;
    CourseIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Course? : CourseEntity;
    Package? : PackageEntity;
}

export interface PaymentMethodEntity extends DwOrmData {
    // Attributes
    PaymentMethodId?: number;
    ProcessorConfigId?: number;
    PaymentId?: string;
    Last4?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    IsDefault?: boolean;

    // Display Attributes
    ProcessorConfigIdDisplay?: string;

    // Relations
    ProcessorConfig? : ProcessorConfigEntity;
}

export interface ProcessorConfigEntity extends DwOrmData {
    // Attributes
    ProcessorConfigId?: number;
    FlightSchoolId?: number;
    CustomerId?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    BillingName?: string;
    BillingEmail?: string;

    // Display Attributes
    FlightSchoolIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    PaymentMethod? : PaymentMethodEntity[];
}

export interface ProgramTypeEntity extends DwOrmData {
    // Attributes
    ProgramTypeId?: number;
    ProgramType?: string;
    ProgramTypeDescription?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Course? : CourseEntity[];
}

export interface QuestionEntity extends DwOrmData {
    // Attributes
    QuestionName?: string;
    Question?: string;
    QuestionImageMediaId?: number;
    CorrectAnswer?: string;
    CorrectAnswerNotes?: string;
    CorrectAnswerImageMediaId?: number;
    QuestionTypeId?: number;
    QuestionId?: number;
    UseRandomOrder?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    CourseId?: number;
    CourseModuleId?: number;
    QuestionStatusId?: number;

    // Display Attributes
    QuestionImageMediaIdDisplay?: string;
    CorrectAnswerImageMediaIdDisplay?: string;
    QuestionTypeIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    CourseIdDisplay?: string;
    CourseModuleIdDisplay?: string;
    QuestionStatusIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    CourseModule? : CourseModuleEntity;
    QuestionImageDwroMedia? : DwRoMediaEntity;
    CorrectAnswerImageDwroMedia? : DwRoMediaEntity;
    QuestionStatus? : QuestionStatusEntity;
    QuestionType? : QuestionTypeEntity;
    CourseLessonFlag? : CourseLessonFlagEntity[];
    QuestionAnswer? : QuestionAnswerEntity[];
    QuestionTagLink? : QuestionTagLinkEntity[];
    QuizQuestionLink? : QuizQuestionLinkEntity[];
    QuizResultQuestion? : QuizResultQuestionEntity[];
}

export interface QuestionNotUsedEntity extends DwOrmData {
    // Attributes
    QuizQuestionId?: number;
    QuestionName?: string;
    Question?: string;
    QuestionImageMediaId?: number;
    QuestionOrder?: number;
    QuestionTypeId?: number;
    CorrectAnswer?: string;
    CorrectAnswerNotes?: string;
    CorrectAnswerImageMediaId?: number;
    QuestionWeight?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    QuizQuestionAnswerNotUsed? : QuizQuestionAnswerNotUsedEntity[];
}

export interface QuestionAnswerEntity extends DwOrmData {
    // Attributes
    QuestionAnswerId?: number;
    QuestionId?: number;
    AnswerText?: string;
    AnswerImageMediaId?: number;
    IsCorrectAnswer?: boolean;
    ExplanationText?: string;
    AnswerOrder?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    QuestionIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Question? : QuestionEntity;
    QuizResultQuestion? : QuizResultQuestionEntity[];
}

export interface QuestionImportCharacterConversionEntity extends DwOrmData {
    // Attributes
    QuestionImportCharacterConversionId?: number;
    AsciiValue?: number;
    RemoveValue?: boolean;
    ReplacementValue?: string;


    // Relations
}

export interface QuestionStatusEntity extends DwOrmData {
    // Attributes
    QuestionStatusId?: number;
    QuestionStatus?: string;


    // Relations
    Question? : QuestionEntity[];
}

export interface QuestionTagEntity extends DwOrmData {
    // Attributes
    QuestionTagId?: number;
    TagName?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    QuestionTagLink? : QuestionTagLinkEntity[];
    TagIdVideoTagLink? : VideoTagLinkEntity[];
}

export interface QuestionTagLinkEntity extends DwOrmData {
    // Attributes
    QuestionTagLinkd?: number;
    QuestionId?: number;
    QuestionTagId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    QuestionIdDisplay?: string;
    QuestionTagIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Question? : QuestionEntity;
    QuestionTag? : QuestionTagEntity;
}

export interface QuestionTypeEntity extends DwOrmData {
    // Attributes
    QuestionTypeId?: number;
    QuestionType?: string;
    ConfigureAnswers?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Question? : QuestionEntity[];
}

export interface QuizEntity extends DwOrmData {
    // Attributes
    QuizId?: number;
    QuizName?: string;
    QuizTypeId?: number;
    UseRandomOrder?: boolean;
    MaxQuestionsToShow?: number;
    TimeLimitMinutes?: number;
    MaxAttempts?: number;
    PassingPercent?: number;
    ShowAnswersOnFail?: boolean;
    ShowAnswersOnPass?: boolean;
    UseWeightedAnswers?: boolean;
    QuizDescription?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    QuizStatusId?: number;

    // Display Attributes
    QuizTypeIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    QuizStatusIdDisplay?: string;

    // Relations
    QuizType? : QuizTypeEntity;
    QuizStatusIdReferenceStatus? : ReferenceStatusEntity;
    CourseLesson? : CourseLessonEntity[];
    CourseLessonFlag? : CourseLessonFlagEntity[];
    QuizQuestionLink? : QuizQuestionLinkEntity[];
    QuizReferenceQuizLink? : QuizReferenceQuizLinkEntity[];
    ReferenceQuizReferenceQuizLink? : QuizReferenceQuizLinkEntity[];
    QuizResult? : QuizResultEntity[];
}

export interface QuizOutcomeEntity extends DwOrmData {
    // Attributes
    QuizOutcomeId?: number;
    QuizOutcome?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
}

export interface QuizQuestionAnswerNotUsedEntity extends DwOrmData {
    // Attributes
    QuizQuestionAnswerId?: number;
    QuizQuestionId?: number;
    AnswerText?: string;
    AnswerImageMediaId?: number;
    IsCorrectAnswer?: boolean;
    ExplanationText?: string;
    AnswerOrder?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    QuizQuestionIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    QuestionNotUsed? : QuestionNotUsedEntity;
}

export interface QuizQuestionLinkEntity extends DwOrmData {
    // Attributes
    QuizId?: number;
    QuestionId?: number;
    QuizQuestionLinkId?: number;
    QuestionOrder?: number;
    QuestionWeight?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    QuizIdDisplay?: string;
    QuestionIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Question? : QuestionEntity;
    Quiz? : QuizEntity;
}

export interface QuizReferenceQuizLinkEntity extends DwOrmData {
    // Attributes
    QuizReferenceQuizLinkId?: number;
    QuizId?: number;
    ReferenceQuizId?: number;
    QuizWeight?: number;
    RequireCompletion?: boolean;
    PrioritizeMissedQuestions?: boolean;

    // Display Attributes
    QuizIdDisplay?: string;
    ReferenceQuizIdDisplay?: string;

    // Relations
    Quiz? : QuizEntity;
    ReferenceQuiz? : QuizEntity;
}

export interface QuizResultEntity extends DwOrmData {
    // Attributes
    QuizResultId?: number;
    QuizId?: number;
    IsComplete?: boolean;
    CompletedQuestions?: number;
    TotalQuestions?: number;
    QuizScore?: number;
    PerfectScore?: number;
    QuizStartDateTime?: string;
    QuizStopDateTime?: string;
    PercentageScore?: number;
    QuizResultStatusId?: number;
    TotalTimeSeconds?: number;
    CorrectQuestions?: number;
    StudentCourseId?: number;
    CourseLessonProgressId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    QuizIdDisplay?: string;
    QuizResultStatusIdDisplay?: string;
    StudentCourseIdDisplay?: string;
    CourseLessonProgressIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    CourseLessonProgress? : CourseLessonProgressEntity;
    Quiz? : QuizEntity;
    QuizResultStatus? : QuizResultStatusEntity;
    StudentCourse? : StudentCourseEntity;
    BestCourseLessonProgress? : CourseLessonProgressEntity[];
    CourseLessonSession? : CourseLessonSessionEntity[];
    QuizResultQuestion? : QuizResultQuestionEntity[];
}

export interface QuizResultQuestionEntity extends DwOrmData {
    // Attributes
    QuizResultQuestionId?: number;
    QuizResultId?: number;
    AnswerValue?: string;
    IsCorrect?: boolean;
    QuestionStartDateTime?: string;
    QuestionEndDateTime?: string;
    QuestionId?: number;
    QuestionAnswerId?: number;
    TotalTimeSeconds?: number;
    IsAnswered?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    QuizResultIdDisplay?: string;
    QuestionIdDisplay?: string;
    QuestionAnswerIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Question? : QuestionEntity;
    QuestionAnswer? : QuestionAnswerEntity;
    QuizResult? : QuizResultEntity;
}

export interface QuizResultStatusEntity extends DwOrmData {
    // Attributes
    QuizResultStatusId?: number;
    QuizResultStatus?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    QuizResult? : QuizResultEntity[];
}

export interface QuizTypeEntity extends DwOrmData {
    // Attributes
    QuizTypeId?: number;
    QuizType?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    ShowQuizReferences?: boolean;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Quiz? : QuizEntity[];
}

export interface ReferenceImageEntity extends DwOrmData {
    // Attributes
    ReferenceImageId?: number;
    ReferenceImageName?: string;
    ImageMediaId?: number;


    // Relations
    CourseEndorsementTemplateField? : CourseEndorsementTemplateFieldEntity[];
}

export interface ReferenceStatusEntity extends DwOrmData {
    // Attributes
    ReferenceStatusId?: number;
    ReferenceStatus?: string;


    // Relations
    CourseStatusIdCourse? : CourseEntity[];
    LessonStatusIdCourseLesson? : CourseLessonEntity[];
    LessonProgressStatusIdCourseLessonProgress? : CourseLessonProgressEntity[];
    LessonSessionStatusIdCourseLessonSession? : CourseLessonSessionEntity[];
    ModuleStatusIdCourseModule? : CourseModuleEntity[];
    ModuleProgressStatusIdCourseModuleProgress? : CourseModuleProgressEntity[];
    QuizStatusIdQuiz? : QuizEntity[];
    StudentCourseStatusIdStudentCourse? : StudentCourseEntity[];
}

export interface RoAssignmentStudentCompletedDetailsEntity extends DwOrmData {
    // Attributes
    AssignmentStudentId?: number;
    AssignmentId?: number;
    CompletedOnTime?: boolean;

    // Display Attributes
    AssignmentStudentIdDisplay?: string;

    // Relations
    AssignmentStudent? : AssignmentStudentEntity;
}

export interface RoAssignmentStudentCourseLessonProgressEntity extends DwOrmData {
    // Attributes
    AssignmentStudentId?: number;
    StudentId?: number;
    CourseLessonId?: number;
    CourseLessonProgressId?: number;

    // Display Attributes
    AssignmentStudentIdDisplay?: string;
    CourseLessonIdDisplay?: string;
    CourseLessonProgressIdDisplay?: string;

    // Relations
    AssignmentStudent? : AssignmentStudentEntity;
    CourseLesson? : CourseLessonEntity;
    CourseLessonProgress? : CourseLessonProgressEntity;
}

export interface RoFlightSchoolCourseUniqueEntity extends DwOrmData {
    // Attributes
    FlightSchoolId?: number;
    CourseId?: number;

    // Display Attributes
    FlightSchoolIdDisplay?: string;
    CourseIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    FlightSchool? : FlightSchoolEntity;
}

export interface DwRoMediaEntity extends DwOrmData {
    // Attributes
    ContainerName?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    FileName?: string;
    ImageHeight?: number;
    ImageWidth?: number;
    IsDeleted?: boolean;
    IsSecure?: boolean;
    MediaDetailKey?: string;
    MediaFolderId?: number;
    MediaGroupId?: number;
    MediaId?: number;
    MediaName?: string;
    MediaPath?: string;
    MediaSizeType?: string;
    MediaSizeTypeId?: number;
    MediaTypeId?: number;
    SizeInBytes?: number;
    Version?: number;

    // Display Attributes
    CreatedByDisplay?: string;
    MediaGroupIdDisplay?: string;
    MediaIdDisplay?: string;

    // Relations
    DwMedia? : DwMediaEntity;
    DwMediaGroup? : DwMediaGroupEntity;
    CourseImageCourse? : CourseEntity[];
    LessonImageCourseLesson? : CourseLessonEntity[];
    ModuleImageCourseModule? : CourseModuleEntity[];
    QuestionImageQuestion? : QuestionEntity[];
    CorrectAnswerImageQuestion? : QuestionEntity[];
}

export interface RoModuleLessonMaxOrderEntity extends DwOrmData {
    // Attributes
    CourseId?: number;
    CourseName?: string;
    CourseModuleId?: number;
    ModuleName?: string;
    ModuleMaxOrder?: number;
    LessonMaxOrder?: number;


    // Relations
}

export interface RoUserRoleDetailEntity extends DwOrmData {
    // Attributes
    UserId?: number;
    IsStudent?: boolean;
    IsInstructor?: boolean;
    IsLeadInstructor?: boolean;
    IsFlightSchoolAdmin?: boolean;
    IsFlightSchool?: boolean;

    // Display Attributes
    UserIdDisplay?: string;

    // Relations
    DwUser? : DwUserEntity;
}

export interface StandardColorSetEntity extends DwOrmData {
    // Attributes
    StandardColorSetId?: number;
    ForegroundColor?: string;
    BackgroundColor?: string;
    ColorSetName?: string;


    // Relations
    NavigationColorSetIdFlightSchoolBranding? : FlightSchoolBrandingEntity[];
    SidebarColorSetIdFlightSchoolBranding? : FlightSchoolBrandingEntity[];
}

export interface StudentEntity extends DwOrmData {
    // Attributes
    StudentId?: number;
    UserId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    UserIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    DwUser? : DwUserEntity;
    AssignmentStudent? : AssignmentStudentEntity[];
    FlightSchoolGroupStudent? : FlightSchoolGroupStudentEntity[];
    FlightSchoolStudentOld? : FlightSchoolStudentOldEntity[];
    InstructorStudent? : InstructorStudentEntity[];
    StudentCourse? : StudentCourseEntity[];
}

export interface StudentCourseEntity extends DwOrmData {
    // Attributes
    StudentCourseId?: number;
    StudentId?: number;
    CourseId?: number;
    IsActive?: boolean;
    PercentComplete?: number;
    ModulesCompleted?: number;
    ModulesCount?: number;
    LastActivityDateTime?: string;
    LessonsCompleted?: number;
    LessonCount?: number;
    TotalTimeSeconds?: number;
    FirstActivityDateTime?: string;
    CompletedDateTime?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    StudentCourseStatusId?: number;
    IsEndorsed?: boolean;

    // Display Attributes
    StudentIdDisplay?: string;
    CourseIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    StudentCourseStatusIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    StudentCourseStatusIdReferenceStatus? : ReferenceStatusEntity;
    Student? : StudentEntity;
    CourseEndorsementStudent? : CourseEndorsementStudentEntity;
    CourseModuleProgress? : CourseModuleProgressEntity[];
    QuizResult? : QuizResultEntity[];
}

export interface SubdomainSetupStatusEntity extends DwOrmData {
    // Attributes
    SubdomainSetupStatusId?: number;
    SubdomainSetupStatus?: string;


    // Relations
    FlightSchoolBranding? : FlightSchoolBrandingEntity[];
}

export interface SubdomainTypeEntity extends DwOrmData {
    // Attributes
    SubdomainTypeId?: number;
    SubdomainType?: string;


    // Relations
    FlightSchoolBranding? : FlightSchoolBrandingEntity[];
}

export interface SubscriptionEntity extends DwOrmData {
    // Attributes
    SubscriptionId?: number;
    FlightSchoolId?: number;
    SubscriptionTierId?: number;
    SubscriptionStatusId?: number;
    EffectiveStartDate?: string;
    EffectiveEndDate?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    FlightSchoolIdDisplay?: string;
    SubscriptionTierIdDisplay?: string;
    SubscriptionStatusIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    SubscriptionStatus? : SubscriptionStatusEntity;
    SubscriptionTier? : SubscriptionTierEntity;
    SubscriptionRoleTracking? : SubscriptionRoleTrackingEntity[];
}

export interface SubscriptionRoleEntity extends DwOrmData {
    // Attributes
    SubscriptionRoleId?: number;
    SubscriptionRole?: string;
    DevwareRoleId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    ProductId?: string;

    // Display Attributes
    DevwareRoleIdDisplay?: string;

    // Relations
    SubscriptionRoleTracking? : SubscriptionRoleTrackingEntity[];
    SubscriptionTierRole? : SubscriptionTierRoleEntity[];
    UserSubscriptionRole? : UserSubscriptionRoleEntity[];
}

export interface SubscriptionRoleTrackingEntity extends DwOrmData {
    // Attributes
    SubscriptionRoleTrackingId?: number;
    SubscriptionId?: number;
    SubscriptionRoleId?: number;
    StartDate?: string;
    EndDate?: string;
    MinCount?: number;
    MinCountDate?: string;
    MaxCount?: number;
    MaxCountDate?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    SubscriptionIdDisplay?: string;
    SubscriptionRoleIdDisplay?: string;

    // Relations
    Subscription? : SubscriptionEntity;
    SubscriptionRole? : SubscriptionRoleEntity;
}

export interface SubscriptionStatusEntity extends DwOrmData {
    // Attributes
    SubscriptionStatusId?: number;
    SubscriptionStatus?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;


    // Relations
    Subscription? : SubscriptionEntity[];
}

export interface SubscriptionTierEntity extends DwOrmData {
    // Attributes
    SubscriptionTierId?: number;
    SubscriptionTierName?: string;
    SubscriptionTierStatusId?: number;
    EffectiveStartDate?: string;
    EffectiveEndDate?: string;
    PlatformMonthlyCost?: number;
    ProfitSharingPercentage?: number;
    AllowProgressView?: boolean;
    AllowReports?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    ProductId?: string;
    PriceId?: string;

    // Display Attributes
    SubscriptionTierStatusIdDisplay?: string;

    // Relations
    SubscriptionTierStatus? : SubscriptionTierStatusEntity;
    Subscription? : SubscriptionEntity[];
    SubscriptionTierRole? : SubscriptionTierRoleEntity[];
}

export interface SubscriptionTierRoleEntity extends DwOrmData {
    // Attributes
    SubscriptionTierRoleId?: number;
    SubscriptionTierId?: number;
    SubscriptionRoleId?: number;
    MonthlyCost?: number;
    MinimumBilled?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    PriceId?: string;

    // Display Attributes
    SubscriptionTierIdDisplay?: string;
    SubscriptionRoleIdDisplay?: string;

    // Relations
    SubscriptionRole? : SubscriptionRoleEntity;
    SubscriptionTier? : SubscriptionTierEntity;
}

export interface SubscriptionTierStatusEntity extends DwOrmData {
    // Attributes
    SubscriptionTierStatusId?: number;
    SubscriptionTierStatus?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;


    // Relations
    SubscriptionTier? : SubscriptionTierEntity[];
}

export interface TemplateFieldAlignmentEntity extends DwOrmData {
    // Attributes
    TemplateFieldAlignmentId?: number;
    AlignmentDisplay?: string;


    // Relations
    CourseEndorsementTemplateField? : CourseEndorsementTemplateFieldEntity[];
}

export interface TemplateFieldTypeEntity extends DwOrmData {
    // Attributes
    TemplateFieldTypeId?: number;
    FieldType?: string;


    // Relations
    CourseEndorsementTemplateField? : CourseEndorsementTemplateFieldEntity[];
}

export interface TemplateFontStyleEntity extends DwOrmData {
    // Attributes
    TemplateFontStyleId?: number;
    StyleName?: string;
    FontFamily?: string;
    FontSize?: number;
    IsBold?: boolean;
    IsItalics?: boolean;
    Color?: string;


    // Relations
    CourseEndorsementTemplateField? : CourseEndorsementTemplateFieldEntity[];
}

export interface DwThemeEntity extends DwOrmData {
    // Attributes
    ThemeId?: number;
    ThemeName?: string;
    IsDefault?: boolean;
    LogoMediaId?: number;
    LogoWidth?: number;
    LogoHeight?: number;
    AlternateLogoMediaId?: number;
    AlternateLogoWidth?: number;
    AlternateLogoHeight?: number;
    LogoLoginMediaId?: number;
    LogoLoginWidth?: number;
    LogoLoginHeight?: number;

    // Display Attributes
    LogoMediaIdDisplay?: string;
    AlternateLogoMediaIdDisplay?: string;
    LogoLoginMediaIdDisplay?: string;

    // Relations
    AlternateLogoDwMedia? : DwMediaEntity;
    LogoDwMedia? : DwMediaEntity;
    LogoLoginDwMedia? : DwMediaEntity;
    FlightSchoolBranding? : FlightSchoolBrandingEntity[];
    DwThemeProperty? : DwThemePropertyEntity[];
}

export interface DwThemePropertyEntity extends DwOrmData {
    // Attributes
    ThemePropertyId?: number;
    ThemeId?: number;
    ThemePropertyDefinitionId?: number;
    PropertyValue?: string;

    // Display Attributes
    ThemeIdDisplay?: string;
    ThemePropertyDefinitionIdDisplay?: string;

    // Relations
    DwTheme? : DwThemeEntity;
    DwThemePropertyDefinition? : DwThemePropertyDefinitionEntity;
}

export interface DwThemePropertyDefinitionEntity extends DwOrmData {
    // Attributes
    ThemePropertyDefinitionId?: number;
    PropertyDisplay?: string;
    PropertyName?: string;
    ThemePropertyTypeId?: number;

    // Display Attributes
    ThemePropertyTypeIdDisplay?: string;

    // Relations
    DwThemePropertyType? : DwThemePropertyTypeEntity;
    DwThemeProperty? : DwThemePropertyEntity[];
}

export interface DwThemePropertyTypeEntity extends DwOrmData {
    // Attributes
    ThemePropertyTypeId?: number;
    PropertyType?: string;


    // Relations
    DwThemePropertyDefinition? : DwThemePropertyDefinitionEntity[];
}

export interface DwUserEntity extends DwOrmData {
    // Attributes
    AlternateName?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    Email?: string;
    FirstName?: string;
    ForcePasswordChange?: boolean;
    IsActive?: boolean;
    IsEmailVerified?: boolean;
    IsTwoFactorAuthenticationEnabled?: boolean;
    LastLoginDateTime?: string;
    LastName?: string;
    LastPasswordChangeDateTime?: string;
    MobilePhone?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    OrganizationId?: number;
    PartyReferenceId?: number;
    TwoFactorAuthenticationTypeId?: number;
    UserBackgroundMediaId?: number;
    UserId?: number;
    UserKey?: string;
    UserName?: string;
    UserPictureMediaId?: number;
    UserTypeId?: number;
    Password?: number;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    OrganizationIdDisplay?: string;
    PartyReferenceIdDisplay?: string;
    TwoFactorAuthenticationTypeIdDisplay?: string;
    UserBackgroundMediaIdDisplay?: string;
    UserPictureMediaIdDisplay?: string;
    UserTypeIdDisplay?: string;

    // Relations
    UserPictureDwMedia? : DwMediaEntity;
    UserBackgroundDwMedia? : DwMediaEntity;
    DwUserType? : DwUserTypeEntity;
    ModifiedByCourseLessonFlag? : CourseLessonFlagEntity[];
    CreatedByCourseLessonFlag? : CourseLessonFlagEntity[];
    Instructor? : InstructorEntity;
    JoinRequestEmail? : JoinRequestEmailEntity[];
    RoUserRoleDetail? : RoUserRoleDetailEntity;
    Student? : StudentEntity;
    UserDetail? : UserDetailEntity;
    DwUserRole? : DwUserRoleEntity[];
    UserSubscriptionRole? : UserSubscriptionRoleEntity[];
}

export interface UserDetailEntity extends DwOrmData {
    // Attributes
    UserDetailId?: number;
    UserId?: number;
    FlightSchoolId?: number;

    // Display Attributes
    UserIdDisplay?: string;
    FlightSchoolIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    DwUser? : DwUserEntity;
}

export interface DwUserRoleEntity extends DwOrmData {
    // Attributes
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    RoleId?: number;
    UserId?: number;
    UserRoleId?: number;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    RoleIdDisplay?: string;
    UserIdDisplay?: string;

    // Relations
    DwUser? : DwUserEntity;
}

export interface UserSubscriptionRoleEntity extends DwOrmData {
    // Attributes
    UserSubscriptionRoleId?: number;
    UserId?: number;
    SubscriptionRoleId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    UserIdDisplay?: string;
    SubscriptionRoleIdDisplay?: string;

    // Relations
    SubscriptionRole? : SubscriptionRoleEntity;
    DwUser? : DwUserEntity;
}

export interface DwUserTypeEntity extends DwOrmData {
    // Attributes
    ShowOnRegistration?: boolean;
    UserTypeClasses?: string;
    UserTypeDescription?: string;
    UserTypeDisplay?: string;
    UserTypeId?: number;
    UserTypeOrder?: number;


    // Relations
    JoinRequestType? : JoinRequestTypeEntity[];
    DwUser? : DwUserEntity[];
}

export interface VideoEntity extends DwOrmData {
    // Attributes
    VideoId?: number;
    VideoName?: string;
    VideoLibraryId?: number;
    VideoKey?: string;
    DirectPlayUrl?: string;
    ThumbnailUrl?: string;
    VideoStreamingProviderId?: number;
    VideoStatusId?: number;
    VideoSizeBytes?: number;
    VideoDurationSeconds?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    AllowMp4?: boolean;
    IsPrivateVideo?: boolean;
    OriginalFileName?: string;

    // Display Attributes
    VideoLibraryIdDisplay?: string;
    VideoStreamingProviderIdDisplay?: string;
    VideoStatusIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    VideoLibrary? : VideoLibraryEntity;
    VideoStatus? : VideoStatusEntity;
    VideoStreamingProvider? : VideoStreamingProviderEntity;
    CourseLesson? : CourseLessonEntity[];
    VideoHistory? : VideoHistoryEntity[];
    VideoTagLink? : VideoTagLinkEntity[];
}

export interface VideoHistoryEntity extends DwOrmData {
    // Attributes
    VideoHistoryId?: number;
    VideoId?: number;
    VideoKey?: string;
    DirectPlayUrl?: string;
    ThumbnailUrl?: string;
    VideoSizeBytes?: number;
    VideoDurationSeconds?: number;
    AllowMp4?: boolean;
    IsPrivateVideo?: boolean;
    OriginalFileName?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    VideoIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Video? : VideoEntity;
}

export interface VideoLibraryEntity extends DwOrmData {
    // Attributes
    VideoLibraryId?: number;
    VideoLibraryName?: string;
    VideoLibraryKey?: string;
    VideoStreamingProviderId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    VideoStreamingProviderIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    VideoStreamingProvider? : VideoStreamingProviderEntity;
    Video? : VideoEntity[];
}

export interface VideoStatusEntity extends DwOrmData {
    // Attributes
    VideoStatusId?: number;
    VideoStatus?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Video? : VideoEntity[];
}

export interface VideoStreamingProviderEntity extends DwOrmData {
    // Attributes
    VideoStreamingProviderId?: number;
    ProviderName?: string;
    ProviderManagementUrl?: string;
    AutoPlay?: boolean;
    ContinueFromLastPosition?: boolean;
    AllowSeek?: boolean;
    SeekThreshholdSeconds?: number;
    PreloadVideo?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    AllowMp4Default?: boolean;
    IsPrivateVideoDefault?: boolean;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;

    // Relations
    Video? : VideoEntity[];
    VideoLibrary? : VideoLibraryEntity[];
}

export interface VideoTagLinkEntity extends DwOrmData {
    // Attributes
    VideoTagLink?: number;
    VideoId?: number;
    TagId?: number;

    // Display Attributes
    VideoIdDisplay?: string;
    TagIdDisplay?: string;

    // Relations
    TagIdQuestionTag? : QuestionTagEntity;
    Video? : VideoEntity;
}

// Generated Query Interfaces
/** Entity for Query Meaning Course_List-_For_Student  */
export interface CourseListForStudentQueryEntity extends DwOrmData {
    // Attributes
    CourseId?: number;
    CourseName?: string;
    CourseDescription?: string;
    CourseTypeId?: number;
    CourseImageMediaId?: number;
    CourseNotes?: string;
    AircraftTypeId?: number;
    AvionicsType?: string;
    ProgramTypeId?: number;
    RetailPrice?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    CourseStatusId?: number;
    StudentCourseStudentId?: number;
    StudentCourseCourseId?: number;
    StudentCourseIsActive?: boolean;
    StudentCoursePercentComplete?: number;
    StudentCourseModulesCompleted?: number;
    StudentCourseModulesCount?: number;
    StudentCourseLastActivityDateTime?: string;
    StudentCourseIsEndorsed?: boolean;
    StudentCourseStudentCourseStatusId?: number;
    StudentUserId?: number;
    CourseEndorsementStudentCompletionDateTime?: string;
    CourseEndorsementStudentExpirationDateTime?: string;
    CourseEndorsementStudentEndorsementPDFMediaId?: number;
    CourseEndorsementStudentCourseEndorsementStudentId?: number;
    CourseEndorsementStudentCourseEndorsementId?: number;
    MediaImageHeight?: number;
    MediaImageWidth?: number;
    MediaMediaPath?: string;

    // Relations
    AircraftType? : AircraftTypeEntity;
    CourseType? : CourseTypeEntity;
    CourseImageDwMedia? : DwMediaEntity;
    CourseImageDwroMedia? : DwRoMediaEntity;
    ProgramType? : ProgramTypeEntity;
    CourseStatusIdReferenceStatus? : ReferenceStatusEntity;
    Assignment? : AssignmentEntity[];
    CourseEndorsement? : CourseEndorsementEntity;
    CourseLessonFlag? : CourseLessonFlagEntity[];
    CourseModificationHistory? : CourseModificationHistoryEntity[];
    CourseModule? : CourseModuleEntity[];
    FlightSchoolCourse? : FlightSchoolCourseEntity[];
    JoinRequestCourse? : JoinRequestCourseEntity[];
    PackageCourse? : PackageCourseEntity[];
    Question? : QuestionEntity[];
    RoFlightSchoolCourseUnique? : RoFlightSchoolCourseUniqueEntity[];
    StudentCourse? : StudentCourseEntity[];
}

/** Entity for Query Meaning Module_List-For_Student  */
export interface ModuleListForStudentQueryEntity extends DwOrmData {
    // Attributes
    CourseModuleId?: number;
    CourseId?: number;
    CourseIdDisplay?: string;
    ModuleName?: string;
    ModuleDescription?: string;
    ModuleImageMediaId?: number;
    ModuleImageMediaIdDisplay?: string;
    ModuleOrder?: number;
    CreatedBy?: number;
    CreatedByDisplay?: string;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedByDisplay?: string;
    ModifiedDateTime?: string;
    ModuleStatusId?: number;
    ModuleStatusIdDisplay?: string;
    CourseModuleProgressCourseModuleProgressId?: number;
    CourseModuleProgressPercentComplete?: number;
    CourseModuleProgressLessonsCompleted?: number;
    CourseModuleProgressLessonsCount?: number;
    CourseModuleProgressLastActivityDateTime?: string;
    CourseModuleProgressTotalTimeSeconds?: number;
    CourseModuleProgressFirstActivityDateTime?: string;
    CourseModuleProgressCompletedDateTime?: string;
    StudentCourseIsActive?: boolean;
    StudentStudentId?: number;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    CourseImageMediaPath?: string;
    MediaImageWidth?: number;
    MediaImageHeight?: number;
    MediaMediaPath?: string;

    // Relations
    Course? : CourseEntity;
    ModuleImageDwMedia? : DwMediaEntity;
    ModuleImageDwroMedia? : DwRoMediaEntity;
    ModuleStatusIdReferenceStatus? : ReferenceStatusEntity;
    CourseLesson? : CourseLessonEntity[];
    CourseModuleProgress? : CourseModuleProgressEntity[];
    Question? : QuestionEntity[];
}

/** Entity for Query Meaning Lesson_List-For_Student  */
export interface LessonListForStudentQueryEntity extends DwOrmData {
    // Attributes
    CourseLessonId?: number;
    CourseModuleId?: number;
    CourseModuleIdDisplay?: string;
    LessonName?: string;
    LessonDescription?: string;
    LessonTypeId?: number;
    LessonTypeIdDisplay?: string;
    VideoId?: number;
    VideoIdDisplay?: string;
    QuizId?: number;
    QuizIdDisplay?: string;
    LessonOrder?: number;
    LessonImageMediaId?: number;
    LessonImageMediaIdDisplay?: string;
    CourseLessonProgressLessonComplete?: boolean;
    CourseLessonProgressVideoFurthestTimeSeconds?: number;
    CourseLessonProgressBestQuizResultId?: number;
    CourseLessonProgressBestQuizResultIdDisplay?: string;
    CourseLessonProgressLastActivityDateTime?: string;
    CourseLessonProgressPercentComplete?: number;
    CourseLessonProgressTotalTimeSeconds?: number;
    CourseLessonProgressFirstActivityDateTime?: string;
    CourseLessonProgressCompletedDateTime?: string;
    CourseModuleProgressCourseModuleId?: number;
    CourseModuleProgressCourseModuleIdDisplay?: string;
    StudentCourseCourseId?: number;
    StudentCourseCourseIdDisplay?: string;
    StudentCourseIsActive?: boolean;
    StudentStudentId?: number;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    CourseImageMediaPath?: string;
    CourseModuleCourseId?: number;
    CourseModuleCourseIdDisplay?: string;
    ModuleImageMediaPath?: string;
    VideoThumbnailUrl?: string;
    VideoVideoName?: string;
    LessonImageDwroMediaMediaPath?: string;

    // Relations
    CourseModule? : CourseModuleEntity;
    LessonType? : LessonTypeEntity;
    LessonImageDwroMedia? : DwRoMediaEntity;
    Quiz? : QuizEntity;
    LessonStatusIdReferenceStatus? : ReferenceStatusEntity;
    Video? : VideoEntity;
    AssignmentCourseLesson? : AssignmentCourseLessonEntity[];
    CourseEndorsementLesson? : CourseEndorsementLessonEntity[];
    CourseLessonFlag? : CourseLessonFlagEntity[];
    CourseLessonProgress? : CourseLessonProgressEntity[];
    RoAssignmentStudentCourseLessonProgress? : RoAssignmentStudentCourseLessonProgressEntity[];
}

/** Entity for Query Meaning Student-Course_Progress  */
export interface StudentCourseProgressQueryEntity extends DwOrmData {
    // Attributes
    StudentId?: number;
    UserId?: number;
    UserIdDisplay?: string;
    CreatedBy?: number;
    CreatedByDisplay?: string;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedByDisplay?: string;
    ModifiedDateTime?: string;
    StudentCourseStudentCourseId?: number;
    StudentCourseIsActive?: boolean;
    StudentCoursePercentComplete?: number;
    StudentCourseModulesCompleted?: number;
    StudentCourseModulesCount?: number;
    StudentCourseLastActivityDateTime?: string;
    StudentCourseLessonsCompleted?: number;
    StudentCourseLessonCount?: number;
    StudentCourseTotalTimeSeconds?: number;
    StudentCourseStudentId?: number;
    StudentCourseStudentIdDisplay?: string;
    StudentCourseCourseId?: number;
    StudentCourseCourseIdDisplay?: string;
    StudentCourseFirstActivityDateTime?: string;
    StudentCourseCompletedDateTime?: string;
    StudentCourseIsEndorsed?: boolean;
    CourseCourseId?: number;
    CourseCourseName?: string;
    CourseCourseDescription?: string;
    CourseCourseStatusId?: number;
    CourseCourseStatusIdDisplay?: string;
    CourseImageDwroMediaMediaPath?: string;
    CourseEndorsementStudentCompletionDateTime?: string;
    CourseEndorsementStudentExpirationDateTime?: string;
    UserDetailFlightSchoolId?: number;
    UserDetailFlightSchoolIdDisplay?: string;
    UserDetailUserDetailId?: number;

    // Relations
    DwUser? : DwUserEntity;
    AssignmentStudent? : AssignmentStudentEntity[];
    FlightSchoolGroupStudent? : FlightSchoolGroupStudentEntity[];
    FlightSchoolStudentOld? : FlightSchoolStudentOldEntity[];
    InstructorStudent? : InstructorStudentEntity[];
    StudentCourse? : StudentCourseEntity[];
}

/** Entity for Query Meaning Student-Module_Progress  */
export interface StudentModuleProgressQueryEntity extends DwOrmData {
    // Attributes
    CourseModuleProgressId?: number;
    CourseModuleId?: number;
    CourseModuleIdDisplay?: string;
    PercentComplete?: number;
    LessonsCompleted?: number;
    LessonsCount?: number;
    StudentCourseId?: number;
    StudentCourseIdDisplay?: string;
    LastActivityDateTime?: string;
    TotalTimeSeconds?: number;
    FirstActivityDateTime?: string;
    CompletedDateTime?: string;
    CreatedBy?: number;
    CreatedByDisplay?: string;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedByDisplay?: string;
    ModifiedDateTime?: string;
    ModuleProgressStatusId?: number;
    ModuleProgressStatusIdDisplay?: string;
    CourseModuleModuleOrder?: number;
    CourseModuleModuleName?: string;
    ImageMediaPath?: string;
    CourseImageMediaPath?: string;

    // Relations
    CourseModule? : CourseModuleEntity;
    ModuleProgressStatusIdReferenceStatus? : ReferenceStatusEntity;
    StudentCourse? : StudentCourseEntity;
    CourseLessonProgress? : CourseLessonProgressEntity[];
}

/** Entity for Query Meaning Student-Lesson_Progress  */
export interface StudentLessonProgressQueryEntity extends DwOrmData {
    // Attributes
    CourseLessonProgressId?: number;
    CourseLessonId?: number;
    CourseLessonIdDisplay?: string;
    CourseModuleProgressId?: number;
    CourseModuleProgressIdDisplay?: string;
    LessonComplete?: boolean;
    VideoFurthestTimeSeconds?: number;
    BestQuizResultId?: number;
    BestQuizResultIdDisplay?: string;
    LastActivityDateTime?: string;
    LessonNotes?: string;
    PercentComplete?: number;
    TotalTimeSeconds?: number;
    FirstActivityDateTime?: string;
    CompletedDateTime?: string;
    SessionCount?: number;
    CourseLessonLessonName?: string;
    CourseLessonLessonDescription?: string;
    CourseLessonLessonTypeId?: number;
    CourseLessonLessonTypeIdDisplay?: string;
    CourseLessonLessonOrder?: number;
    LessonImageDwroMediaMediaPath?: string;
    VideoThumbnailUrl?: string;
    BestQuizResultPercentageScore?: number;
    BestQuizResultQuizResultStatusId?: number;
    BestQuizResultQuizResultStatusIdDisplay?: string;
    CourseModuleProgressStudentCourseId?: number;
    CourseModuleProgressStudentCourseIdDisplay?: string;
    CourseModuleProgressCourseModuleId?: number;
    CourseModuleProgressCourseModuleIdDisplay?: string;
    StudentCourseCourseId?: number;
    StudentCourseCourseIdDisplay?: string;
    StudentCourseStudentId?: number;
    StudentCourseStudentIdDisplay?: string;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    CourseImageMediaPath?: string;
    ModuleImageMediaPath?: string;

    // Relations
    CourseLesson? : CourseLessonEntity;
    CourseModuleProgress? : CourseModuleProgressEntity;
    BestQuizResult? : QuizResultEntity;
    LessonProgressStatusIdReferenceStatus? : ReferenceStatusEntity;
    CourseLessonSession? : CourseLessonSessionEntity[];
    QuizResult? : QuizResultEntity[];
    RoAssignmentStudentCourseLessonProgress? : RoAssignmentStudentCourseLessonProgressEntity[];
}

/** Entity for Query Meaning Student-Course_Progress-by-student-course  */
export interface StudentCourseProgressByStudentCourseQueryEntity extends DwOrmData {
    // Attributes
    StudentCourseId?: number;
    IsActive?: boolean;
    PercentComplete?: number;
    ModulesCompleted?: number;
    ModulesCount?: number;
    LastActivityDateTime?: string;
    LessonsCompleted?: number;
    LessonCount?: number;
    TotalTimeSeconds?: number;
    StudentId?: number;
    StudentIdDisplay?: string;
    CourseId?: number;
    CourseIdDisplay?: string;
    FirstActivityDateTime?: string;
    CompletedDateTime?: string;
    StudentStudentId?: number;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    StudentCreatedBy?: number;
    StudentCreatedByDisplay?: string;
    StudentCreatedDateTime?: string;
    StudentModifiedBy?: number;
    StudentModifiedByDisplay?: string;
    StudentModifiedDateTime?: string;
    UserDetailFlightSchoolId?: number;
    UserDetailFlightSchoolIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    StudentCourseStatusIdReferenceStatus? : ReferenceStatusEntity;
    Student? : StudentEntity;
    CourseEndorsementStudent? : CourseEndorsementStudentEntity;
    CourseModuleProgress? : CourseModuleProgressEntity[];
    QuizResult? : QuizResultEntity[];
}

/** Entity for Query Meaning Student-Module/Lesson_Activity  */
export interface StudentModuleLessonActivityQueryEntity extends DwOrmData {
    // Attributes
    CourseModuleId?: number;
    CourseModuleIdDisplay?: string;
    CourseLessonProgressCourseLessonId?: number;
    CourseLessonProgressCourseLessonIdDisplay?: string;
    CourseLessonProgressLessonComplete?: boolean;
    CourseLessonProgressVideoFurthestTimeSeconds?: number;
    CourseLessonProgressBestQuizResultId?: number;
    CourseLessonProgressBestQuizResultIdDisplay?: string;
    CourseLessonProgressLastActivityDateTime?: string;
    CourseLessonProgressPercentComplete?: number;
    CourseLessonProgressTotalTimeSeconds?: number;
    CourseLessonProgressFirstActivityDateTime?: string;
    CourseLessonProgressCompletedDateTime?: string;
    CourseLessonSessionCourseLessonSessionId?: number;
    CourseLessonSessionCourseLessonProgressId?: number;
    CourseLessonSessionCourseLessonProgressIdDisplay?: string;
    CourseLessonSessionLessonCompleted?: boolean;
    CourseLessonSessionVideoId?: number;
    CourseLessonSessionVideoFurthestTimeSeconds?: number;
    CourseLessonSessionQuizResultId?: number;
    CourseLessonSessionQuizResultIdDisplay?: string;
    CourseLessonSessionSessionStartDateTime?: string;
    CourseLessonSessionSessionEndDateTime?: string;
    CourseLessonSessionTotalTimeSeconds?: number;
    QuizResultCompletedQuestions?: number;
    QuizResultTotalQuestions?: number;
    QuizResultQuizScore?: number;
    QuizResultPerfectScore?: number;
    QuizResultPercentageScore?: number;
    QuizResultQuizResultStatusId?: number;
    QuizResultQuizResultStatusIdDisplay?: string;
    QuizResultQuizId?: number;
    QuizResultQuizIdDisplay?: string;
    QuizResultQuizResultId?: number;

    // Relations
    CourseModule? : CourseModuleEntity;
    ModuleProgressStatusIdReferenceStatus? : ReferenceStatusEntity;
    StudentCourse? : StudentCourseEntity;
    CourseLessonProgress? : CourseLessonProgressEntity[];
}

/** Entity for Query Meaning Flight_School_Group_with_StudentCourse_Detail  */
export interface FlightSchoolGroupWithStudentCourseDetailQueryEntity extends DwOrmData {
    // Attributes
    FlightSchoolGroupId?: number;
    FlightSchoolId?: number;
    FlightSchoolIdDisplay?: string;
    GroupName?: string;
    FlightSchoolGroupStudentStudentId?: number;
    FlightSchoolGroupStudentStudentIdDisplay?: string;
    FlightSchoolGroupStudentFlightSchoolGroupStudentId?: number;
    StudentStudentId?: number;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    StudentCourseStudentCourseId?: number;
    StudentCourseCourseId?: number;
    StudentCourseCourseIdDisplay?: string;
    StudentCourseIsActive?: boolean;
    UserDetailUserDetailId?: number;
    UserDetailFlightSchoolId?: number;
    UserDetailFlightSchoolIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    Assignment? : AssignmentEntity[];
    FlightSchoolGroupStudent? : FlightSchoolGroupStudentEntity[];
    JoinRequest? : JoinRequestEntity[];
}

/** Entity for Query Meaning Assignment_Student-Lesson_Sesson_Grid  */
export interface AssignmentStudentLessonSessonGridQueryEntity extends DwOrmData {
    // Attributes
    AssignmentStudentId?: number;
    AssignmentId?: number;
    AssignmentIdDisplay?: string;
    StudentId?: number;
    StudentIdDisplay?: string;
    PercentComplete?: number;
    CompletedDateTime?: string;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    RoAssignmentStudentCourseLessonProgressStudentId?: number;
    CourseLessonProgressBestQuizResultId?: number;
    CourseLessonProgressBestQuizResultIdDisplay?: string;
    CourseLessonProgressCompletedDateTime?: string;
    CourseLessonProgressCourseLessonId?: number;
    CourseLessonProgressCourseLessonIdDisplay?: string;
    CourseLessonProgressCourseLessonProgressId?: number;
    CourseLessonProgressCourseModuleProgressId?: number;
    CourseLessonProgressCourseModuleProgressIdDisplay?: string;
    CourseLessonProgressCreatedBy?: number;
    CourseLessonProgressCreatedByDisplay?: string;
    CourseLessonProgressCreatedDateTime?: string;
    CourseLessonProgressFirstActivityDateTime?: string;
    CourseLessonProgressLastActivityDateTime?: string;
    CourseLessonProgressLessonComplete?: boolean;
    CourseLessonProgressLessonNotes?: string;
    CourseLessonProgressModifiedBy?: number;
    CourseLessonProgressModifiedByDisplay?: string;
    CourseLessonProgressModifiedDateTime?: string;
    CourseLessonProgressPercentComplete?: number;
    CourseLessonProgressTotalTimeSeconds?: number;
    CourseLessonProgressVideoFurthestTimeSeconds?: number;
    SessionCourseLessonSessionId?: number;
    SessionCourseLessonProgressId?: number;
    SessionCourseLessonProgressIdDisplay?: string;
    SessionLessonCompleted?: boolean;
    SessionVideoId?: number;
    SessionVideoFurthestTimeSeconds?: number;
    SessionQuizResultId?: number;
    SessionQuizResultIdDisplay?: string;
    SessionSessionStartDateTime?: string;
    SessionSessionEndDateTime?: string;
    SessionTotalTimeSeconds?: number;
    SessionCreatedBy?: number;
    SessionCreatedByDisplay?: string;
    SessionCreatedDateTime?: string;
    SessionModifiedBy?: number;
    SessionModifiedByDisplay?: string;
    SessionModifiedDateTime?: string;
    QuizResultQuizResultId?: number;
    QuizResultQuizId?: number;
    QuizResultQuizIdDisplay?: string;
    QuizResultQuizScore?: number;
    QuizResultPercentageScore?: number;
    QuizResultQuizResultStatusId?: number;
    QuizResultQuizResultStatusIdDisplay?: string;
    CourseLessonLessonName?: string;
    CourseLessonCourseModuleId?: number;
    CourseLessonCourseModuleIdDisplay?: string;

    // Relations
    Assignment? : AssignmentEntity;
    Student? : StudentEntity;
    RoAssignmentStudentCompletedDetails? : RoAssignmentStudentCompletedDetailsEntity;
    RoAssignmentStudentCourseLessonProgress? : RoAssignmentStudentCourseLessonProgressEntity[];
}

/** Entity for Query Meaning Assignment_Lesson_with_Progress-For_Student  */
export interface AssignmentLessonWithProgressForStudentQueryEntity extends DwOrmData {
    // Attributes
    AssignmentCourseLessonId?: number;
    AssignmentId?: number;
    AssignmentIdDisplay?: string;
    CourseLessonId?: number;
    CourseLessonIdDisplay?: string;
    AssignmentAssignmentDescription?: string;
    AssignmentAssignmentStatusId?: number;
    AssignmentAssignmentStatusIdDisplay?: string;
    AssignmentAssignedDateTime?: string;
    AssignmentDueDateTime?: string;
    AssignmentCourseId?: number;
    AssignmentCourseIdDisplay?: string;
    AssignmentStudentAssignmentStudentId?: number;
    AssignmentStudentStudentId?: number;
    AssignmentStudentStudentIdDisplay?: string;
    AssignmentStudentPercentComplete?: number;
    AssignmentStudentCompletedDateTime?: string;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    CourseLessonLessonName?: string;
    CourseLessonCourseModuleId?: number;
    CourseLessonCourseModuleIdDisplay?: string;
    CourseLessonLessonTypeId?: number;
    CourseLessonLessonTypeIdDisplay?: string;
    CourseLessonLessonOrder?: number;
    CourseLessonProgressCourseLessonId?: number;
    CourseLessonProgressCourseLessonIdDisplay?: string;
    CourseLessonProgressLessonComplete?: boolean;
    CourseLessonProgressVideoFurthestTimeSeconds?: number;
    CourseLessonProgressBestQuizResultId?: number;
    CourseLessonProgressBestQuizResultIdDisplay?: string;
    CourseLessonProgressPercentComplete?: number;
    CourseLessonProgressLastActivityDateTime?: string;
    CourseModuleProgressCourseModuleProgressId?: number;
    CourseModuleProgressCourseModuleId?: number;
    CourseModuleProgressCourseModuleIdDisplay?: string;
    CourseModuleProgressStudentCourseId?: number;
    CourseModuleProgressStudentCourseIdDisplay?: string;
    StudentCourseStudentId?: number;
    StudentCourseStudentIdDisplay?: string;
    StudentFromProgressUserId?: number;
    StudentFromProgressUserIdDisplay?: string;
    CourseModuleModuleName?: string;
    CourseModuleModuleOrder?: number;
    CourseModuleCourseModuleId?: number;
    CourseModuleCourseId?: number;
    CourseModuleCourseIdDisplay?: string;

    // Relations
    Assignment? : AssignmentEntity;
    CourseLesson? : CourseLessonEntity;
}

/** Entity for Query Meaning Assignment-By_Student  */
export interface AssignmentByStudentQueryEntity extends DwOrmData {
    // Attributes
    AssignmentId?: number;
    CourseId?: number;
    CourseIdDisplay?: string;
    AssignmentDescription?: string;
    AssignmentTypeId?: number;
    AssignmentTypeIdDisplay?: string;
    AssignmentStatusId?: number;
    AssignmentStatusIdDisplay?: string;
    AssignmentLinkUrl?: string;
    MediaId?: number;
    DueDateTime?: string;
    FlightSchoolGroupId?: number;
    FlightSchoolGroupIdDisplay?: string;
    AssignedDateTime?: string;
    CompletedDateTime?: string;
    FlightSchoolId?: number;
    FlightSchoolIdDisplay?: string;
    ResetLessonProgress?: boolean;
    UseGroupAssignment?: boolean;
    InstructorId?: number;
    InstructorIdDisplay?: string;
    AssignmentStudentAssignmentStudentId?: number;
    AssignmentStudentPercentComplete?: number;
    AssignmentStudentCompletedDateTime?: string;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    StudentStudentId?: number;

    // Relations
    AssignmentStatus? : AssignmentStatusEntity;
    AssignmentType? : AssignmentTypeEntity;
    Course? : CourseEntity;
    FlightSchool? : FlightSchoolEntity;
    FlightSchoolGroup? : FlightSchoolGroupEntity;
    Instructor? : InstructorEntity;
    DwMedia? : DwMediaEntity;
    AssignmentCourseLesson? : AssignmentCourseLessonEntity[];
    AssignmentStudent? : AssignmentStudentEntity[];
}

/** Entity for Query Meaning Assignment_Student-Lesson_Progress-_List  */
export interface AssignmentStudentLessonProgressListQueryEntity extends DwOrmData {
    // Attributes
    AssignmentStudentId?: number;
    AssignmentStudentIdDisplay?: string;
    StudentId?: number;
    CourseLessonId?: number;
    CourseLessonIdDisplay?: string;
    CourseLessonProgressId?: number;
    AssignmentStudentAssignmentStudentId?: number;
    AssignmentStudentAssignmentId?: number;
    AssignmentStudentAssignmentIdDisplay?: string;
    AssignmentStudentStudentId?: number;
    AssignmentStudentStudentIdDisplay?: string;
    AssignmentStudentPercentComplete?: number;
    AssignmentStudentCompletedDateTime?: string;
    CourseLessonProgressLessonComplete?: boolean;
    CourseLessonCourseLessonId?: number;
    CourseLessonCourseModuleId?: number;
    CourseLessonCourseModuleIdDisplay?: string;
    CourseLessonLessonName?: string;
    CourseLessonLessonDescription?: string;
    CourseLessonLessonTypeId?: number;
    CourseLessonLessonTypeIdDisplay?: string;
    CourseLessonVideoId?: number;
    CourseLessonVideoIdDisplay?: string;
    CourseLessonQuizId?: number;
    CourseLessonQuizIdDisplay?: string;
    CourseLessonLessonOrder?: number;
    LessonImageDwroMediaMediaPath?: string;

    // Relations
    AssignmentStudent? : AssignmentStudentEntity;
    CourseLesson? : CourseLessonEntity;
    CourseLessonProgress? : CourseLessonProgressEntity;
}

/** Entity for Query Meaning Student_By_Instructor  */
export interface StudentByInstructorQueryEntity extends DwOrmData {
    // Attributes
    StudentId?: number;
    UserId?: number;
    UserIdDisplay?: string;
    CreatedBy?: number;
    CreatedByDisplay?: string;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedByDisplay?: string;
    ModifiedDateTime?: string;
    DwUserFirstName?: string;
    DwUserLastName?: string;
    DwUserEmail?: string;
    DwroMediaMediaPath?: string;
    UserDetailUserDetailId?: number;
    UserDetailFlightSchoolId?: number;
    UserDetailFlightSchoolIdDisplay?: string;
    InstructorStudentInstructorId?: number;
    InstructorStudentInstructorIdDisplay?: string;
    InstructorStudentInstructorStudentId?: number;
    InstructorInstructorId?: number;
    InstructorUserId?: number;
    InstructorUserIdDisplay?: string;

    // Relations
    DwUser? : DwUserEntity;
    AssignmentStudent? : AssignmentStudentEntity[];
    FlightSchoolGroupStudent? : FlightSchoolGroupStudentEntity[];
    FlightSchoolStudentOld? : FlightSchoolStudentOldEntity[];
    InstructorStudent? : InstructorStudentEntity[];
    StudentCourse? : StudentCourseEntity[];
}

/** Entity for Query Meaning Student-By_School  */
export interface StudentBySchoolQueryEntity extends DwOrmData {
    // Attributes
    StudentId?: number;
    UserId?: number;
    UserIdDisplay?: string;
    CreatedBy?: number;
    CreatedByDisplay?: string;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedByDisplay?: string;
    ModifiedDateTime?: string;
    DwUserFirstName?: string;
    DwUserLastName?: string;
    DwroMediaMediaPath?: string;
    UserDetailUserDetailId?: number;
    UserDetailFlightSchoolId?: number;
    UserDetailFlightSchoolIdDisplay?: string;

    // Relations
    DwUser? : DwUserEntity;
    AssignmentStudent? : AssignmentStudentEntity[];
    FlightSchoolGroupStudent? : FlightSchoolGroupStudentEntity[];
    FlightSchoolStudentOld? : FlightSchoolStudentOldEntity[];
    InstructorStudent? : InstructorStudentEntity[];
    StudentCourse? : StudentCourseEntity[];
}

/** Entity for Query Meaning Flight_School-for_My_School_Context  */
export interface FlightSchoolForMySchoolContextQueryEntity extends DwOrmData {
    // Attributes
    FlightSchoolId?: number;
    FlightSchoolName?: string;
    FlightSchoolStatusId?: number;
    NumberOfAircraft?: number;
    Location?: string;
    NumberOfStudents?: number;
    PercentDiscount?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    IsDefaultFlightSchool?: boolean;
    FlightSchoolBrandingId?: number;

    // Relations
    FlightSchoolBranding? : FlightSchoolBrandingEntity;
    FlightSchoolStatus? : FlightSchoolStatusEntity;
    Assignment? : AssignmentEntity[];
    FlightSchoolCourse? : FlightSchoolCourseEntity[];
    FlightSchoolGroup? : FlightSchoolGroupEntity[];
    FlightSchoolInstructor? : FlightSchoolInstructorEntity[];
    FlightSchoolPackage? : FlightSchoolPackageEntity[];
    FlightSchoolStudentOld? : FlightSchoolStudentOldEntity[];
    Instructor? : InstructorEntity[];
    JoinRequest? : JoinRequestEntity[];
    ProcessorConfig? : ProcessorConfigEntity[];
    RoFlightSchoolCourseUnique? : RoFlightSchoolCourseUniqueEntity[];
    Subscription? : SubscriptionEntity[];
    UserDetail? : UserDetailEntity[];
}

/** Entity for Query Meaning Student_Course-For_Context  */
export interface StudentCourseForContextQueryEntity extends DwOrmData {
    // Attributes
    StudentCourseId?: number;
    StudentId?: number;
    StudentIdDisplay?: string;
    CourseId?: number;
    CourseIdDisplay?: string;
    IsActive?: boolean;
    PercentComplete?: number;
    ModulesCompleted?: number;
    ModulesCount?: number;
    LastActivityDateTime?: string;
    LessonsCompleted?: number;
    LessonCount?: number;
    TotalTimeSeconds?: number;
    FirstActivityDateTime?: string;
    CompletedDateTime?: string;
    CreatedBy?: number;
    CreatedByDisplay?: string;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedByDisplay?: string;
    ModifiedDateTime?: string;
    StudentCourseStatusId?: number;
    StudentCourseStatusIdDisplay?: string;
    IsEndorsed?: boolean;
    CourseEndorsementStudentCourseEndorsementStudentId?: number;
    CourseEndorsementStudentCourseEndorsementId?: number;
    CourseEndorsementStudentCourseEndorsementIdDisplay?: string;
    CourseEndorsementStudentStudentCourseId?: number;
    CourseEndorsementStudentStudentCourseIdDisplay?: string;
    CourseEndorsementStudentCompletionDateTime?: string;
    CourseEndorsementStudentExpirationDateTime?: string;
    CourseEndorsementStudentEndorsementPDFMediaId?: number;
    CourseEndorsementStudentEndorsementPDFMediaIdDisplay?: string;
    StudentUserId?: number;
    StudentUserIdDisplay?: string;
    StudentStudentId?: number;

    // Relations
    Course? : CourseEntity;
    StudentCourseStatusIdReferenceStatus? : ReferenceStatusEntity;
    Student? : StudentEntity;
    CourseEndorsementStudent? : CourseEndorsementStudentEntity;
    CourseModuleProgress? : CourseModuleProgressEntity[];
    QuizResult? : QuizResultEntity[];
}

/** Entity for Query Meaning Join_Request-Details-Grid  */
export interface JoinRequestDetailsGridQueryEntity extends DwOrmData {
    // Attributes
    JoinRequestId?: number;
    FlightSchoolId?: number;
    FlightSchoolIdDisplay?: string;
    JoinRequestTypeId?: number;
    JoinRequestTypeIdDisplay?: string;
    MessageHtml?: string;
    CreatedBy?: number;
    CreatedByDisplay?: string;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedByDisplay?: string;
    ModifiedDateTime?: string;
    UsePackage?: boolean;
    CoursePackageId?: number;
    CoursePackageIdDisplay?: string;
    JoinRequestStatusId?: number;
    JoinRequestStatusIdDisplay?: string;
    AssignToGroup?: boolean;
    FlightSchoolGroupId?: number;
    FlightSchoolGroupIdDisplay?: string;
    JoinRequestEmailEmailAddress?: string;
    JoinRequestEmailAccepted?: boolean;
    JoinRequestEmailUserId?: number;
    JoinRequestEmailUserIdDisplay?: string;
    JoinRequestEmailAcceptedDateTime?: string;
    JoinRequestEmailLastResendRequestDateTime?: string;
    JoinRequestEmailJoinRequestEmailId?: number;
    JoinRequestEmailJoinRequestId?: number;
    JoinRequestEmailJoinRequestIdDisplay?: string;

    // Relations
    FlightSchool? : FlightSchoolEntity;
    FlightSchoolGroup? : FlightSchoolGroupEntity;
    JoinRequestStatus? : JoinRequestStatusEntity;
    JoinRequestType? : JoinRequestTypeEntity;
    CoursePackage? : PackageEntity;
    JoinRequestCourse? : JoinRequestCourseEntity[];
    JoinRequestEmail? : JoinRequestEmailEntity[];
}

    // Lookup Value Enums  {
export enum QuizResultStatusAllItems {
        Incomplete = 1,
        CompleteUngraded = 2,
        Passed = 3,
        Failed = 4,
        Deleted = 5,
    }

export enum VideoStatusAllItems {
        Uploading = 1,
        Encoding = 2,
        Active = 3,
        Deleted = 4,
    }

export enum FlightSchoolStatusAllItems {
        Submitted = 1,
        Active = 2,
        Inactive = 3,
        Rejected = 4,
        Test2 = 8,
        Asdfasfdasfd2 = 9,
        Test1a = 10,
        Asdfasdfasdf = 11,
        // Duplicate property Name found
        Asdfasdfasdf_12 = 12,
        // Duplicate property Name found
        Asdfasdfasdf_13 = 13,
        Asdfasdf = 14,
        // Duplicate property Name found
        Asdfasdfasdf_15 = 15,
        Asfdasdf = 16,
        Asdfasdfasdfasdf = 17,
        S = 18,
        Sdfasdfasdf = 19,
    }

export enum QuestionStatusAllItems {
        Draft = 1,
        Active = 2,
        Inactive = 3,
        Deleted = 4,
    }

export enum AssignmentStatusAllItems {
        Active = 1,
        Completed = 2,
        Cancelled = 3,
        Deleted = 4,
    }

export enum JoinRequestStatusAllItems {
        Pending = 1,
        Completed = 2,
        Deleted = 3,
    }

export enum InstructorStatusAllItems {
        Active = 1,
        Inactive = 2,
    }

export enum CourseLessonFlagStatusAllItems {
        New = 1,
        Complete = 2,
        Reviewed = 3,
    }

export enum ReferenceStatusAllItems {
        Active = 1,
        Inactive = 2,
        Deleted = 3,
    }

export enum SubdomainSetupStatusAllItems {
        New = 1,
        InProgress = 2,
        Completed = 3,
    }

export enum SubscriptionTierStatusAllItems {
        Active = 1,
        Inactive = 2,
    }

export enum SubscriptionStatusAllItems {
    }

// Excluding lookup MediaGroup All Items because it contains 120 items which exceeds the max item setting of 100.  Override in cli config for code gen using ExcludeLookupsMaxCount
export enum FlightSchoolGroupAllItems {
        PrivateGroundSummer2024 = 1,
        FallSemester2024FullTrack = 2,
    }

export enum FlightSchoolGroupStudentAllItems {
        FlightSchoolGroupStudentId1 = 1,
        FlightSchoolGroupStudentId2 = 2,
        FlightSchoolGroupStudentId3 = 3,
        FlightSchoolGroupStudentId4 = 4,
        FlightSchoolGroupStudentId5 = 5,
        FlightSchoolGroupStudentId6 = 6,
        FlightSchoolGroupStudentId7 = 7,
        FlightSchoolGroupStudentId8 = 8,
        FlightSchoolGroupStudentId9 = 9,
        FlightSchoolGroupStudentId10 = 10,
    }

export enum FlightSchoolGroupStudentBySchool {
    }

export enum FlightSchoolGroupBySchool {
    }

export enum UserTypeAllItems {
        Student = 1,
        FlightSchool = 2,
        AviatorOnlineAdmin = 3,
        FlightInstructor = 4,
    }

export enum CourseTypeAllItems {
        Curriculum = 1,
        AircraftSpecific = 7,
    }

export enum ProgramTypeAllItems {
        Num61 = 1,
        Num141 = 2,
        Num135 = 3,
        Num91 = 4,
        Num121 = 5,
        Num91K = 6,
    }

export enum AircraftTypeAllItems {
        Cessna172Skyhawk = 1,
        PiperArcherII = 2,
        PiperArcherIII = 3,
        PiperWarrior = 4,
        PiperCherokee = 5,
        PiperArrow = 7,
        PiperSeminole = 8,
        VansRV9A = 9,
    }

export enum LessonTypeAllItems {
        Video = 1,
        Quiz = 2,
    }

export enum QuizTypeAllItems {
        Quiz = 1,
        CourseFinal = 2,
        ModuleFinal = 3,
    }

export enum QuestionTypeAllItems {
        TrueOrFalse = 1,
        MultipleChoice = 2,
        MultipleAnswer = 3,
    }

export enum AssignmentTypeAllItems {
        Lesson = 1,
        Link = 2,
        FilePDF = 3,
        Text = 4,
    }

export enum JoinRequestTypeRoleAllItems {
        JoinRequestTypeRoleId1 = 1,
        JoinRequestTypeRoleId2 = 2,
    }

export enum JoinRequestTypeAllItems {
        StudentRequest = 1,
        FlightInstructor = 2,
    }

export enum TemplateFieldTypeAllItems {
        Field = 1,
        Text = 2,
        Image = 3,
    }

export enum CourseLessonFlagTypeAllItems {
        LessonFlag = 1,
        QuestionFlag = 2,
    }

export enum ThemePropertyTypeAllItems {
        Color = 1,
        Text = 2,
        Length = 3,
    }

export enum SubdomainTypeAllItems {
        NotSetup = 1,
        SubdomainAviatorProCom = 2,
        FullDomain = 3,
    }

export enum QuizOutcomeAllItems {
    }

export enum VideoStreamingProviderAllItems {
        BunnyNet = 1,
        ApiVideo = 2,
    }

export enum VideoLibraryByStreamingProvider {
    }




